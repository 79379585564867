import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class FileApiService {
  buildurl(path: string, queryMap: any = null): string {
    return this.http.buildurl('/files' + path, queryMap);
  }
  constructor(private http: HttpService) {}

  fileurl(fileid: string) {
    if (!fileid) {
      return '';
    }
    if (fileid.startsWith('http')) {
      return fileid;
    }
    return this.buildurl(`/${fileid}`);
  }

  uploadFile(file: any, form: string = '') {
    const url = this.buildurl('/file');
    const body = new FormData();
    body.append('file', file);
    body.append('form', form);
    return this.http.post(url, body);
  }
}
