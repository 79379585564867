import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-commission-status',
  templateUrl: './commission-status.component.html',
  styleUrls: ['./commission-status.component.scss'],
})
export class CommissionStatusComponent implements OnInit {
  @Input() status = '';
  translatePageAffiliate: any;

  constructor(private translateService: TranslateService) {
    this.translateService.onLangChange.subscribe((text: any) => {
      this.translateService.get('pageAffiliate').subscribe((text: string) => {
        this.translatePageAffiliate = text;
      });
    });
  }

  ngOnInit(): void {
    this.translateService.get('pageAffiliate').subscribe((text: string) => {
      this.translatePageAffiliate = text;
    });
  }

  text() {
    const m: any = {
      Settled: this.translatePageAffiliate?.Settled,
      Frozen: this.translatePageAffiliate?.UnSettled,
    };
    return m[this.status] || this.translatePageAffiliate?.Unknown;
  }
}
