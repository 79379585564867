import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-withdrawal-status',
  templateUrl: './withdrawal-status.component.html',
  styleUrls: ['./withdrawal-status.component.scss'],
})
export class WithdrawalStatusComponent implements OnInit {
  @Input() status = '';
  pageWithdraw: any;
  constructor(private translateService: TranslateService) {
    this.translateService.onLangChange.subscribe((text: any) => {
      this.translateService.get('pageWithdraw').subscribe((text: string) => {
        this.pageWithdraw = text;
      });
    });
  }

  ngOnInit(): void {
    this.translateService.get('pageWithdraw').subscribe((text: string) => {
      this.pageWithdraw = text;
    });
  }

  text() {
    const m: any = {
      Completed: this.pageWithdraw.Completed,
      Pending: this.pageWithdraw.Pending,
    };
    return m[this.status] || this.pageWithdraw.Unknown;
  }
}
