import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-refresh-netflix-code-modal',
  templateUrl: './refresh-netflix-code-modal.component.html',
  styleUrls: ['./refresh-netflix-code-modal.component.scss'],
})
export class RefreshNetflixCodeModalComponent {
  timeout = 5;
  disabled = true;
  constructor(private dialog: MatDialogRef<RefreshNetflixCodeModalComponent>) {
    if (this.timeout > 0) {
      let interval = setInterval(async () => {
        this.timeout -= 1;
        if (this.timeout === 0) {
          this.disabled = false;
          clearInterval(interval);
        }
      }, 1000);
    }
  }

  onOk() {
    if (!this.disabled) this.dialog.close('ok');
  }
}
