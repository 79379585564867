
<div class="outside" >
<div class="close">
  <button mat-dialog-close>
    <img
      alt="close"
      width="24"
      height="24"
      src="../../../../assets/images/base/Icon-del-default.svg"
    />
  </button>
</div>
<div class="title">
  <!-- {{"Order Confirm" | translate }} -->
  <app-image [fileid]="data.icon" width="201" height="84"></app-image>
</div>
<!-- <ng-container *ngIf="data.name === 'YouTube'">
  <div class="steps" (click)="openSteps()">
    <img
      src="../../../../assets/images/index/Icon-steps.svg"
      alt="step"
      width="20"
      height="20"
    />
    <span>{{ "modalPlatform.HowUse" | translate }}</span>
  </div>
</ng-container> -->

<div class="top-divider"></div>

<div class="content">


  <!-- 新div,设置平台 -->
  <!-- <div class="purchase-platform-row">
    <h2>{{ 'Platform' | translate }}</h2>
    <div class="check">
      <app-image [fileid]="data.icon" width="201" height="84"></app-image>
    </div>
  </div>

  <div class="divider"></div> -->
  <!-- 新div,设置左右 -->
  <!-- <div class="purchase-months-row">
    <h2>{{ "modalPlatform.PurchaseMonths" | translate }}</h2>
    <div class="check">
      <button
        *ngFor="let busType of data.bus_types"
        mat-flat-button
        [ngStyle]="{
          background: selectedBusType?.id === busType.id ? '#000000' : '#000000',
          color: selectedBusType?.id === busType.id ? '#FFFFFF' : '#FFFFFF',
          border:selectedBusType?.id === busType.id ? '4px solid rgba(40, 94, 194, 0.5)' : '',
          borderRadius:selectedBusType?.id === busType.id ?'10px':''
        }"
        (click)="selectBusType(busType)"
      >
        {{ busType.name }}
      </button>
    </div>
  </div>
  <div class="divider"></div> -->

  <!-- <div class="purchase-accounttype-row">
    <h2>{{ "modalPlatform.AccountType" | translate }}</h2>
    <div class="check">
      <ng-container *ngIf="data.name !== 'ChatGPT Plus'">
        <button
          mat-flat-button
          [ngStyle]="{
            background: !exclusive ? '#000000' : '#000000',
            color: !exclusive ? '#FFFFFF' : '#FFFFFF',
            border:!exclusive? '4px solid rgba(40, 94, 194, 0.5)' : '',
            borderRadius:!exclusive ?'10px':''
          }"
          (click)="exclusive = false"
        >
          {{ "modalPlatform.Profile" | translate }}
        </button>
        <button
          *ngIf="selectedBusType?.allow_exclusive"
          mat-flat-button
          [ngStyle]="{
            background: exclusive ? '#000000' : '#000000',
            color: exclusive ? '#FFFFFF' : '#FFFFFF',
            border:exclusive? '4px solid rgba(40, 94, 194, 0.5)' : '',
            borderRadius:exclusive ?'10px':''
          }"
          (click)="exclusive = true"
        >
          {{ selectedBusType?.seat_count }}
          {{ "modalPlatform.Profiles" | translate }}
        </button>
      </ng-container>
      <ng-container *ngIf="data.name === 'ChatGPT Plus'">
        <button
          mat-flat-button
          [ngStyle]="{
            background: !exclusive ? '#F5ECE3' : '#f1f1f1',
            color: !exclusive ? '#612069' : '#000000'
          }"
          (click)="exclusive = false"
        >
          {{ "modalPlatform.SharedPeople" | translate }}
        </button>
      </ng-container>
    </div>
  </div> -->
  <!-- <div class="divider" style="margin-top: 15px"></div> -->

  <!-- <div class="divider"></div> -->

  <!-- 新的样式在这里修改 -->
  <div class="new-purchase-months-row">
    <h2>{{ "modalPlatform.PurchaseMonths" | translate }}</h2>
    <div class="check">
      <button
        *ngFor="let busType of data.bus_types;let i = index"
        mat-flat-button
        [ngStyle]="{

          background: i === 0? 'linear-gradient(to bottom right, rgba(27, 151, 110, 0.2), rgba(255, 117, 17, 0.2))' :
                      i === 1 ? 'linear-gradient(to bottom right, rgba(178, 58, 243, 0.2), rgba(67, 178, 252, 0.2))' : 
                      i === 2 ? 'linear-gradient(to bottom right, rgba(255, 179, 66, 0.2), rgba(253, 75, 75, 0.2))' : 
                      i === 3 ? 'linear-gradient(to bottom right, rgba(255, 78, 163, 0.2), rgba(64, 60, 252, 0.2))':'black',
          border:selectedBusType?.id === busType.id ? '2px solid rgba(255, 255, 255, 1)' : '',
        }"
        (click)="selectBusType(busType)"
      >
      <div class="icon-circle" 
      [ngStyle]="{
        background: i === 0? 'linear-gradient(to bottom right, rgba(27, 151, 110, 1), rgba(255, 117, 17, 1))' :
                    i === 1 ? 'linear-gradient(to bottom right, rgba(178, 58, 243, 1), rgba(67, 178, 252, 1))' : 
                    i === 2 ? 'linear-gradient(to bottom right, rgba(255, 179, 66, 1), rgba(253, 75, 75, 1))' : 
                    i === 3 ? 'linear-gradient(to bottom right, rgba(255, 78, 163, 1), rgba(64, 60, 252, 1))':'black',
      }"  
      >
        <!-- <img src="../../../../favicon.png"> -->
        <img [src]="getImageSrc(i)" style="width: 15px;">

      </div>
        {{ busType.name }}
      </button>
    </div>
  </div>
  <!-- <div class="divider"></div> -->

  <div class="new-purchase-accounttype-row">
    <h2>{{ "modalPlatform.AccountType" | translate }}</h2>
    <div class="check">
      <ng-container *ngIf="data.name !== 'ChatGPT Plus'">
        <button
          mat-flat-button
          [ngStyle]="{
            background: !exclusive ? '#000000' : '#000000',
            color: !exclusive ? '#FFFFFF' : '#FFFFFF',
            border:!exclusive? '4px solid rgba(40, 94, 194, 0.5)' : '',
            borderRadius:!exclusive ?'30px':''
          }"
          (click)="exclusive = false"
        >
          <img src="../../../../assets/images/index/profile-1.svg" style="height: 20px;">
          {{ "modalPlatform.Profile" | translate }}
        </button>
        <button
          *ngIf="selectedBusType?.allow_exclusive"
          mat-flat-button
          [ngStyle]="{
            background: exclusive ? '#000000' : '#000000',
            color: exclusive ? '#FFFFFF' : '#FFFFFF',
            border:exclusive? '4px solid rgba(40, 94, 194, 0.5)' : '',
            borderRadius:exclusive ?'30px':''
          }"
          (click)="exclusive = true"
        >
          <img src="../../../../assets/images/index/profile-{{selectedBusType.seat_count}}.svg" style="height: 20px;">
          {{ selectedBusType?.seat_count }}
          {{ "modalPlatform.Profiles" | translate }}
        </button>
      </ng-container>
      <ng-container *ngIf="data.name === 'ChatGPT Plus'">
        <button
          mat-flat-button
          [ngStyle]="{
            background: exclusive ? '#000000' : '#000000',
            color: exclusive ? '#FFFFFF' : '#FFFFFF',
            border:exclusive? '4px solid rgba(40, 94, 194, 0.5)' : '4px solid rgba(40, 94, 194, 0.5)',
            borderRadius:exclusive ?'30px':'30px'
          }"
          (click)="exclusive = false"
        >
          <img src="../../../../assets/images/index/profile-6.svg" style="height: 20px;">
          {{ "modalPlatform.SharedPeople" | translate }}
        </button>
      </ng-container>
    </div>
  </div>
  <!-- <div class="divider" style="margin-top: 15px"></div> -->

  <!-- <div class="divider"></div> -->


  <!-- 这里是新增的优惠码部分 -->
  <div class="purchase-promo-row">
    <div class="input-container">
      <form [formGroup]="formGroup">
        <div class="input-wrapper">
          <input 
          [attr.placeholder]="'placeholder.PromoCode' | translate"
          formControlName="code"
          >
            <app-hollow-button
            color="#612069"
            fontColor="#ffffff"
            width="100px"
            height="40px"
            [loading]="loading"
            (click)="apply()"
            >{{ "button.Apply" | translate }}
            </app-hollow-button>
      </div>
      <div class="discount">
        <app-discount-money
            [busType]="selectedBusType"
            [exclusive]="exclusive"
            [promo]="promo"
          ></app-discount-money> 
      </div>

    </form>
    <div class="error">
      <app-error *ngIf="errorText">{{ errorText }}</app-error>
    </div>
  </div>



    <!-- <div class="content" mat-dialog-content>
      <form [formGroup]="formGroup">
        <input 
        [attr.placeholder]="'placeholder.PromoCode' | translate"
        formControlName="code">
      </form>
      <div class="error">
        <app-error *ngIf="errorText">{{ errorText }}</app-error>
      </div>
    </div>
    <div class="action" mat-dialog-actions>
      <app-button
        color="#612069"
        fontColor="#ffffff"
        width="282px"
        height="48px"
        [loading]="loading"
        (click)="apply()"
        >{{ "button.Apply" | translate }}
      </app-button> 
    </div> -->
  </div>

   







  <!-- <div class="promo">
    <div class="left">{{ "inputLabel.PromoCode" | translate }}</div>
    <button class="right" (click)="enter()">
      <span [style.color]="discountPrice > 0 ? '#612069' : '#c1c1c1'">
        {{
          discountPrice > 0
            ? renderDiscountPrice()
            : ("modalPlatform.EnterPromoCode" | translate)
        }}
      </span>
      <img
        alt="arrow"
        width="24"
        height="24"
        style="margin-left: 15px"
        src="../../../../assets/images/base/Icon-arrow-right.svg"
      />
    </button>
  </div>
  <div class="divider divider1"></div>
  <div class="action">
    <app-discount-money
      [busType]="selectedBusType"
      [exclusive]="exclusive"
      [promo]="promo"
    ></app-discount-money>
    <app-button
      color="#612069"
      fontColor="#ffffff"
      width="100%"
      height="48px"
      (click)="pay()"
      >{{ "button.CheckOut" | translate }}</app-button
    >
  </div>
</div>
</div> -->


<app-button
color="#612069"
fontColor="#ffffff"
width="100%"
height="48px"
(click)="pay()"
>{{ "button.CheckOut" | translate }}</app-button>

<!-- <h1>分割线</h1>

<app-hollow-button color="#612069"
fontColor="#ffffff"
width="100%"
height="48px"
(click)="pay()">
{{ "button.CheckOut" | translate }}
</app-hollow-button> -->




<!--         [ngStyle]="{
          background: !exclusive ? '#F5ECE3' : '#f1f1f1',
          color: !exclusive ? '#612069' : '#000000'
        }" -->