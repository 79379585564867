<div class="container">
  <div class="content">
    <h1 class="mat-headline">{{ "pageAffiliate.Affiliate" | translate }}</h1>
    <div class="cards" *ngIf="!loading">
      <div class="card">
        <app-union-card [data]="data"></app-union-card>
      </div>
      <div class="card">
        <app-refer-link-card
          [data]="data"
          [translatePageAffiliate]="translatePageAffiliate"
        ></app-refer-link-card>
      </div>
      <div class="card">
        <app-total-earned-card [data]="data"></app-total-earned-card>
      </div>
    </div>
    <div class="charts" *ngIf="!loading">
      <app-orders-pie></app-orders-pie>
      <app-orders-line
        [translatePageAffiliate]="translatePageAffiliate"
      ></app-orders-line>
    </div>
    <div class="space" *ngIf="!loading"></div>
    <div class="orderList">
      <h4>
        {{ "pageAffiliate.OrderList-1" | translate }}
        
        <span>{{ "pageAffiliate.OrderList-2" | translate }}</span>
      </h4>
      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
        <mat-tab>
          <ng-template mat-tab-label>{{
            "pageAffiliate.Settled" | translate
          }}</ng-template>
          <ng-template matTabContent>
            <app-affiliate-order-list [typelist]="2" *ngIf="!isSmall"></app-affiliate-order-list>
            <app-affiliate-order-list-cards [typelist]="2" *ngIf="isSmall"></app-affiliate-order-list-cards>
          </ng-template>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>{{
            "pageAffiliate.UnSettled" | translate
          }}</ng-template>
          <ng-template matTabContent>
            <app-affiliate-order-list [typelist]="1" *ngIf="!isSmall"></app-affiliate-order-list>
            <app-affiliate-order-list-cards [typelist]="1" *ngIf="isSmall"></app-affiliate-order-list-cards>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
