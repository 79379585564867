import { Component } from '@angular/core';
import { AbstractNgModelComponent } from '../../base/abstract-ng-model/abstract-ng-model.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-currency-select',
  templateUrl: './currency-select.component.html',
  styleUrls: ['./currency-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CurrencySelectComponent,
    },
  ],
})
export class CurrencySelectComponent extends AbstractNgModelComponent {
  options: any[] = [{ value: 'usd' }, { value: 'eur' }];
  value = '';
}
