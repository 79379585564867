<div class="close">
  <button mat-dialog-close>
    <img
      alt="close"
      width="24"
      height="24"
      src="../../../../assets/images/base/Icon-del-default.svg"
    />
  </button>
</div>
<div class="title">{{ "modalSteps.Title" | translate }}</div>
<div class="content" (click)="tab(1)" [ngClass]="{ active: 1 === activeIndex }">
  <div class="step">
    <div class="pic-text">
      <img
        class="step1"
        src="../../../../assets/images/index/step1.webp"
        alt="step"
        width="224"
        height="139"
      />
      <div class="text">
        {{ "modalSteps.Step1" | translate }}
      </div>
    </div>
    <div class="number">
      <span>1</span>
      <div class="divider-row divider-row1"></div>
    </div>
  </div>
  <div class="divider"></div>
  <div class="step">
    <div class="number">
      <div class="divider-row divider-row0"></div>
      <span>2</span>
      <div class="divider-row divider-row0"></div>
    </div>
    <div class="pic-text">
      <div class="text">{{ "modalSteps.Step2" | translate }}</div>
      <img
        class="step2"
        src="../../../../assets/images/index/step2.webp"
        alt="step"
        width="313"
        height="131"
      />
    </div>
  </div>
  <div class="divider"></div>
  <div class="step">
    <div class="pic-text">
      <img
        class="step3"
        src="../../../../assets/images/index/step3.webp"
        alt="step"
        width="224"
        height="139"
      />
      <div class="text">{{ "modalSteps.Step3" | translate }}</div>
    </div>
    <div class="number">
      <div class="divider-row divider-row0"></div>
      <span>3</span>
      <div class="divider-row divider-row0"></div>
    </div>
  </div>
  <div class="divider divider-last"></div>
</div>
<div class="content" (click)="tab(2)" [ngClass]="{ active: 2 === activeIndex }">
  <div class="step step-row">
    <div class="number">
      <div class="divider-row divider-row3"></div>
      <span>4</span>
      <div class="divider-row divider-row3"></div>
    </div>
    <div class="pic-text pic-text-row">
      <img
        class="step4"
        src="../../../../assets/images/index/step4.webp"
        alt="step"
        width="444"
        height="122"
      />
      <div class="text">
        {{ "modalSteps.Step4" | translate }}
      </div>
    </div>
  </div>
  <div class="divider"></div>
  <div class="step">
    <div class="pic-num">
      <img
        class="step"
        src="../../../../assets/images/index/step5.webp"
        alt="step"
        width="156"
        height="218"
      />
      <div class="number">
        <span>5</span>
      </div>
    </div>
    <div class="divider-row divider-row2"></div>
    <div class="pic-num">
      <img
        class="step"
        src="../../../../assets/images/index/step6.webp"
        alt="step"
        width="148"
        height="226"
      />
      <div class="number">
        <span>6</span>
      </div>
    </div>
    <div class="divider-row divider-row2"></div>
    <div class="pic-num">
      <img
        class="step"
        src="../../../../assets/images/index/step7.webp"
        alt="step"
        width="169"
        height="211"
      />
      <div class="number">
        <span>7</span>
      </div>
    </div>
  </div>
</div>
<div class="tabs">
  <div
    class="tab"
    (click)="tab(1)"
    [ngClass]="{ active: 1 === activeIndex }"
  ></div>
  <div
    class="tab"
    (click)="tab(2)"
    [ngClass]="{ active: 2 === activeIndex }"
  ></div>
</div>
