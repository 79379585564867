import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { OnInit } from '@angular/core';

@Component({
  selector: 'app-netflix-code-modal',
  templateUrl: './netflix-code-modal.component.html',
  styleUrls: ['./netflix-code-modal.component.scss'],
})
export class NetflixCodeModalComponent implements OnInit{
  isDevice4Sure = false;
  isMobile = false;
  ngOnInit(): void {
    this.isDevice4Sure = false;
    this.isMobile = false;
  }
  
  activeIndex = 1;

  language: any;
  constructor(private dialogRef: MatDialogRef<NetflixCodeModalComponent>) {
    this.language = localStorage.getItem('language');
  }

  
  chooseMobile() {
    this.isDevice4Sure = true;
    this.isMobile = true;
    console.log(this.isDevice4Sure)
    console.log(this.isMobile)
  }

  chooseDesktop() {
    this.isDevice4Sure = true;
    this.isMobile = false;
    console.log(this.isDevice4Sure)
    console.log(this.isMobile)
  }


  tab(index: any) {
    this.activeIndex = index;
  }

  next(index: any) {
    this.activeIndex = index + 1;
  }

  skip() {
    this.activeIndex = 5;
  }

  onOk() {
    this.dialogRef.close('ok');
  }
}
