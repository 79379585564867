<button
  mat-flat-button
  [disabled]="sendButton ? false : true"
  [ngStyle]="{
    'background-color': sendButton ? '#F9F9F9' : '#C1C1C1',
    width: width,
    height: height,
    'color':sendButton ? '#f1f1f1' : '#f1f1f1',
    'border':sendButton ? '1px solid #f1f1f1' : '1px solid #f1f1f1',
    
  }"
  (click)="sendCode()"
>
  {{ timeout === 0 ? ("button.Send" | translate) : this.timeout + " S" }}
</button>
