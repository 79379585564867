import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  ViewChild,
} from '@angular/core';
import { AbstractNgModelComponent } from '../../base/abstract-ng-model/abstract-ng-model.component';
import { NG_VALUE_ACCESSOR, UntypedFormBuilder } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { WorkorderApiService } from 'src/app/service/workorder-api.service';

@Component({
  selector: 'app-issue-category-select',
  templateUrl: './issue-category-select.component.html',
  styleUrls: ['./issue-category-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: IssueCategorySelectComponent,
    },
  ],
})
export class IssueCategorySelectComponent
  extends AbstractNgModelComponent
  implements OnChanges
{
  @ViewChild(MatMenuTrigger, { static: true, read: ElementRef })
  userMenu!: ElementRef<HTMLElement>;
  @Input() platform = '';
  localStorageLanguage = '';

  get userMenuData() {
    return {
      menuWidth: this.userMenu.nativeElement.clientWidth,
    };
  }

  value: any;

  isDropdownOpen = false;
  src = '../../../../assets/images/withdraw/Icon-down-arrow-min.svg';

  constructor(
    private elementRef: ElementRef,
    private fb: UntypedFormBuilder,
    private api: WorkorderApiService
  ) {
    super();
    this.localStorageLanguage = localStorage.getItem('language') || '';
  }

  ngOnChanges(): void {
    this.findProblems();
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
    if (this.isDropdownOpen) {
      this.src = '../../../../assets/images/withdraw/Icon-up-arrow-min.svg';
    } else {
      this.src = '../../../../assets/images/withdraw/Icon-down-arrow-min.svg';
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
      this.src = '../../../../assets/images/withdraw/Icon-down-arrow-min.svg';
    }
  }

  override onChange(): void {
    super.onChange();
  }

  problem: any;
  selectOption(option: any) {
    this.isDropdownOpen = false;
    this.src = '../../../../assets/images/withdraw/Icon-down-arrow-min.svg';
    this.problem = option;
    this.value = this.problem.id;
    this.onChange();
  }

  problems: any = [];
  async findProblems() {
    const la = localStorage.getItem('language') || 'en';
    try {
      const r = await this.api.findProblem(this.platform, la);
      this.problems = r.data || [];
    } catch (error) {
    } finally {
    }
  }
}
