import { Component, OnInit } from '@angular/core';
import { AbstractNgModelComponent } from '../../base/abstract-ng-model/abstract-ng-model.component';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DealerApiService } from 'src/app/service/dealer-api.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-promotion-platform-checkbox',
  templateUrl: './promotion-platform-checkbox.component.html',
  styleUrls: ['./promotion-platform-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PromotionPlatformCheckboxComponent,
    },
  ],
})
export class PromotionPlatformCheckboxComponent extends AbstractNgModelComponent {
  value: any;
  formGroup: UntypedFormGroup;

  platforms: { id: number; icon: string; method: string }[] = [];

  isSmall = false;
  constructor(
    private fb: UntypedFormBuilder,
    private api: DealerApiService,
    private breakpointObserver: BreakpointObserver
  ) {
    super();
    this.formGroup = this.fb.group({});
    this.isSmall = this.breakpointObserver.isMatched([
      Breakpoints.XSmall,
      Breakpoints.Small,
    ]);
  }

  ngOnInit(): void {
    this.findPlatforms();
  }

  data: any;
  async findPlatforms() {
    try {
      const r = await this.api.findPlatforms();
      this.platforms = r.data.list || [];

      //创建表单
      const formControls: { [key: string]: FormControl } = {};
      this.platforms.forEach((data, index) => {
        formControls[data.id] = new FormControl(false);
      });
      this.formGroup = this.fb.group(formControls);
    } catch (error) {}
  }

  override onChange(): void {
    super.onChange();
  }

  onInputChange(event: any, id: number) {
    // 获取当前输入框的值
    const inputValue = event.target.value;
    this.formGroup.get(id.toString())?.patchValue(inputValue);
    this.value = Object.entries(this.formGroup.value)
      .filter(([id, value]) => value !== true && value !== false)
      .map(([id, value]) => ({
        promotion_method_id: id,
        promotion_link: value,
      }));
    this.onChange();
  }

  onCheckboxChange(event: any) {
    this.value = Object.entries(this.formGroup.value)
      .filter(([id, value]) => value !== true && value !== false)
      .map(([id, value]) => ({
        promotion_method_id: Number(id),
        promotion_link: value,
      }));
    this.onChange();
  }
}
