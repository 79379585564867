import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, Input, ElementRef, OnInit, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from 'src/app/service/dialog.service';
import { BeAffiliateCardComponent } from '../be-affiliate-card/be-affiliate-card.component';


@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnDestroy, OnInit {
  isSmall = false;
  subscription: Subscription;
  @Input() backgroundColor = 'linear-gradient(#000211 40%, #00000000)'; //#000211   #f5ece3

  constructor(
    private breakpointObserver: BreakpointObserver,
    private elementRef: ElementRef,
    private myDialog: DialogService, 
    private dialog: MatDialog
  ) {
    // this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
    //   .subscribe(result => {
    //     this.isSmall = result.matches; // 如果匹配手机模式，则为 true
    //   });
    this.subscription = this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .subscribe((r) => {
        this.isSmall = r.matches;
      });
  }

  

  ngOnInit(): void {
    document.addEventListener('click', this.onDocumentClick.bind(this))
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  isOpen = false;

  toggleDrawer() {
    this.isOpen = !this.isOpen;
  }
  onDocumentClick(event: MouseEvent) {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (!clickedInside && this.isOpen) {
      this.isOpen = false;  // 如果点击发生在抽屉外部且抽屉是打开状态，关闭抽屉
    }
  }

  beAffiliate() {
    // if (!this.renewButton) return;
    if(!this.isSmall){
      const dialogRef = this.dialog.open(BeAffiliateCardComponent, {
        width: '800px',
        panelClass: 'order-dialog-container', // 添加自定义样式类
      });
    }
    else{
      const dialogRef = this.dialog.open(BeAffiliateCardComponent, {
        width: '80%',
        panelClass: 'order-dialog-container', // 添加自定义样式类
      });
    }
    

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('Dialog result:', result);
    // });
  }

  closeDrawer() {
    if (this.isOpen){
      this.isOpen = false;
    }
  }
}
