<div class="container">
  <article class='mat-typography'>
      <h1>Terms and Conditions</h1>
      <div class='card'>
          <div class="content">
              <p>
                  Please read these terms and conditions carefully before using the platform. By accessing or using the platform, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms and conditions, please do not use the platform.
              </p>
          </div>
          <div class='content'>
              <h2>1. General</h2>

                  <p>
                      1.1 This Agreement is between you and PremLogin.com ("PremLogin," "we," "us," or "our"), including all attached or referenced schedules and appendices (if any).
                  </p>
                  <p>
                      1.2 This Agreement governs:
                  </p>
                  <ul>
                      <li>
                          <p>(a) The provision of PremLogin services (defined below);</p>
                      </li>
                      <li>
                          <p>(b) Your use of the platform (defined below).</p>
                      </li>
                  </ul>
                  <p>
                      The service is only available to:
                  </p>
                  <ul>
                      <li>
                          <p>
                              (a) Individuals who are at least 18 years old and have the legal capacity to enter into binding agreements under applicable laws.
                          </p>
                      </li>
                      <li>
                          <p>
                              (b) Individuals under 18 years old may only use the platform with the explicit consent and direct supervision of their parents or legal guardians. It is presumed that minors have obtained the necessary consent from their parents or legal guardians before engaging in transactions governed by this Agreement. The legal guardian or parent is fully responsible for all actions and activities of the minor under this Agreement.
                          </p>
                      </li>
                  </ul>
          </div>
          <div class='divide-line'></div>
          <div class='content'>
              <h2>2. Definition and Interpretation</h2>
              <p>2.1 Definitions:</p>
              <ul>
                  <li>
                      <p>
                          Account: The account you activate and use to access or utilize the platform.
                      </p>
                  </li>
                  <li>
                      <p>
                          Working Day: Any day that is not a Saturday, Sunday, public holiday, or bank holiday in the United Kingdom.
                      </p>
                  </li>
                  <li>
                      <p>
                          Buyer: An individual or entity purchasing goods and/or services.
                      </p>
                  </li>
                  <li>
                      <p>
                          Goods and/or Services: Digital items, tangible goods, or services listed or provided by sellers on the platform.
                      </p>
                  </li>
                  <li>
                      <p>
                          PremLogin Service: The services and features provided by PremLogin through the platform, including facilitating sales agreements between buyers and sellers.
                      </p>
                  </li>
                  <li>
                      <p>
                          Information: Data including details, content, measurements, maps, locations, photographs, textual descriptions, specifications, audio or video segments, graphics, or other relevant material.
                      </p>
                  </li>
                  <li>
                      <p>
                          Intellectual Property: Patents, registered designs, copyrights, names, marks, trade names, trademarks, service marks, and related proprietary rights.
                      </p>
                  </li>
                  <li>
                      <p>
                          Sales Contract: An agreement between a seller and a buyer for the sale of goods and/or services on the platform.
                      </p>
                  </li>
                  <li>
                      <p>
                          Seller: An individual or entity registered with PremLogin that lists or provides goods and/or services for sale on the platform.
                      </p>
                  </li>
                  <li>
                      <p>
                          Personal Data: As defined in the privacy policy.
                      </p>
                  </li>
                  <li>
                      <p>
                          Privacy Policy: The policies set forth by PremLogin regarding the processing of personal data on the platform.
                      </p>
                  </li>
                  <li>
                      <p>
                          Platform: The digital infrastructure, including the website, mobile site, or mobile application, operated by PremLogin.
                      </p>
                  </li>
                  <li>
                      <p>
                          Registration Process: The process defined by PremLogin for individuals or entities to register and create an account with PremLogin.
                      </p>
                  </li>
                  <li>
                      <p>
                          Representative: Employees, officers, agents, contractors, associates, staff, or representatives of the parties involved.
                      </p>
                  </li>
                  <li>
                      <p>
                          You/you: All users of the platform, including buyers and sellers.
                      </p>
                  </li>
              </ul>
              <p>2.2 Interpretation:</p>
              <ul>
                  <li>
                      <p>
                          (a) Words in the singular include the plural and vice versa.
                      </p>
                  </li>
                  <li>
                      <p>
                          (b) References to a natural person include corporate and unincorporated bodies.
                      </p>
                  </li>
                  <li>
                      <p>
                          (c) References to any legal statute, legislation, or specific provision include all related provisions, statutory mandates, and documents, including any amendments or re-enactments.
                      </p>
                  </li>
                  <li>
                      <p>
                          (d) Headings are for organizational convenience and do not affect the interpretation of the Agreement.
                      </p>
                  </li>
                  <li>
                      <p>
                          (e) References to any party include their legal successors and authorized assignees.
                      </p>
                  </li>
                  <li>
                      <p>
                          (f) No rule of construction or interpretation will adversely affect the party responsible for drafting this Agreement.
                      </p>
                  </li>
                  <li>
                      <p>
                          (g) Terms such as "including" or "includes" are illustrative and do not limit the general terms.
                      </p>
                  </li>
              </ul>
          </div>
          <div class='divide-line'></div>
          <div class='content'>
              <h2>3. Entering the Platform</h2>
              <p>
                  3.1 To access the PremLogin platform and its associated services, you must establish an account in accordance with the PremLogin registration process. Upon successful registration, you will be granted access to the platform and services provided by PremLogin. PremLogin reserves the right to conduct background checks and may deny access to the platform, services, or any part thereof at its sole discretion. The registration process may require additional verification or information.
              </p>
              <p>
                  3.2 You are responsible for maintaining the confidentiality and security of your account credentials, including your username and password. In the event of unauthorized access or a security breach involving your account, you must promptly notify PremLogin. All actions, instructions, or acknowledgments originating from your account will be deemed authorized by you, regardless of whether they were made by an authorized third party. PremLogin is not liable for actions taken based on instructions, notifications, or acknowledgments from your account.
              </p>
              <p>
                  3.3 You must monitor all activities and transactions associated with your account and verify the transaction history. Notify PremLogin immediately if:
              </p>
              <ul>
                  <li>
                      <p>
                          (a) There is any unusual activity or transaction on your account.
                      </p>
                  </li>
                  <li>
                      <p>
                          (b) You receive fragmented, obscured, or erroneous data from PremLogin.
                      </p>
                  </li>
                  <li>
                      <p>
                          (c) You receive data not intended for you. Such data must not be used or disclosed without PremLogin's consent.
                      </p>
                  </li>
              </ul>
              <p>
                  3.4 Unless expressly authorized by PremLogin, each individual is limited to one account. Creating accounts on behalf of or impersonating another is prohibited. PremLogin reserves the right to suspend fraudulent accounts and may take further action, including notifying relevant authorities.
              </p>
          </div>
          <div class="divide-line"></div>
          <div class="content">
              <h2>
                  4. Use of Platform/PremLogin Service
              </h2>
              <p>
                  4.1 License Grant: Subject to your compliance with these terms and conditions, PremLogin grants you a non-exclusive, non-transferable right to access and use the PremLogin platform and services for the purposes described in this Agreement. The platform and/or PremLogin services must not be used for commercial purposes.
              </p>
              <p>
                  4.2 Restrictions on Use: Unless expressly permitted in this Agreement or with prior written consent from PremLogin, you shall not:
              </p>
              <ul>
                  <li>
                      <p>
                          (a) Copy, disseminate, reproduce, or distribute the PremLogin platform/service except for standard use or backup/security purposes.
                      </p>
                  </li>
                  <li>
                      <p>
                          (b) Lease, sublease, rent, translate, adapt, alter, or modify the PremLogin platform/service.
                      </p>
                  </li>
                  <li>
                      <p>
                          (c) Modify or alter any part of the PremLogin platform/service or combine it with other software.
                      </p>
                  </li>
                  <li>
                      <p>
                          (d) Decompile, reverse engineer, disassemble, or create derivative works from any part of the PremLogin platform/service.
                      </p>
                  </li>
                  <li>
                      <p>
                          (e) Disclose any part of the platform's software to third parties.
                      </p>
                  </li>
                  <li>
                      <p>
                          (f) Use the PremLogin platform/service for illegal activities or fraudulent purposes.
                      </p>
                  </li>
                  <li>
                      <p>
                          (g) Extract or attempt to extract information from the PremLogin system or decipher transmissions to servers operating any PremLogin service.
                      </p>
                  </li>
                  <li>
                      <p>
                          (h) Access unauthorized data or information.
                      </p>
                  </li>
                  <li>
                      <p>
                          (i) Use devices or methods that disrupt the platform's operations or introduce malicious software.
                      </p>
                  </li>
                  <li>
                      <p>
                          (j) Compromise the integrity of the PremLogin software, system, or services.
                      </p>
                  </li>
                  <li>
                      <p>
                          (k) Use the PremLogin platform/service in ways that interfere with other users' access and enjoyment.
                      </p>
                  </li>
              </ul>
              <p>
                  4.3 Membership: Upon registration with PremLogin, you become a member of the PremLogin virtual community, allowing you to use subscription-based virtual home-sharing services.
              </p>
          </div>
          <div class="divide-line"></div>
          <div class="content">
              <h2>
                  5. Personal Information
              </h2>
              <p>
                  5.1 All personal data acquired by PremLogin will be managed and processed according to the platform's privacy policy.
              </p>
              <p>
                  5.2 Adherence to Data Protection Protocols: If any data or information provided by PremLogin to you, or collected or processed by you on behalf of PremLogin, includes personal data:
              </p>
              <ul>
                  <li>
                      <p>
                          (a) You agree to follow the privacy policy and not cause PremLogin to violate it.
                      </p>
                  </li>
                  <li>
                      <p>
                          (b) You agree to adhere to PremLogin's personal data protection policy.
                      </p>
                  </li>
                  <li>
                      <p>
                          (c) You will provide PremLogin access to personal data upon reasonable request.
                      </p>
                  </li>
                  <li>
                      <p>
                          (d) If you detect a breach of personal data management duties, you must inform PremLogin promptly. This clause remains in effect even after the agreement ends.
                      </p>
                  </li>
              </ul>
          </div>
          <div class="divide-line"></div>
          <div class="content">
              <h2>
                  6. Confidential Information
              </h2>
              <p>
                  6.1 Definition: "Confidential Information" includes all data or details provided to the recipient by the disclosing party, encompassing information provided before and after the commencement of this agreement. This includes technical data, proprietary techniques, business details, trade secrets, financial health, and other proprietary and confidential information.
              </p>
              <p>
                  6.2 Exclusions: "Confidential Information" does not include:
              </p>
              <ul>
                  <li>
                      <p>
                          (a) Data known to the recipient without confidentiality obligations before disclosure.
                      </p>
                  </li>
                  <li>
                      <p>
                          (b) Independently developed information.
                      </p>
                  </li>
                  <li>
                      <p>
                          (c) Publicly available information unless due to a breach by the recipient.
                      </p>
                  </li>
                  <li>
                      <p>
                          (d) Information lawfully obtained from a third party without confidentiality obligations.
                      </p>
                  </li>
              </ul>
              <p>
                  6.3 Non-Disclosure: Except as permitted in this Agreement, the recipient will not disclose Confidential Information unless required by law, regulatory authority, or court order.
              </p>
              <p>
                  6.4 Mandatory Disclosures: If legally required to disclose Confidential Information, the recipient must promptly notify PremLogin and limit disclosure to the minimum required by law.
              </p>
              <p>
                  6.5 Purpose Restriction: Confidential Information will be used solely for the Agreement's objectives.
              </p>
              <p>
                  6.6 Post-Termination: The confidentiality obligations remain in effect after the Agreement ends.
              </p>
          </div>
          <div class="divide-line"></div>
          <div class="content">
              <h2>
                  7. Protection of Consumer
              </h2>
              <p>
                  7.1 If the Consumer Protection Act of 1999 applies and you engage in transactions as a consumer under the Act:
              </p>
              <ul>
                  <li>
                      <p>
                          (a) This Agreement limits your rights and remedies only to the extent permissible under the Act.
                      </p>
                  </li>
                  <li>
                      <p>
                          (b) This Agreement does not negate PremLogin's liability for negligence or breach without cause.
                      </p>
                  </li>
              </ul>
          </div>
          <div class="divide-line"></div>
          <div class="content">
              <h2>
                  8. Warranty, Non-Availability, and Disclaimer
              </h2>
              <p>
                  8.1 PremLogin will use reasonable care and skill in delivering services.
              </p>
              <p>
                  8.2 PremLogin does not guarantee:
              </p>
              <ul>
                  <li>
                      <p>
                          (a) Platform functionalities will meet individual requirements.
                      </p>
                  </li>
                  <li>
                      <p>
                          (b) Uninterrupted or error-free platform or services.
                      </p>
                  </li>
                  <li>
                      <p>
                          (c) Immediate rectification of detected issues.
                      </p>
                  </li>
                  <li>
                      <p>
                          (d) The platform is immune to malicious software or other potential hazards.
                      </p>
                  </li>
              </ul>
              <p>
                  8.3 Users acknowledge that:
              </p>
              <ul>
                  <li>
                      <p>
                          (a) Advice or recommendations reflect subjective judgments and are acted upon at the user's discretion.
                      </p>
                  </li>
                  <li>
                      <p>
                          (b) Commercial engagements facilitated through the platform may experience lags due to factors beyond PremLogin's control.
                      </p>
                  </li>
                  <li>
                      <p>
                          (c) Platform or service availability may be compromised due to maintenance or unforeseen circumstances.
                      </p>
                  </li>
              </ul>
          </div>
          <div class="divide-line"></div>
          <div class="content">
              <h2>
                  9. Limitation of Liability
              </h2>
              <p>
                  9.1 To the maximum extent permitted by law:
              </p>
              <ul>
                  <li>
                      <p>
                          (a) The platform and services are provided "as is" without warranties.
                      </p>
                  </li>
                  <li>
                      <p>
                          (b) PremLogin is not responsible for financial implications, damages, or costs arising from information provided through the platform.
                      </p>
                  </li>
                  <li>
                      <p>
                          (c) PremLogin is not liable for indirect, ancillary, punitive, or consequential damages, including lost profits or goodwill.
                      </p>
                  </li>
                  <li>
                      <p>
                          (d) PremLogin's liability under this agreement shall not exceed SGD30 or the relevant fees paid by you for the disputed service.
                      </p>
                  </li>
              </ul>
          </div>
          <div class="divide-line"></div>
          <div class="content">
              <h2>10. Indemnification</h2>
              <p>
                  10.1 You agree to indemnify and hold PremLogin harmless from any liabilities, damages, losses, claims, costs, penalties, fines, and expenses arising from a breach of this Agreement.
              </p>
          </div>
          <div class="divide-line"></div>
          <div class="content">
              <h2>11. Force Majeure</h2>
              <p>
                  11.1 PremLogin is not liable for delays or non-performance due to causes beyond its control, including natural disasters, wars, terrorism, government actions, labor disputes, supply challenges, or infrastructure disruptions.
              </p>
          </div>
          <div class="divide-line"></div>
          <div class="content">
              <h2>
                  12. PremLogin Rights and Suspension
              </h2>
              <p>
                  12.1 PremLogin reserves the right to:
              </p>
              <ul>
                  <li>
                      <p>
                          (a) Suspend or terminate your access to the platform.
                      </p>
                  </li>
                  <li>
                      <p>
                          (b) Remove any content you have submitted.
                      </p>
                  </li>
                  <li>
                      <p>
                          (c) Restrict account activities or transactions.
                      </p>
                  </li>
                  <li>
                      <p>
                          (d) Withhold or confiscate payments under certain circumstances, including non-compliance with standards or guidelines, suspected breach of terms, or disruption to the platform.
                      </p>
                  </li>
              </ul>
          </div>
          <div class="divide-line"></div>
          <div class="content">
              <h2>13. Termination</h2>
              <p>13.1 PremLogin may terminate this Agreement with written notice if you breach or fail to comply with the terms and conditions, and such breach or non-compliance is not rectified within 30 days.</p>
              <p>13.2 Upon termination:</p>
              <ul>
                  <li>
                      <p>
                          (a) All rights granted to you will cease.
                      </p>
                  </li>
                  <li>
                      <p>
                          (b) You must stop all activities authorized by this Agreement.
                      </p>
                  </li>
                  <li>
                      <p>
                          (c) You must destroy or return all confidential information related to PremLogin.
                      </p>
                  </li>
              </ul>
          </div>
          <div class="divide-line"></div>
          <div class="content">
              <h2>14. Changes</h2>
              <p>14.1 PremLogin may revise, supplement, or modify this Agreement at any time, with changes effective upon notice.</p>
              <p>14.2 If you find changes unacceptable, you must promptly notify PremLogin. Continued use of the platform implies acceptance of the changes.</p>
              <p>14.3 PremLogin may modify or discontinue any platform features or services at its discretion.</p>
          </div>
          <div class="divide-line"></div>
          <div class="content">
              <h2>15. Notifications</h2>
              <p>
                  15.1 Notices under this Agreement must be in writing and sent to the recipient's communicated address, email, or fax number. Notices are considered delivered:
              </p>
              <ul>
                  <li>
                      <p>
                          (a) On the business day after personal delivery.
                      </p>
                  </li>
                  <li>
                      <p>
                          (b) Five business days after dispatch by registered post or courier.
                      </p>
                  </li>
                  <li>
                      <p>
                          (c) Upon receipt of a successful transmission report for facsimiles.
                      </p>
                  </li>
                  <li>
                      <p>
                          (d) Upon successful email transmission.
                      </p>
                  </li>
              </ul>
              <p>
                  15.2 Notices from PremLogin to you may be issued through the platform, email, public announcement, SMS, or support@PremLogin.com.
              </p>
          </div>
          <div class="divide-line"></div>
          <div class="content">
              <h2>
                  16. Entire Agreement
              </h2>
              <p>
                  16.1 This Agreement represents the entire agreement between the parties and supersedes all prior contracts, understandings, or arrangements. Any changes must be agreed upon in writing by both parties.
              </p>
          </div>
          <div class="divide-line"></div>
          <div class="content">
              <h2>
                  17. Assignment, Replacement, Commission, and Subcontracting
              </h2>
              <p>
                  17.1 You may not transfer or delegate your rights or obligations under this Agreement without PremLogin's written consent. PremLogin may transfer or delegate its rights and obligations to affiliated entities.​
              </p>
          </div>
          <div class="divide-line"></div>
          <div class="content">
              <h2>
                  18. No Waiver
              </h2>
              <p>
                  18.1 Failure to exercise any right under this Agreement does not constitute a waiver of that right. Waivers must be explicitly made in writing.
              </p>
          </div>
          <div class="divide-line"></div>
          <div class="content">
              <h2>
                  19. Successors and Assigns
              </h2>
              <p>
                  19.1 This Agreement binds and benefits the parties and their respective permitted assigns and successors.
              </p>
          </div>
          <div class="divide-line"></div>
          <div class="content">
              <h2>
                  20. Severability
              </h2>
              <p>
                  20.1 If any provision of this Agreement is found to be illegal, invalid, or unenforceable, it will not affect the legality, validity, or enforceability of the remaining provisions. Efforts will be made to modify the provision to make it legal, valid, and enforceable.
              </p>
          </div>
          <div class="divide-line"></div>
          <div class="content">
              <h2>
                  21. No Partnership/Agent
              </h2>
              <p>
                  21.1 This Agreement does not create a joint venture or partnership between the parties. Neither party is authorized to act on behalf of the other.
              </p>
          </div>
          <div class="divide-line"></div>
          <div class="content">
              <h2>
                  22. Applicable Law
              </h2>
              <p>
                  22.1 This Agreement is governed by the laws of the United Kingdom. Both parties submit to the jurisdiction of the courts of the United Kingdom.
              </p>
          </div>
          <div class="divide-line"></div>
          <div class="content">
              <h2>
                  23. Survival
              </h2>
              <p>
                  23.1 The terms of this Agreement that are intended to survive its termination or expiration will remain in effect.
              </p>
              <p>
                  Appendix A
              </p>
              <p>
                  Payment
              </p>
              <ul>
                  <li>
                      <p>
                          - The buyer must remit the total amount under any sales contract through the designated payment gateway on the platform.
                      </p>
                  </li>
                  <li>
                      <p>
                          - Buyers cannot offset payments owed to PremLogin against claims or payments owed to them.
                      </p>
                  </li>
                  <li>
                      <p>
                          - PremLogin may annul payment requests if fraudulent activities are suspected.
                      </p>
                  </li>
              </ul>
          </div>
          <div class="divide-line"></div>
          <div class="content">
              <h2>
                  24. General
              </h2>
              <ul>
                  <li>
                      <p>
                          The website and affiliate marketing scheme are provided "as is" and "as available" without warranties.
                      </p>
                  </li>
                  <li>
                      <p>
                          Liability for financial losses, goodwill depreciation, or consequential damages is excluded to the extent allowed by law.
                      </p>
                  </li>
              </ul>
              <p>
                  Indemnity
              </p>
              <ul>
                  <li>
                      <p>
                          You agree to indemnify PremLogin against any claims, actions, damages, losses, liabilities, costs, or expenses arising from your use of the affiliate schemes or breach of this Agreement.
                      </p>
                  </li>
              </ul>
              <p>
                  General Information
              </p>
              <p>
                  This service is jointly provided by the following companies:
              </p>
              <p>
                  1. Hong Kong Company:
              </p>
              <ul>
                  <li>
                      <p>
                          Company Name: Hongkong PremLogin Limited
                      </p>
                  </li>
                  <li>
                      <p>
                          Registered Address: Room D07, 8/F, Phase 2, Kaide Factory Building, 99 King Fuk Street, San Po Kong, Hong Kong
                      </p>
                  </li>
              </ul>
              <p>
                  2. UK Company:
              </p>
              <ul>
                  <li>
                      <p>
                          Company Name: PREMLOGIN LIMITED
                      </p>
                  </li>
                  <li>
                      <p>
                          Registered Address: Suite 6630 61 Bridge Street, Kington, United Kingdom, HR5 3DJ
                      </p>
                  </li>
              </ul>
              <p>
                  Relationship Description:
              </p>
              <ul>
                  <li>
                      <p>
                          Hongkong PremLogin Limited and PREMLOGIN LIMITED are affiliated companies, jointly operating and managing this e-commerce platform.
                      </p>
                  </li>
                  <li>
                      <p>
                          All matters related to this platform are handled and managed by the above-mentioned companies.
If you have any questions, you can contact us through the following ways:
                      </p>
                  </li>
                  <ul>
                      <li>
                          <p>
                              Email: support@premlogin.com
                          </p>
                      </li>
                      <li>
                          <p>
                              Governing Law: United Kingdom
                          </p>
                      </li>
                      <li>
                          <p>
                              Jurisdiction: Courts of the United Kingdom
                          </p>
                      </li>
                  </ul>
                  

              </ul>
          </div>
      </div>
  </article>
</div>
