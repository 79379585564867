<app-spinner [loading]="loading">
  <div class="close" (click)="close('')">
    <button>
      <img
        alt="close"
        width="24"
        height="24"
        src="../../../../assets/images/base/Icon-del-default.svg"
      />
    </button>
  </div>
  <div class="title">
    {{ "modalUpdateUserAvatar.ChooseAvatar" | translate }}
  </div>
  <div class="content" mat-dialog-content>
    <div class="item" *ngFor="let fileid of avatars">
      <div>
        <app-user-avatar
          [fileid]="fileid"
          width="90"
          height="90"
          (click)="select(fileid)"
          style="cursor: pointer"
        ></app-user-avatar>
        <mat-icon
          ><img
            alt="confirm"
            width="20"
            height="20"
            src="../../../../assets/images/user-profile/Icon-confirm.svg"
            *ngIf="selected === fileid"
        /></mat-icon>
      </div>
    </div>
  </div>
  <div class="action" mat-dialog-actions>
    <div>
      <app-button
        color="#602369"
        fontColor="#ffffff"
        width="100%"
        height="48px"
        [loading]="loading"
        (click)="submit()"
        [disabled]="!selected || selected === data.avatar"
        >{{ "button.Submit" | translate }}</app-button
      >
    </div>
  </div>
</app-spinner>
