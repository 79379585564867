import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-bus-seat-status',
  templateUrl: './bus-seat-status.component.html',
  styleUrls: ['./bus-seat-status.component.scss'],
})
export class BusSeatStatusComponent implements OnInit {
  @Input() status = '';
  cardBusSeat: any;
  constructor(private translateService: TranslateService) {
    this.translateService.onLangChange.subscribe((text: any) => {
      this.translateService.get('cardBusSeat').subscribe((text: string) => {
        this.cardBusSeat = text;
      });
    });
  }

  ngOnInit(): void {
    this.translateService.get('cardBusSeat').subscribe((text: string) => {
      this.cardBusSeat = text;
    });
  }

  text() {
    const m: any = {
      Active: this.cardBusSeat.Active,
      Inactive: this.cardBusSeat.Inactive,
    };
    return m[this.status] || this.cardBusSeat?.Unknown;
  }
}
