import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-page-header-link-button',
  templateUrl: './page-header-link-button.component.html',
  styleUrls: ['./page-header-link-button.component.scss'],
})
export class PageHeaderLinkButtonComponent implements OnInit, OnDestroy {
  @Input() url = '/';
  isActive = false;
  subscription: Subscription | null = null;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.isActive = this.router.url.split('?')[0] === this.url;
    this.subscription = this.router.events.subscribe((val) => {
      if (!(val instanceof NavigationEnd)) {
        return;
      }
      this.isActive = this.router.url.split('?')[0] === this.url;
    });
  }

  ngOnDestroy(): void {
    if (this.subscription != null) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }
}
