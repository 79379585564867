import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RegionService {
  data: string = '';

  constructor() {
    this.data = localStorage.getItem('region') || '';
  }

  observer = new Subject();
  public subscribe$ = this.observer.asObservable();

  set(data: string) {
    this.data = data;
    localStorage.setItem('region', this.data);
    this.observer.next(data);
  }
}
