<mat-card>
  <mat-card-header>
    <div mat-card-avatar class="avatar">
      <img width="50" height="50" [src]="data.avatar" alt="avatar" />
    </div>
    <mat-card-title>{{ data.nickname }}</mat-card-title>
    <mat-card-subtitle>{{ data.region }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <span>
      {{ data.content }}
    </span>
  </mat-card-content>
  <mat-card-footer>
    <img
      alt="start"
      height="14"
      width="14"
      src="../../../../assets/images/index/start.svg"
      *ngFor="let item of starts"
    />
    <img
      alt="start"
      height="14"
      width="14"
      src="../../../../assets/images/index/start-half.svg"
      *ngFor="let item of startsHalf"
    />
    <img
      alt="start"
      height="14"
      width="14"
      src="../../../../assets/images/index/start-non.svg"
      *ngFor="let item of startsNon"
    />

    <div>{{ data.time }}</div>
  </mat-card-footer>
</mat-card>
