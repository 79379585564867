import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserApiService } from 'src/app/service/user-api.service';

@Component({
  selector: 'app-carpool-users-modal',
  templateUrl: './carpool-users-modal.component.html',
  styleUrls: ['./carpool-users-modal.component.scss'],
})
export class CarpoolUsersModalComponent implements OnInit {
  constructor(
    private api: UserApiService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.getCarpoolUsers();
  }

  loading = false;
  seatUsers: any;
  maxSeatNo = 0;
  async getCarpoolUsers() {
    try {
      this.loading = true;
      const r = await this.api.getCarpoolUsers(this.data.id);
      this.seatUsers = r.data;

      const seatNumberList = new Array(this.data.bus.seat_count)
        .fill(0)
        .map((item, index) => index + 1);
      for (let i of seatNumberList) {
        var flag = false;
        for (let j of this.seatUsers) {
          if (i === j.no) {
            flag = true;
          }
        }
        if (flag === false) this.seatUsers.push({ no: i });
      }

      this.seatUsers = this.seatUsers.sort((a: any, b: any) => a.no - b.no);
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }
}
