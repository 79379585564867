<span>{{ label }}</span>
<input
  [placeholder]="placeholder"
  [(ngModel)]="value"
  (ngModelChange)="onChange()"
  [disabled]="disabled"
  [type]="passwordVisible ? 'text' : type"
/>
<ng-container *ngIf="type === 'password'">
  <img
    alt="password"
    width="17"
    height="17"
    style="margin-right: 6px"
    [src]="
      passwordVisible
        ? '../../../../assets/images/base/Icon-eye.svg'
        : '../../../../assets/images/base/Icon-eyeclose.svg'
    "
    (click)="passwordVisible = !passwordVisible"
  />
</ng-container>
