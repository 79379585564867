<div class="title">
  <img src="../../../../assets/images/subscription/youtube-thanks.svg" alt="">
  {{ "enterInvitationLink.ThankYou" | translate }}
</div>
<div class="content">
  <div class="item">{{ "enterInvitationLink.Content1" | translate }}</div>
  <div class="item">
    {{ "enterInvitationLink.Content2" | translate }}
  </div>
  <div class="item">
    {{ "enterInvitationLink.Content3" | translate }}(<span
      style="color: #CF6CFF"
      >{{ "enterInvitationLink.Click" | translate }}</span
    >)
  </div>
</div>
<div class="action" mat-dialog-close>
  <app-button mat-dialog-close
  color="#612069"
  fontColor="#ffffff"
  width="300px"
  height="60px">
    {{ "enterInvitationLink.BackToHome" | translate }}
  </app-button>
</div>
