import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-affiliate-order-detail-dialog',
  templateUrl: './affiliate-order-detail-dialog.component.html',
  styleUrls: ['./affiliate-order-detail-dialog.component.scss'],
})
export class AffiliateOrderDetailDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public orders: any) {}
}
