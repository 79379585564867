import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractNgModelComponent } from '../abstract-ng-model/abstract-ng-model.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input-icon',
  templateUrl: './input-icon.component.html',
  styleUrls: ['./input-icon.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputIconComponent,
    },
  ],
})
export class InputIconComponent
  extends AbstractNgModelComponent
  implements OnChanges
{
  @Input() label = '';
  @Input() type = 'text';
  @Input() placeholder = '';
  @Input() icon = '';
  value = '';
  passwordVisible = false;

  @Input() email = '';

  ngOnChanges(changes: SimpleChanges) {}
}
