import { Component, Input, OnChanges } from '@angular/core';
import { MessageService } from 'src/app/service/message.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { DialogService } from 'src/app/service/dialog.service';
import { ShareModalComponent } from '../share-modal/share-modal.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-refer-link-card',
  templateUrl: './refer-link-card.component.html',
  styleUrls: ['./refer-link-card.component.scss'],
})
export class ReferLinkCardComponent implements OnChanges {
  @Input() data: any;
  link = '';
  @Input() translatePageAffiliate: any;

  constructor(
    private clipboard: Clipboard,
    private message: MessageService,
    private myDialog: DialogService
  ) {}

  ngOnChanges() {
    this.link = environment.http + this.data?.code;
  }

  copy() {
    this.clipboard.copy(this.link);
    this.message.info(this.translatePageAffiliate.CopySuccessful);
  }

  share() {
    this.myDialog.open(ShareModalComponent, {
      width: '726px',
      data: this.data.code,
    });
  }
}
