import { Component, Input, OnChanges } from '@angular/core';
import { MessageService } from 'src/app/service/message.service';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-copyable-text',
  templateUrl: './copyable-text.component.html',
  styleUrls: ['./copyable-text.component.scss'],
})
export class CopyableTextComponent implements OnChanges {
  @Input() data: any;
  text = '';

  constructor(private clipboard: Clipboard, private message: MessageService) {}
  ngOnChanges() {
    if (this.data.bus.bus_account)
      this.text = this.data.bus.bus_account.password;
  }

  copy() {
    this.clipboard.copy(this.text);
    this.message.info('Copied');
  }
}
