import { Component, Input } from '@angular/core';
import { AbstractNgModelComponent } from '../abstract-ng-model/abstract-ng-model.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputComponent,
    },
  ],
})
export class InputComponent extends AbstractNgModelComponent {
  @Input() label = '';
  @Input() type = 'text';
  @Input() placeholder = '';
  @Input() override disabled: boolean = false; // 接收 disabled 属性

  value = '';
  passwordVisible = false;
}
