import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-order-status',
  templateUrl: './user-order-status.component.html',
  styleUrls: ['./user-order-status.component.scss'],
})
export class UserOrderStatusComponent implements OnInit, OnChanges {
  @Input() status = '';
  pageOrderList: any;

  constructor(private translateService: TranslateService) {
    this.translateService.onLangChange.subscribe((text: any) => {
      this.translateService.get('pageOrderList').subscribe((text: string) => {
        this.pageOrderList = text;
      });
    });
  }

  ngOnInit(): void {
    this.translateService.get('pageOrderList').subscribe((text: string) => {
      this.pageOrderList = text;
    });
  }

  color = '';
  ngOnChanges(changes: SimpleChanges): void {
    if (this.status === 'Refunded') this.color = '#A10E0E';
  }

  text() {
    const m: any = {
      Unpaid: this.pageOrderList.Unpaid,
      Paid: this.pageOrderList.Paid,
      Finished: this.pageOrderList.Finished,
      Refunded: this.pageOrderList.Refunded,
      Cancelled: this.pageOrderList.Cancelled,
      Gived: this.pageOrderList.Gived,
    };
    return m[this.status] || this.pageOrderList.Unknown;
  }
}
