// import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Component, OnInit} from '@angular/core'
// import { TranslateService } from '@ngx-translate/core'

@Component({
    selector: 'app-contact-us-panel',
    templateUrl: './contact-us-panel.component.html',
    styleUrls: ['./contact-us-panel.component.scss'],
})
export class ContactUsPanelComponent implements OnInit {
    points: any = [];
    panelSellingPoint: any;
    isSmall = false;
    constructor() {

    }

    ngOnInit(): void {

    }

    contents = [
        {
            alt: 'Customer Support',
            image: '../../../../assets/images/about-us/customer-support.png',
            title: 'Customer Support',
            contactWay: 'Website:',
            url: 'help.premlogin.com',
        },
        {
            alt: 'Business Cooperation',
            image: '../../../../assets/images/about-us/business-cooperation.png',
            title: 'Business Cooperation',
            contactWay: 'Email:',
            url: 'sales@premlogin.com',
        },
        {
            alt: 'Team Support',
            image: '../../../../assets/images/about-us/team-support.png',
            title: 'Team Support',
            contactWay: 'Email:',
            url: 'info@premlogin.com',
        }
    ]
}