import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/service/store/language.service';
import { Languages } from 'src/app/utils/region';

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss'],
})
export class LanguageSelectComponent {
  languages = Languages;
  constructor(
    private translate: TranslateService,
    private languageService: LanguageService
  ) {}
  switchLanguage(lang: string) {
    this.languageService.set(lang);
    this.translate.use(lang);
  }

  isDropdownOpen = false;
  src = '../../../../assets/images/withdraw/Icon-down-arrow-min.svg';
  // toggleDropdown() {
  //   this.isDropdownOpen = !this.isDropdownOpen;
  //   if (this.isDropdownOpen) {
  //     this.src = '../../../../assets/images/withdraw/Icon-up-arrow-min.svg';
  //   } else {
  //     this.src = '../../../../assets/images/withdraw/Icon-down-arrow-min.svg';
  //   }
  // }
}
