import { Component, OnInit, Input } from '@angular/core'

@Component({
    selector: 'app-withdrawal-list-card',
    templateUrl: './withdrawal-list-card.component.html',
    styleUrls: ['./withdrawal-list-card.component.scss'],
})
export class WithdrawalListCardComponent implements OnInit {
    @Input() data: any

    constructor(

    ){

    }
    
    ngOnInit(): void {

    }


}