import { Component } from '@angular/core';

@Component({
  selector: 'app-steps-modal',
  templateUrl: './steps-modal.component.html',
  styleUrls: ['./steps-modal.component.scss'],
})
export class StepsModalComponent {
  activeIndex = 1;

  tab(index: any) {
    this.activeIndex = index;
  }
}
