import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { AbstractNgModelComponent } from '../../base/abstract-ng-model/abstract-ng-model.component';
import { DealerApiService } from 'src/app/service/dealer-api.service';

@Component({
  selector: 'app-payment-checkbox',
  templateUrl: './payment-checkbox.component.html',
  styleUrls: ['./payment-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PaymentCheckboxComponent,
    },
  ],
})
export class PaymentCheckboxComponent
  extends AbstractNgModelComponent
  implements OnInit
{
  value: any;

  formGroup: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private api: DealerApiService) {
    super();
    this.formGroup = this.fb.group({});
  }

  ngOnInit(): void {
    this.findPayments();
  }

  payments: { id: number; icon: string; name: string }[] = [];
  async findPayments() {
    try {
      const r = await this.api.findPayments();
      this.payments = r.data.list || [];

      //创建表单
      const formControls: { [key: string]: FormControl } = {};
      this.payments.forEach((data, index) => {
        formControls[data.id] = new FormControl(false);
      });
      this.formGroup = this.fb.group(formControls);
    } catch (error) {}
  }

  onCheckboxChange(event: any) {
    this.value = Object.entries(this.formGroup.value)
      .filter(([id, value]) => value === true)
      .map(([id, value]) => id);
    this.onChange();
  }
}
