import {
  Component,
  OnChanges,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { AbstractNgModelComponent } from '../abstract-ng-model/abstract-ng-model.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PaginatorComponent,
    },
  ],
})
export class PaginatorComponent
  extends AbstractNgModelComponent
  implements OnChanges
{
  value = '';

  @Input() currentPage = 1;
  @Input() total = 0;
  @Input() pageSize = 0;
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  totalPages = 0;
  pageNumber = 2;
  ngOnChanges(): void {
    this.totalPages = Math.ceil(this.total / this.pageSize);
    this.updateVisiblePages();
  }

  Next() {
    if (this.totalPages > this.currentPage) this.currentPage++;
    this.updateVisiblePages();
    this.pageChange.emit(this.currentPage);
  }

  Previous() {
    if (this.currentPage > 1) this.currentPage--;
    this.updateVisiblePages();
    this.pageChange.emit(this.currentPage);
  }

  Goto(index: number) {
    if (!this.value && index === 0) return;
    this.currentPage = index === 0 ? Number(this.value) : index;
    this.updateVisiblePages();
    this.pageChange.emit(this.currentPage);
  }

  visiblePages: number[] = [];
  updateVisiblePages() {
    const halfPageSize = Math.floor(this.pageNumber / 2);
    const startPage = Math.max(
      Math.min(
        Math.max(1, this.currentPage - halfPageSize),
        this.totalPages - this.pageNumber - 1
      ),
      1
    );
    const endPage = Math.min(
      Math.max(Math.min(this.totalPages, this.currentPage + halfPageSize), 4),
      this.totalPages
    );

    this.visiblePages = [];
    if (startPage > 1) {
      this.visiblePages.push(1);
      if (startPage > 2) {
        this.visiblePages.push(-1);
      }
    }

    for (let page = startPage; page <= endPage; page++) {
      this.visiblePages.push(page);
    }

    if (endPage < this.totalPages) {
      if (endPage < this.totalPages - 1) {
        this.visiblePages.push(-1);
      }
      this.visiblePages.push(this.totalPages);
    }
  }
}
