<mat-card>
  <mat-card-header>
    <div class="title" style="color: white;">{{ "pageLogin.LogWithSign" | translate }}</div>
  </mat-card-header>
  <mat-card-content>
    <div class="space"></div>
    <form [formGroup]="formGroup">
      <app-login-input-icon
        [placeholder]="'placeholder.EmailAddress' | translate"
        formControlName="email"
        icon="../../../../assets/images/login/Icon-send-sms.svg"
        (keydown.enter)="continue()"
        height="90px"
      ></app-login-input-icon>
    </form>

    <app-button
      color="#602369"
      fontColor="#ffffff"
      height="60px"
      width="100%"
      fontSize="20px"
      [loading]="loading"
      (click)="continue()"
      >{{ "button.Continue" | translate }}</app-button
    >
    <div class="divider">
      <div class="leftSpaceOr"></div>
      <span>{{ "pageLogin.Or" | translate }}</span>
      <div class="rightSpaceOr"></div>
    </div>
    <div class="social-button">
      <div class="login-button">
        <asl-google-signin-button
          text="signin_with"
          type="icon"
          size="large"
          shape="pill"
          logo_alignment="center"
          width="220px"
        ></asl-google-signin-button>
      </div>
      <div class="login-button">
        <img
          width="40"
          height="40"
          src="../../../../assets/images/login/line-login.webp"
          alt="Line"
          (click)="lineLogin()"
        />
      </div>
    </div>
  </mat-card-content>
  <mat-card-footer>
    <div class="tip">
      <img
        alt="focus"
        width="20"
        height="20"
        src="../../../assets/images/login/Icon-focus.svg"
      />
      <span>
        {{ "pageLogin.Tip1" | translate }}
        <span class="file" routerLink="/terms-condition">{{
          "pageLogin.Tip2" | translate
        }}</span
        >{{ "pageLogin.Tip3" | translate
        }}<span class="file" routerLink="/privacypolicy">{{
          "pageLogin.Tip4" | translate
        }}</span>
      </span>
    </div>
    <div class="error">
      <app-error *ngIf="error?.required">{{
        "textError.RequiredEmail" | translate
      }}</app-error>
      <app-error *ngIf="error?.email">{{
        "textError.EnterEmail" | translate
      }}</app-error>
    </div>
  </mat-card-footer>
</mat-card>
