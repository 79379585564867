import { Component, Input } from '@angular/core'

@Component({
    selector: 'app-contact-us-card',
    templateUrl: './contact-us-card.component.html',
    styleUrls: ['./contact-us-card.component.scss'],
})
export class ContactUsCardComponent{
    @Input() image: any;
    @Input() title: any;
    @Input() contactWay: any;
    @Input() contactUrl: any;
    @Input() alt: any;

}