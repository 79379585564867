import { Component, DoCheck, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'src/app/service/message.service';
import { UserApiService } from 'src/app/service/user-api.service';
@Component({
  selector: 'app-update-user-nickname-dialog',
  templateUrl: './update-user-nickname-dialog.component.html',
  styleUrls: ['./update-user-nickname-dialog.component.scss'],
})
export class UpdateUserNicknameDialogComponent implements OnInit, DoCheck {
  input = '';
  textMessage: any;
  errorText = false;

  constructor(
    private api: UserApiService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private message: MessageService,
    private dialogRef: MatDialogRef<UpdateUserNicknameDialogComponent>,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.translateService.get('textMessage').subscribe((text: string) => {
      this.textMessage = text;
    });
    this.input = this.data?.nickname;
  }

  ngDoCheck(): void {
    if (this.input) this.errorText = false;
  }

  loading = false;
  async apply() {
    this.input = this.input.trim();
    if (!this.input) {
      this.errorText = true;
      return;
    }
    try {
      this.loading = true;
      const r = await this.api.updateNickname(this.input);
      if (r.status !== 0) {
        this.message.warn(this.textMessage.Unknown);
      } else {
        this.message.info(this.textMessage.NicknameUpdated);
        this.dialogRef.close(r.data);
      }
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }
}
