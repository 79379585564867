import { Component, OnInit } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { MessageService } from 'src/app/service/message.service';
import { UserApiService } from 'src/app/service/user-api.service';
import { lastValueFrom } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoadingDataService } from 'src/app/service/store/loading-data.service';
import { MatDialog } from '@angular/material/dialog';
import { NoEmailModalComponent } from '../no-email-modal/no-email-modal.component';

@Component({
  selector: 'app-email-sign-up-card',
  templateUrl: './email-sign-up-card.component.html',
  styleUrls: ['./email-sign-up-card.component.scss'],
})
export class EmailSignUpCardComponent implements OnInit {
  textMessage: any;

  constructor(
    private api: UserApiService,
    private message: MessageService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private loadingDataService: LoadingDataService,
    private dialog: MatDialog
  ) {
    this.translateService.onLangChange.subscribe((text: any) => {
      this.translateService.get('textMessage').subscribe((text: string) => {
        this.textMessage = text;
      });
    });
  }

  ngOnInit(): void {
    this.translateService.get('textMessage').subscribe((text: string) => {
      this.textMessage = text;
    });

    this.route.queryParams.subscribe((params) => {
      this.email = params['email'];
    });
    this.sendCode();
  }

  enterCode = false;
  code = '';

  continue() {
    if (this.code.length === 6) {
      this.signUp();
    } else {
      this.enterCode = true;
    }
  }

  timeout = 0;
  text = '';

  resend() {
    this.resendButton = false;
    this.sendCode();
  }

  loading = false;
  email = '';
  async signUp() {
    const token = await lastValueFrom(
      this.recaptchaV3Service.execute('sendEmailCode')
    );
    if (!token) {
      this.message.error('recaptcha failed');
      return;
    }

    try {
      this.loading = true;
      this.loadingDataService.set(this.loading);
      const r = await this.api.loginWithEmail(this.code, this.email, token);
      if (r.status === 999) {
        this.message.warn(this.textMessage.AccountBanned);
      } else if (r.status === 1101) {
        this.message.warn(this.textMessage.CodeInvalid);
      } else if (r.status === 1106) {
        this.message.warn(this.textMessage.RobotError);
      } else if (r.status !== 0) {
        this.message.warn(this.textMessage.Unknown);
      } else {
        this.router.navigate(['/login/password/setup'], {
          queryParams: { email: this.email },
        });
      }
    } catch (error) {
      this.message.error(this.textMessage.NetworkError);
    } finally {
      this.loading = false;
      this.loadingDataService.set(this.loading);
    }
  }

  async sendCode() {
    const token = await lastValueFrom(
      this.recaptchaV3Service.execute('sendEmailCode')
    );
    if (!token) {
      this.message.error('recaptcha failed');
      return;
    }
    try {
      const r = await this.api.sendLoginCode(this.email, token);
      if (r.status === 1102) {
        this.message.warn(this.textMessage.SendFrequently);
        this.timeout = r.data;
      } else if (r.status === 1103) {
        this.message.warn(this.textMessage.Failure);
      } else if (r.status === 999) {
        this.message.warn(this.textMessage.AccountBanned);
      } else if (r.status !== 0) {
        this.message.warn(this.textMessage.SendFailed);
      } else {
        this.timeout = r.data;
      }
      this.startTimeout();
    } catch (error) {
      this.message.error(this.textMessage.SendFailed);
    }
  }

  resendButton = false;

  startTimeout() {
    if (this.timeout > 0) {
      let interval = setInterval(() => {
        this.timeout -= 1;
        if (this.timeout === 0) {
          this.resendButton = true;
          clearInterval(interval);
        }
      }, 1000);
    }
  }

  onOtpChange(event: any) {
    this.code = event;
    this.enterCode = false;
  }

  noEmail() {
    this.dialog.open(NoEmailModalComponent, {
      width: '550px',
    });
  }
}
