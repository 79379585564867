<div class="container">
  <div class="content">
    <h1 class="mat-headline">
      Recent 
      <span>{{ "pageOrderList.Orders" | translate }}</span>
    </h1>
    <mat-tab-group mat-align-tabs="center">
      <mat-tab>
        <ng-template mat-tab-label>
          {{ "pageOrderList.All" | translate }}
        </ng-template>
        <ng-template matTabContent>
          <app-user-order-list></app-user-order-list>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          {{ "pageOrderList.Paid" | translate }}
        </ng-template>
        <ng-template matTabContent>
          <app-user-order-list status="Paid"></app-user-order-list>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>{{
          "pageOrderList.Finished" | translate
        }}</ng-template>
        <ng-template matTabContent>
          <app-user-order-list status="Finished"></app-user-order-list>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          {{ "pageOrderList.Refunded" | translate }}
        </ng-template>
        <ng-template matTabContent>
          <app-user-order-list status="Refunded"></app-user-order-list>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
