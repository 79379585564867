<button
  class="border-wrap"
  [ngClass]="{'border-wrap': isType1, 'border-wrap-2': isType2}"
  mat-flat-button
  [ngStyle]="{
    'background-color': disabled || loading ? '#c1c1c1' : color,
    width: width,
    height: height,
    'font-size': fontSize,
    color: fontColor,
    border: border,
    'font-weight': fontWeight,
    'border-radius': borderRadius
  }"
  [disabled]="disabled || loading"
>
  <ng-content></ng-content>
</button>


