<button [matMenuTriggerFor]="menu" id="avatar">
  <img
    alt="default-avatar"
    height="40"
    width="40"
    src="../../../../assets/images/page-header/Icon-account_circle.svg"
    *ngIf="!self"
  />
  <app-user-avatar
    [fileid]="self.avatar"
    width="40"
    height="40"
    *ngIf="self"
  ></app-user-avatar>
</button>

<mat-menu #menu="matMenu" >
  <div mat-menu-item *ngIf="!self" style="text-align: center">
    <!-- <app-button
      routerLink="/login"
      background="#612069"
      width="227px"
      height="36px"
      fontColor="#ffffff"
    >
      {{ "button.Login" | translate }}</app-button
    > -->
    <app-hollow-button
    routerLink="/login"
    background="#612069"
    width="227px"
    height="36px"
    fontColor="#ffffff"
  >
    {{ "button.Login" | translate }}</app-hollow-button
  >
  </div>
  <button mat-menu-item *ngIf="self" routerLink="/user/profile">
    <app-user-profile-menu-button [data]="self"></app-user-profile-menu-button>
  </button>
  <!-- 登陆按钮下的分界线 -->
  <div
    class="space"
    style="width: 90%; border: 1px solid #c1c1c179; margin: 5px auto 0"
  ></div>
  <button
    mat-menu-item
    (click)="goto('/subscription')"
    style="min-height: 50px"
  >
    <app-user-profile-menu-span
      src="../../../../assets/images/page-header/Icon-cart-new.svg"
      >{{
        "menuUserAvatar.Subscription" | translate
      }}</app-user-profile-menu-span
    >
  </button>
  <button mat-menu-item (click)="goto('/help')" style="min-height: 50px">
    <app-user-profile-menu-span
      src="../../../../assets/images/page-header/Icon-help-new.svg"
      >{{
        "menuUserAvatar.HelpSupport" | translate
      }}</app-user-profile-menu-span
    >
  </button>
  <button mat-menu-item (click)="goto('/orders')" style="min-height: 50px">
    <app-user-profile-menu-span
      src="../../../assets/images/page-header/Icon-list-new.svg"
      >{{
        "menuUserAvatar.OrderHistory" | translate
      }}</app-user-profile-menu-span
    >
  </button>
  <button
    mat-menu-item
    (click)="goto('/affiliate/dashboard')"
    style="min-height: 50px"
    *ngIf="self?.affiliate.is_partner"
  >
    <app-user-profile-menu-span
      src="../../../../assets/images/page-header/Icon-cooperate-new.svg"
      >{{
        "menuUserAvatar.AffiliateDashboard" | translate
      }}</app-user-profile-menu-span
    >
  </button>
  <button
    mat-menu-item
    [matMenuTriggerFor]="menuLanguage"
    style="min-height: 50px"
  >
    <app-user-profile-menu-span
      src="../../../../assets/images/page-header/Icon-world-new.svg"
      >{{
        "menuUserAvatar.ChangeLanguage" | translate
      }}</app-user-profile-menu-span
    >
  </button>
  <div mat-menu-item *ngIf="self" style="text-align: center">
    <app-hollow-button
      color="#757575"
      fontColor="#ffffff"
      width="100%"
      height="36px"
      (click)="logout()"
      >{{ "button.Logout" | translate }}</app-hollow-button
    >
  </div>
</mat-menu>

<mat-menu #menuLanguage="matMenu" style="width: fit-content">
  <button
    mat-menu-item
    *ngFor="let language of languages"
    (click)="switchLanguage(language.code)"
  >
    {{ language.name }}
  </button>
</mat-menu>

