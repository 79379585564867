import {
  Component,
  Input,
  OnChanges,
  ElementRef,
  SimpleChanges,
} from '@angular/core';
import { Line, LineOptions } from '@antv/g2plot';

@Component({
  selector: 'app-g2-line',
  templateUrl: './g2-line.component.html',
  styleUrls: ['./g2-line.component.scss'],
})
export class G2LineComponent implements OnChanges {
  @Input() cfg: LineOptions | null = null;
  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.render();
  }

  plot: Line | null = null;
  render() {
    if (!this.cfg) {
      return;
    }
    if (!this.plot) {
      this.plot = new Line(
        this.el.nativeElement.querySelector('#line'),
        this.cfg
      );
    } else {
      this.plot.update(this.cfg);
    }
    this.plot.render();
  }
}
