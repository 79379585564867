import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input() color = '';
  @Input() height = 'auto';
  @Input() width = 'auto';
  @Input() fontSize = '18px';
  @Input() fontColor = '#ffffff';
  @Input() disabled = false;
  @Input() loading = false;
  @Input() border = 'none';
  @Input() fontWeight = '600';
  @Input() borderRadius = '36px';
  @Input() type = '1';

  isType1: boolean = true
  isType2: boolean = false;
  ngOnInit(): void {
      this.isType1 = this.type === '1'
      this.isType2 = !this.isType1
  }
}
