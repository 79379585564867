import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookie-consent-modal',
  templateUrl: './cookie-consent-modal.component.html',
  styleUrls: ['./cookie-consent-modal.component.scss'],
})
export class CookieConsentModalComponent implements OnInit {
  closed = false;
  ngOnInit(): void {
    this.closed = Boolean(localStorage.getItem('allowCookie'));
  }

  close() {
    this.closed = true;
  }

  accept() {
    localStorage.setItem('allowCookie', 'true');
    this.closed = true;
  }
}
