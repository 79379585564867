<div class="close">
  <button mat-dialog-close>
    <img
      alt="close"
      width="24"
      height="24"
      src="../../../../assets/images/base/Icon-del-default.svg"
    />
  </button>
</div>
<div class="title">
  {{ "pageAffiliate.GetRewarded" | translate }}
</div>
<div class="content" mat-dialog-content>
  <div class="item">
    <a>
      <button (click)="copy()">
        <img
          alt="img"
          width="80"
          height="80"
          src="../../../../assets/images/affiliate/share-Copy.webp"
        />
      </button>
    </a>
  </div>
  <div class="item" *ngFor="let data of socialMedial">
    <a [href]="data.href" target="_blank">
      <button>
        <img alt="img" width="80" height="80" [src]="data.src" />
      </button>
    </a>
  </div>
</div>
<div class="action" mat-dialog-actions>
  <div>
    <app-button
      color="#602369"
      fontColor="#ffffff"
      width="100%"
      height="48px"
      (click)="cancel()"
      >{{ "button.Cancel" | translate }}</app-button
    >
  </div>
</div>
