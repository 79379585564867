<button
  [matMenuTriggerData]="userMenuData"
  [matMenuTriggerFor]="menu"
  (click)="toggleDropdown()"
  [ngStyle]="{
    direction: localStorageLanguage === 'ar' ? 'rtl' : 'ltr'
  }"
  [disabled]="disabled"
>
  <ng-container *ngIf="value"
    >{{ value?.bus.platform.name }} -
    {{ value?.bus.bus_account.username }}
  </ng-container>
  <ng-container *ngIf="!value">{{
    "modalCreateNewTicket.Select1Pla" | translate
  }}</ng-container>
  <img alt="arrow" width="20" height="20" [src]="src" />
</button>
<mat-menu #menu="matMenu" class="my-menu">
  <ng-template matMenuContent let-menuWidth="menuWidth">
    <div [style.width.px]="menuWidth">
      <button mat-menu-item *ngIf="seats.length === 0">
        {{ "modalCreateNewTicket.NoData" | translate }}
      </button>
      <button
        mat-menu-item
        *ngFor="let data of seats"
        (click)="selectOption(data)"
      >
        <span
          >{{ data.bus.platform.name }} -
          {{ data.bus.bus_account.username }}</span
        >
      </button>
    </div>
  </ng-template>
</mat-menu>
