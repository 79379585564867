<app-spinner [loading]="loading">
  <div class="close">
    <button mat-dialog-close>
      <img
        alt="close"
        width="24"
        height="24"
        src="../../../../assets/images/base/Icon-del-default.svg"
      />
    </button>
  </div>
  <div class="title">{{ "modalNickname.EditNickname" | translate }}</div>
  <div class="content">
    <div class="input">
      <app-input
        [(ngModel)]="input"
        [placeholder]="'placeholder.Nickname' | translate"
        (keydown.enter)="apply()"
      ></app-input>
    </div>
    <div class="error">
      <app-error *ngIf="errorText">{{
        "textError.Nickname" | translate
      }}</app-error>
    </div>
  </div>
  <div class="action" mat-dialog-actions>
    <div>
      <app-button
        color="#612069"
        fontColor="#ffffff"
        width="100%"
        height="48px"
        [loading]="loading"
        (click)="apply()"
        >{{ "button.Apply" | translate }}</app-button
      >
    </div>
  </div>
</app-spinner>
