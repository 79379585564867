import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { enc,SHA256 } from 'crypto-js';


// const CryptoJS = require('crypto-js')


@Component({
  selector: 'app-startup-screen',
  template: `
    <div>
      <h1>Welcome to Our Site</h1>
      <form (submit)="checkPassword()">
        <input type="password" [(ngModel)]="pwd" placeholder="Enter password" name="password">
        <button type="submit">Enter</button>
      </form>
    </div>
  `,
  styles: []
})
export class StartupScreenComponent {
  pwd: string = '';

  constructor(private router: Router) {}

  async checkPassword() {
    const hashedPassword = await this.hashPassword(this.pwd);
    const now = new Date().getTime();
    const ttl = 3600000; // 设置超时时间为3600秒
    const expireAt = now + ttl;
    console.log(JSON.stringify({ hashedPassword, expireAt }))
    localStorage.setItem('hashedPwd', JSON.stringify({ hashedPassword, expireAt }));
    this.router.navigate(['/']); 
  }

  private async hashPassword(password: string): Promise<string> {
    const hash = SHA256(password);
    return hash.toString(enc.Hex);
  }
}