import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component } from '@angular/core';
@Component({
  selector: 'app-platform-modal',
  templateUrl: './platform-modal.component.html',
  styleUrls: ['./platform-modal.component.scss'],
})
export class PlatformModalComponent {
  isSmall = false;
  constructor(private breakpointObserver: BreakpointObserver) {
    this.isSmall = this.breakpointObserver.isMatched([Breakpoints.XSmall]);
  }
}
