import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import {  Router } from '@angular/router';
import { enc,SHA256 } from 'crypto-js';


export const accessGuard: CanActivateFn = (route, state) => {
  // const router = inject(Router);
  // const itemStr = localStorage.getItem('hashedPwd');
  // if (!itemStr) {
  //   console.log("登录失败，未找到登录凭证");
  //   router.navigate(['/startup']);
  //   return false;
  // }

  // const item = JSON.parse(itemStr);
  // const now = new Date().getTime();
  // const expectedHash = SHA256("premlogin@520!").toString(enc.Hex);

  // // 检查存储的哈希密码是否与预期的相同
  // if (item.hashedPassword !== expectedHash) {
  //   console.log("登录失败，密码不匹配");
  //   router.navigate(['/startup']);
  //   return false;
  // }

  // // 检查是否过期

  // console.log("now: "+now)
  // console.log("expireAt: "+item.expireAt)
  // if (now > item.expireAt) {
  //   console.log("登录信息已过期");
  //   router.navigate(['/startup']);
  //   localStorage.removeItem('hashedPwd');  // 清除过期数据
  //   return false;
  // }

  // console.log("登录成功");
  return true;
};
