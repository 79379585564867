<app-spinner [loading]="loading">
  <div class="close">
    <button mat-dialog-close>
      <img
        alt="del"
        width="24"
        height="24"
        src="../../../../assets/images/base/Icon-del-default.svg"
      />
    </button>
  </div>
  <div class="title">{{ "modalPromoCode.PromoCode" | translate }}</div>
  <div class="content" mat-dialog-content>
    <form [formGroup]="formGroup">
      <app-input
        [placeholder]="'placeholder.PromoCode' | translate"
        formControlName="code"
      ></app-input>
    </form>
    <div class="error">
      <app-error *ngIf="errorText">{{ errorText }}</app-error>
    </div>
  </div>
  <div class="action" mat-dialog-actions>
    <app-button
      color="#612069"
      fontColor="#ffffff"
      width="282px"
      height="48px"
      [loading]="loading"
      (click)="apply()"
      >{{ "button.Apply" | translate }}</app-button
    >
  </div>
</app-spinner>
