import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss'],
})
export class NotFoundPageComponent implements OnInit {
  isSmall: any;
  constructor(
    private title: Title,
    private router: Router,
    private meta: Meta,
    private breakpointObserver: BreakpointObserver
  ) {
    this.isSmall = this.breakpointObserver.isMatched([
      Breakpoints.XSmall,
      Breakpoints.Small,
    ]);
    if (this.isSmall) {
      this.title.setTitle('PremLogin');
    } else {
      this.title.setTitle('Page Not Found - PremLogin');
    }

    this.meta.updateTag({
      name: 'description',
      content: 'Not Found Page',
    });
  }

  ngOnInit(): void {}

  back() {
    this.router.navigate(['/'], { replaceUrl: true });
  }
}
