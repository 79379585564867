import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component } from '@angular/core';

@Component({
  selector: 'app-affiliate-partner-panel',
  templateUrl: './affiliate-partner-panel.component.html',
  styleUrls: ['./affiliate-partner-panel.component.scss'],
})
export class AffiliatePartnerPanelComponent {
  isSmall = false;
  constructor(private breakpointObserver: BreakpointObserver) {
    this.isSmall = this.breakpointObserver.isMatched([
      Breakpoints.XSmall,
      Breakpoints.Small,
    ]);
  }
}
