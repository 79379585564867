import { Component, OnInit, Input } from '@angular/core'

@Component({
    selector: 'app-affiliate-order-list-card',
    templateUrl: './affiliate-order-list-card.component.html',
    styleUrls: ['./affiliate-order-list-card.component.scss'],
})
export class AffiliateOrderListCardComponent implements OnInit {
    @Input() data: any

    constructor(

    ){

    }
    
    ngOnInit(): void {

    }


}