<div class="orderLists">
  

  <app-spinner [loading]="loading" *ngIf="loading"></app-spinner>
  <div class="no-data" *ngIf="orders.length === 0 && !loading">
    <app-no-data
      src="../../../../assets/images/base/empty-data.webp"
      [width]="338"
      [height]="274"
      [margin]="0"
      >{{ "pageAffiliate.Nodata" | translate }}</app-no-data
    >
  </div>
  <div class="content" mat-dialog-content>
    <div class="order" *ngFor="let data of orders">
      <app-affiliate-order-list-card [data]="data"></app-affiliate-order-list-card>
    </div>
  </div>
</div>
<div class="pagination" *ngIf="orders.length !== 0 && !loading">
  <app-paginator
    [currentPage]="page"
    [total]="total"
    [pageSize]="pageSize"
    (pageChange)="onPageChange($event)"
  ></app-paginator>
</div>
