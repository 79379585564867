<div class="container">
  <article class='mat-typography'>
    <h1>{{ "pageRefundPolicy.title" | translate }}</h1>
    <div class="card">
      <div class="content">
        <h2>{{ "pageRefundPolicy.premLoginRefundPolicy" | translate}}</h2>
        <p>{{ "pageRefundPolicy.content-1" | translate}}</p>
      </div>
      <div class="divide-line"></div>
      <div class="content">
        <h2>{{ "pageRefundPolicy.conditionsTitle" | translate }}</h2>
        <p>{{ "pageRefundPolicy.conditionContent"|translate }}</p>

        <p>
          {{ "pageRefundPolicy.24HoursTitle" | translate}}
        </p>
        <ul>
          <li>
            <p>
              {{ "pageRefundPolicy.24HoursContent" | translate}}
            </p>
          </li>
        </ul>

        <p>
          {{ "pageRefundPolicy.duplicatePaymentsTitle" | translate}}
        </p>
        <ul>
          <li>
            <p>
              {{ "pageRefundPolicy.duplicatePaymentsContent" | translate}}
            </p>
          </li>
        </ul>

        <p>
          {{ "pageRefundPolicy.subscriptionNotReceivedTitle" | translate}}
        </p>
        <ul>
          <li>
            <p>
              {{ "pageRefundPolicy.subscriptionNotReceivedContent" | translate}}
            </p>
          </li>
        </ul>

        <p>
          {{ "pageRefundPolicy.technicalIssuesTitle" | translate}}
        </p>
        <ul>
          <li>
            <p>
              {{ "pageRefundPolicy.technicalIssuesContent" | translate}}
            </p>
          </li>
        </ul>

        <p>
          {{ "pageRefundPolicy.specificSubscriptionTypeDissatisfactionTitle" | translate}}
        </p>
        <ul>
          <li>
            <p>
              {{ "pageRefundPolicy.specificSubscriptionTypeDissatisfactionContent" | translate}}
            </p>
          </li>
        </ul>

      </div>

      <div class="divide-line"></div>
      <div class="content">
        <h2>{{"pageRefundPolicy.nonRefundableSituationsTitle" | translate}}</h2>
        <p>{{"pageRefundPolicy.nonRefundableSituationsContent"| translate}}</p>
        
        <p>{{"pageRefundPolicy.unpaidOrdersTitle"| translate}}</p>
        <ul>
          <li>
            <p>
              {{"pageRefundPolicy.unpaidOrdersContent"| translate}}
            </p>
          </li>
        </ul>

        <p>{{"pageRefundPolicy.specialSubscriptionsTitle"| translate}}</p>
        <ul>
          <li>
            <p>
              {{"pageRefundPolicy.specialSubscriptionsContent"| translate}}
            </p>
          </li>
        </ul>

        <p>{{"pageRefundPolicy.otherReasonsNotMeetingRefundTermsTitle"| translate}}</p>
        <ul>
          <li>
            <p>
              {{"pageRefundPolicy.otherReasonsNotMeetingRefundTermsContent"| translate}}
            </p>
          </li>
        </ul>
      
      </div>

      <div class="divide-line"></div>
      <div class="content">
        <h2>
          {{"pageRefundPolicy.refundAmountCalculationTitle"|translate}}
        </h2>
        <p>
          {{"pageRefundPolicy.refundAmountCalculationContent"|translate}}
        </p>
      </div>
      <div class="divide-line"></div>
      <div class="content">
        <h2>
          {{"pageRefundPolicy.additionalConsequencesOfRefundsTitle"|translate}}
        </h2>
        <ul>
          <li>
            <p>
              {{"pageRefundPolicy.impactOnPersonalAccountTitle"|translate}}
            </p>
            <ul>
              <li>
                <p>
                  {{"pageRefundPolicy.impactOnPersonalAccountContent"|translate}}
                </p>
              </li>
            </ul>
          </li>

          <li>
            <p>
              {{"pageRefundPolicy.consequencesOfViolatingRefundPolicyTitle"|translate}}
            </p>
            <ul>
              <li>
                <p>
                  {{"pageRefundPolicy.consequencesOfViolatingRefundPolicyContent"|translate}}
                </p>
              </li>
            </ul>
          </li>
        </ul>
        <p>
          {{"pageRefundPolicy.additionalConsequencesOfRefundsContent"|translate}}
        </p>
      </div>
    </div>
  </article>
</div>
