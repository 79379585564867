<div 
    class="carousel-container"
>
    <span (click)="prevSlide()" class="zuojiantou">
        <img
        alt="arrow"
        width="25"
        height="25"
        src="../../../../assets/images/index/Icon-arrow-card-left.svg"
        >
    </span>
    <div 
        class="carousel-slide"
        *ngFor="let data of reviewData; let i = index"
        [ngClass]="{
            active: i === activeIndex,
            next: i === nextIndex,
            next2: i === next2Index,
            next3: i === next3Index
        }"
        >
        <app-team-member-card [data]="data"></app-team-member-card>
    </div>
    <span (click)="nextSlide()" class="youjiantou">
        <img
        alt="arrow"
        width="25"
        height="25"
        src="../../../../assets/images/index/Icon-arrow-card-right.svg"
        >
    </span>
    <div class="carousel-footer">
        <span
        *ngFor="let data of reviewData; let i = index"
        (click)="toSlide(i)"
        [ngClass]="{active: i === activeIndex}"
        >
        </span>
    </div>

</div>