import { Component, Input } from '@angular/core'
import { Router } from '@angular/router';

@Component({
    selector: 'app-partner-card',
    templateUrl: './partner-card.component.html',
    styleUrls: ['./partner-card.component.scss']
})
export class PartnerCardComponent {
    @Input() image: any;
    @Input() title: any;
    @Input() content: any;
    @Input() alt: any;
    constructor(private router: Router){

    }

    toAffiliate(){
        this.router.navigate(['affiliate/dashboard'])
    }
}