import { Component,Input} from '@angular/core';

@Component({
  selector: 'app-hollow-button',
  templateUrl: './hollow-button.component.html',
  styleUrls: ['./hollow-button.component.scss']
})
export class HollowButtonComponent {
  @Input() color = '';
  @Input() height = 'auto';
  @Input() width = 'auto';
  @Input() fontSize = '18px';
  @Input() fontColor = '#612069';
  @Input() disabled = false;
  @Input() loading = false;
  @Input() border = 'none';
  @Input() fontWeight = '600';



  adjustedWidth: string = '';
  adjustedHeight: string = '';

  ngOnInit() {
    this.adjustDimensions();
  }

  ngOnChanges() {
    this.adjustDimensions();
  }

  private adjustDimensions() {
    if (this.width && this.width !== 'auto') {
      this.adjustedWidth = `calc(${this.width} - 2px)`;
    } else {
      this.adjustedWidth = this.width;
    }

    if (this.height && this.height !== 'auto') {
      this.adjustedHeight = `calc(${this.height} - 2px)`;
    } else {
      this.adjustedHeight = this.height;
    }
  }
}
