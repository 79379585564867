import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OrderApiService } from 'src/app/service/order-api.service';
import { RefundUserOrderModalComponent } from '../refund-user-order-modal/refund-user-order-modal.component';
import { DialogService } from 'src/app/service/dialog.service';
import { Crisp } from 'crisp-sdk-web';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'

@Component({
  selector: 'app-user-order-list',
  templateUrl: './user-order-list.component.html',
  styleUrls: ['./user-order-list.component.scss'],
})
export class UserOrderListComponent implements OnInit {
  @Input() status = '';
  loading = false;
  orders: any[] = [];
  query = '';
  queryStatus = '';
  page = 1;
  pageSize = 5;
  total = 0;
  price: any;
  isSmall: boolean = false

  constructor(
    private api: OrderApiService, 
    private myDialog: DialogService,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.queryStatus = this.status;
    this.findUserOrders();
    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
      .subscribe(result => {
        this.isSmall = result.matches; // 如果匹配手机模式，则为 true
      });
  }

  onPageChange(e: any) {
    this.page = e;
    this.findUserOrders();
  }

  async findUserOrders() {
    try {
      this.loading = true;
      const r = await this.api.findUserOrders(
        this.query,
        this.queryStatus,
        this.page,
        this.pageSize
      );
      const data = r.data;
      this.orders = data.list;
      this.page = data.page;
      this.pageSize = data.page_size;
      this.total = data.total;
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }

  refund(data: any) {
    if (this.checkPaidAt(data.paid_time)) return;
    Crisp.chat.open();

    // const that = this;
    // this.myDialog.open(
    //   RefundUserOrderModalComponent,
    //   {
    //     width: '550px',
    //     data: data,
    //   },
    //   function callback(r: any) {
    //     that.findUserOrders();
    //   }
    // );
  }

  checkPaidAt(paidAt: string) {
    if (!paidAt) {
      return false;
    }
    const d = new Date(paidAt).getTime();
    const now = new Date().getTime();
    return now - d > 3600 * 24 * 1000;
  }
}
