import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-withdrawal-list-cards',
  templateUrl: './withdrawal-list-cards.component.html',
  styleUrls: ['./withdrawal-list-cards.component.scss'],
})
export class WithdrawalListCardsComponent {
  @Input() records: any[] = [];
  @Input() loading = false;
}
