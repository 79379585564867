

<div class="border-box border-bg"
[ngStyle]="{
    width: width,
    height: height,
    'padding-left': '1px',
    'padding-right': '1px'
}"
>
    <button
    class="border-wrap"
    mat-flat-button
    [ngStyle]="{
        'background-color': disabled || loading ? '#c1c1c1' : color,
        width: adjustedWidth,
        height: adjustedHeight,
        'font-size': fontSize,
        color: fontColor,
        border: border,
        'font-weight': fontWeight
    }"
    [disabled]="disabled || loading"
    >
    <ng-content></ng-content>
    </button>
</div>
