import { Component, OnInit, DoCheck } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { MessageService } from 'src/app/service/message.service';
import { UserApiService } from 'src/app/service/user-api.service';
import { lastValueFrom } from 'rxjs';
import { Crisp } from 'crisp-sdk-web';
import { FileApiService } from 'src/app/service/file-api.service';
import { UserService } from 'src/app/service/store/user.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadingDataService } from 'src/app/service/store/loading-data.service';
import { NoEmailModalComponent } from '../no-email-modal/no-email-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-login-with-verification-card',
  templateUrl: './login-with-verification-card.component.html',
  styleUrls: ['./login-with-verification-card.component.scss'],
})
export class LoginWithVerificationCardComponent implements OnInit, DoCheck {
  formGroup: UntypedFormGroup;
  tip1 = false;
  tip2 = false;
  email = '';
  error = false;
  textMessage: any;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private api: UserApiService,
    private message: MessageService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private fileapi: FileApiService,
    private userService: UserService,
    private translateService: TranslateService,
    private loadingDataService: LoadingDataService,
    private dialog: MatDialog
  ) {
    this.route.queryParams.subscribe((params) => {
      this.email = params['email'];
    });

    this.formGroup = this.fb.group({
      email: [this.email, [Validators.required, Validators.email]],
      code: ['', [Validators.required]],
    });

    this.formGroup.valueChanges.subscribe((newValues) => {
      this.error = false;
    });

    this.translateService.onLangChange.subscribe((text: any) => {
      this.translateService.get('textMessage').subscribe((text: string) => {
        this.textMessage = text;
      });
    });
  }

  ngOnInit(): void {
    this.translateService.get('textMessage').subscribe((text: string) => {
      this.textMessage = text;
    });
  }

  ngDoCheck(): void {
    this.loading = this.loadingDataService.loading();
    this.loadingDataService.set(this.loading);
  }

  changeLogin() {
    this.router.navigate(['/login/password'], {
      queryParams: { email: this.email },
    });
  }

  loading = false;
  async loginWithEmail() {
    const token = await lastValueFrom(
      this.recaptchaV3Service.execute('checkEmail')
    );
    if (!token) {
      this.message.error('recaptcha failed');
      return;
    }
    const code = this.formGroup.value['code'];
    this.email = this.formGroup.value['email'];

    try {
      this.loading = true;
      this.loadingDataService.set(this.loading);
      const r = await this.api.loginWithEmail(code, this.email, token);
      if (r.status === 999) {
        this.message.warn(this.textMessage.AccountBanned);
      } else if (r.status === 1101) {
        this.message.warn(this.textMessage.CodeInvalid);
      } else if (r.status === 1106) {
        this.message.warn(this.textMessage.RobotError);
      } else if (r.status !== 0) {
        this.message.warn(this.textMessage.Unknown);
      } else {
        this.afterLogin(r.data);
      }
    } catch (error) {
      this.message.error(this.textMessage.NetworkError);
    } finally {
      this.loading = false;
      this.loadingDataService.set(this.loading);
    }
  }

  afterLogin(data: any) {
    this.router.navigate(['/']);
    this.userService.set(data);
    try {
      Crisp.user.setEmail(data.email);
      Crisp.user.setNickname(data.nickname);
      Crisp.user.setAvatar(this.fileapi.fileurl(data.avatar));
    } catch {}
  }

  checkError() {
    const errors = this.formGroup.controls['code'].errors;
    if (errors && errors['required']) {
      this.error = true;
    }
  }

  login() {
    this.checkError();
    if (!this.error) this.loginWithEmail();
  }

  noEmail() {
    this.dialog.open(NoEmailModalComponent, {
      width: '794px',
      panelClass: 'order-dialog-container', // 添加自定义样式类
    });
  }
}
