import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-tip-netflix-code-modal',
  templateUrl: './tip-netflix-code-modal.component.html',
  styleUrls: ['./tip-netflix-code-modal.component.scss'],
})
export class TipNetflixCodeModalComponent {
  constructor(private dialogRef: MatDialogRef<TipNetflixCodeModalComponent>) {}

  close() {
    this.dialogRef.close();
  }

  onOk() {
    this.dialogRef.close('ok');
  }
}
