<div class="table" *ngIf="!isSmall">
  <app-spinner [loading]="loading" *ngIf="loading"></app-spinner>
  <div class="no-data" *ngIf="orders.length === 0 && !loading">
    <app-no-data src="../../../../assets/images/base/empty.webp">{{
      "pageOrderList.NoData" | translate
    }}</app-no-data>
  </div>
  <div class="list" *ngIf="!loading && orders.length > 0">
    <table>
      <tr class="attribute-name">
        <th>{{ "pageOrderList.OrderID" | translate }}</th>
        <th>{{ "pageOrderList.Product" | translate }}</th>
        <th>{{ "pageOrderList.Duration" | translate }}</th>
        <th>{{ "pageOrderList.Price" | translate }}</th>
        <th>{{ "pageOrderList.Status" | translate }}</th>
        <th>{{ "pageOrderList.CreateTime" | translate }}</th>
        <th>{{ "pageOrderList.Actions" | translate }}</th>
      </tr>
      <tr *ngFor="let data of orders">
        <td>{{ data.id }}</td>
        <td>
          <app-image
            [fileid]="data.bus_type_snapshot.platform.icon"
            width="122"
            height="51"
            style="vertical-align: middle"
          ></app-image>
        </td>
        <td>{{ data.bus_type_snapshot.name }}</td>
        <td>
          <app-money
            [price]="{ currency: data.currency, value: data.pay_price }"
            fontSize="16px"
            fontWeight="400px"
          ></app-money>
        </td>
        <td>
          <app-user-order-status [status]="data.status"></app-user-order-status>
        </td>
        <td>
          <app-datetime [text]="data.created_time"></app-datetime>
        </td>
        <td style="width: 200px">
          <app-button
            width="163px"
            height="46px"
            color="#F5ECE3"
            [fontColor]="checkPaidAt(data.paid_time) ? '#602369' : '#ffffff'"
            fontSize="16px"
            fontWeight="500"
            [border]="
              checkPaidAt(data.paid_time)
                ? '1px solid #C1C1C1'
                : '1px solid #602369'
            "
            [disabled]="checkPaidAt(data.paid_time)"
            *ngIf="!(data.status === 'Refunded' || data.status === 'Gived')"
            (click)="refund(data)"
            >{{ "pageOrderList.Refund" | translate }}</app-button
          >
        </td>
      </tr>
    </table>
  </div>
  <div class="pagination">
    <app-paginator
      *ngIf="!loading && orders.length > 0"
      [currentPage]="page"
      [total]="total"
      [pageSize]="pageSize"
      (pageChange)="onPageChange($event)"
    ></app-paginator>
  </div>
</div>

<div class="table" *ngIf="isSmall">
  <app-spinner [loading]="loading" *ngIf="loading"></app-spinner>
  <div class="no-data" *ngIf="orders.length === 0 && !loading">
    <app-no-data src="../../../../assets/images/base/empty.webp">{{
      "pageOrderList.NoData" | translate
    }}</app-no-data>
  </div>
  <div class="cards" *ngIf="!loading && orders.length > 0">
    <mat-card *ngFor="let data of orders">
      <div class="item">
        <div class="title">
          {{ "pageOrderList.OrderID" | translate }}
        </div>
        <div class="content">
          {{ data.id }}
        </div>
      </div>

      <div class="item">
        <div class="title">
          {{ "pageOrderList.Product" | translate }}
        </div>
        <div class="content">
          <app-image
            [fileid]="data.bus_type_snapshot.platform.icon"
            width="122"
            height="51"
            style="vertical-align: middle"
          ></app-image>
        </div>
      </div>

      <div class="item">
        <div class="title">
          {{ "pageOrderList.Duration" | translate }}
        </div>
        <div class="content">
          {{ data.bus_type_snapshot.name }}
        </div>
      </div>

      <div class="item">
        <div class="title">
          {{ "pageOrderList.Price" | translate }}
        </div>
        <div class="content">
          <app-money
            [price]="{ currency: data.currency, value: data.pay_price }"
            fontSize="16px"
            fontWeight="400px"
          ></app-money>
        </div>
      </div>

      <div class="item">
        <div class="title">
          {{ "pageOrderList.Status" | translate }}
        </div>
        <div class="content">
          <app-user-order-status [status]="data.status"></app-user-order-status>
        </div>
      </div>

      <div class="item">
        <div class="title">
          {{ "pageOrderList.CreateTime" | translate }}
        </div>
        <div class="content">
          <app-datetime [text]="data.created_time"></app-datetime>
        </div>
      </div>

      <div class="item">
        <div class="title">
          {{ "pageOrderList.Actions" | translate }}
        </div>
        <div class="content">
          <app-button
            width="163px"
            height="46px"
            color="#F5ECE3"
            [fontColor]="checkPaidAt(data.paid_time) ? '#ffffff' : '#602369'"
            fontSize="16px"
            fontWeight="500"
            [border]="
              checkPaidAt(data.paid_time)
                ? '1px solid #C1C1C1'
                : '1px solid #602369'
            "
            [disabled]="checkPaidAt(data.paid_time)"
            *ngIf="!(data.status === 'Refunded' || data.status === 'Gived')"
            (click)="refund(data)"
            >{{ "pageOrderList.Refund" | translate }}</app-button
          >
        </div>
      </div>
    </mat-card>
  </div>

  <div class="pagination">
    <app-paginator
      *ngIf="!loading && orders.length > 0"
      [currentPage]="page"
      [total]="total"
      [pageSize]="pageSize"
      (pageChange)="onPageChange($event)"
    ></app-paginator>
  </div>
</div>
