<ng-container *ngIf="data.status === 'Active'; else noActive">
  <mat-card class="Active">
    <mat-card-header>
      <div class="left">
        <app-image
          [fileid]="data.bus.platform.icon"
          width="162"
          height="68"
        ></app-image>
      </div>

      <div class="space"></div>
      <div class="status">
        <app-bus-seat-status [status]="data.status"></app-bus-seat-status>
      </div>
    </mat-card-header>
    <div class="divider"></div>
    <mat-card-content>
      <mat-list role="list">
        <div class="listitem" role="listitem">
          <div>
            <span class="key">{{ "cardBusSeat.Account" | translate }}</span>:
          </div>
          
          <span class="value">
            <app-account
              [data]="data"
              [isAuto]="data.bus.delivery_method"
            ></app-account
          ></span>
        </div>
        <div
          class="listitem"
          role="listitem"
          *ngIf="data.bus.delivery_method === 'Auto'"
        >
          <div>
            <span class="key">{{ "cardBusSeat.Password" | translate }}</span>:
          </div>
          
          <span class="value"
            ><app-copyable-text
              [password]="true"
              [data]="data"
            ></app-copyable-text
          ></span>
        </div>
        <div class="listitem" role="listitem">
          <div>
            <span class="key">{{ "cardBusSeat.NO" | translate }}</span>: 
          </div>
          <div class="item-NO"
            (click)="checkCarpoolUsers()"
            *ngIf="data.bus.seat_count !== 1"
          >
            <span>Please use No.</span>
            <button class="no-value">
              {{ data.no | number }}
            </button>
            <span>Profile</span>
            
          </div>
          
          <div
          class="item-NO"
          *ngIf="data.bus.seat_count == 1"
          >
            <span>Please use No.</span>
            <button class="seat-count-1">
              {{ data.no | number }}
            </button>
            <span>Profile</span>
          </div>

          
        </div>
        <div class="listitem" role="listitem" style="margin-bottom: 5px">
          <div>
            <span class="key">{{ "cardBusSeat.ExpiryTime" | translate }}</span>:
          </div>
          
          <span class="value"
            ><app-datetime [text]="data.ended_time"></app-datetime
          ></span>
        </div>
        <div class="netflix" *ngIf="data.bus.platform.name === 'Netflix'">
          <div (click)="openNetflixCodeModal()" class="get-link-way">
            <img
              src="../../../../assets/images/subscription/Icon-step.svg"
              alt=""
            />
            <span style="cursor: pointer"
              >{{ "cardBusSeat.GetLinkWay" | translate }}
            </span>
          </div>

          <div class="no-link">
            <ng-container *ngIf="timeout === 91 && !link">
              <img
                src="../../../../assets/images/subscription/Icon-lianjie.svg"
                alt=""
              />
              <span
                style="text-decoration-line: underline; cursor: pointer"
                (click)="openTipModal()"
              >
                {{ "cardBusSeat.GetLink" | translate }}
              </span>
            </ng-container>
            <ng-container *ngIf="timeout !== 0 && timeout !== 91 && !link">
              <img
                src="../../../../assets/images/subscription/Icon-lianjie.svg"
                alt=""
              />
              <span
                style="
                  text-decoration-line: none;
                  cursor: default;
                "
              >
                {{ "cardBusSeat.Search" | translate }}{{ timeout
                }}{{ "cardBusSeat.Second" | translate }}
              </span>
            </ng-container>
            <ng-container *ngIf="timeout === 0 && !link">
              <img
                width="20"
                height="20"
                src="../../../../assets/images/subscription/Icon-refresh.svg"
                alt=""
              />
              <span
                style="text-decoration-line: underline; cursor: pointer"
                (click)="refresh()"
              >
                {{ "cardBusSeat.Refresh" | translate }}
              </span>
            </ng-container>
          </div>
          <div *ngIf="link" class="link">
            <span class="href-link">
              <img
                src="../../../../assets/images/subscription/Icon-lianjie.svg"
                alt=""
              />
              <span class="over">{{ link }}</span>
            </span>
            <span (click)="openLink()" class="link-button">
              {{ "cardBusSeat.OpenLink" | translate }}
            </span>
          </div>
        </div>

        <div class="warn">
          <span *ngIf="data.bus.platform.name === 'spotify'">
            ({{ "cardBusSeat.SpotifyTip" | translate }})
          </span>
        </div>
      </mat-list>
    </mat-card-content>
    <mat-card-actions>
      <div class="item" (click)="renew()">
        <div class = "renew">
          <img
          alt="img"
          height="17"
          width="17"
          src="../../../../assets/images/subscription/Icon-money.svg"
        />
        {{ "cardBusSeat.Renew" | translate }}
        </div>
      </div>
      <div class="item" (click)="groups()">
        <div class = "group">
          <img
          alt="img"
          height="17"
          width="17"
          src="../../../../assets/images/subscription/Icon-group.svg"
        />
        {{ "cardBusSeat.Groups" | translate }}
        </div>
      </div>
      <div class="item" [matMenuTriggerFor]="menu" (menuOpened)="onMenuOpened()" (menuClosed)="onMenuClosed()">
        <div class = "support" [ngClass]="{'menu-open': menuOpen}">
          <img
          alt="img"
          height="17"
          width="17"
          [src]="imgSrc"
        />
        {{ "cardBusSeat.Support" | translate }}
        </div>
      </div>
    </mat-card-actions>
  </mat-card>
</ng-container>
<ng-template #noActive>
  <mat-card class="noActive">
    <img
      src="../../../../assets/images/subscription/no-subscription.png"
      alt="no-data"
      height="220"
    />
    <div class="title">
      {{ "cardBusSeat.Your" | translate
      }}<span>{{ data.bus.platform.name }}</span
      >{{ "cardBusSeat.Prepared" | translate }}
    </div>
    <div class="time">
      {{ "cardBusSeat.ExpectedDeliveryTime" | translate }}:
      <span>{{ time }}</span>
    </div>
    <div class="tip">
      {{ "cardBusSeat.Tip" | translate }}
    </div>
    <app-button 
    *ngIf="!isSmall"
    (click)="chat()"
    height="60px"
    width="250px"
    >
      {{ "cardBusSeat.OnlineService" | translate }}
    </app-button>
    <app-button 
    *ngIf="isSmall"
    (click)="chat()"
    height="30px"
    width="160px"
    >
      {{ "cardBusSeat.OnlineService" | translate }}
    </app-button>
  </mat-card>
</ng-template>
<mat-menu #menu="matMenu">
  <a mat-menu-item href="" target="_blank">
    <span>{{ "cardBusSeat.Faqs" | translate }}</span>
  </a>
  <!-- <button mat-menu-item (click)="chat()">
    <span>{{ "cardBusSeat.OnlineService" | translate }}</span>
  </button> -->
  <button mat-menu-item (click)="newTicket()">
    <span>{{ "pageHelp.NewTicket" | translate }}</span>
  </button>
  <button mat-menu-item (click)="viewTicket()">
    <span>{{ "cardBusSeat.ViewTicket" | translate }}</span>
  </button>
</mat-menu>
