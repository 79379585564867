import { DialogRef } from '@angular/cdk/dialog';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-affiliate-form-successful-dialog',
  templateUrl: './affiliate-form-successful-dialog.component.html',
  styleUrls: ['./affiliate-form-successful-dialog.component.scss'],
})
export class AffiliateFormSuccessfulDialogComponent {
  count = 3;
  constructor(
    private dialogRef: DialogRef<AffiliateFormSuccessfulDialogComponent>,
    private router: Router
  ) {
    const interval = setInterval(() => {
      this.count--;
      if (this.count === 0) {
        clearInterval(interval);
        this.dialogRef.close();
        this.router.navigate(['/affiliate/dashboard']);
      }
    }, 1000);
  }
}
