import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RegionApiService } from 'src/app/service/region-api.service';
import { LanguageService } from 'src/app/service/store/language.service';
import { UserService } from 'src/app/service/store/user.service';
import { UserApiService } from 'src/app/service/user-api.service';
import { Languages } from 'src/app/utils/region';

@Component({
  selector: 'app-user-avatar-with-menu',
  templateUrl: './user-avatar-with-menu.component.html',
  styleUrls: ['./user-avatar-with-menu.component.scss'],
})
export class UserAvatarWithMenuComponent implements OnInit {
  self: any = null;
  languages = Languages;

  constructor(
    private api: UserApiService,
    private userService: UserService,
    private router: Router,
    private translate: TranslateService,
    private languageService: LanguageService,
    private apiRegion: RegionApiService
  ) {}

  ngOnInit(): void {
    this.getSelf();
    this.userService.getSelf();
    this.getIpRegion();
  }
  goto(url: string) {
    if (!this.self) {
      this.router.navigate(['/login']);
    } else {
      this.router.navigate([url]);
    }
  }
  async logout() {
    try {
      const r = await this.api.logout();
      this.router.navigate(['/'], { replaceUrl: true });
      this.userService.set(null);
      this.self = null;
      location.replace('/');
    } catch (error) {}
  }

  async getSelf() {
    try {
      const r = await this.api.getSelf();
      this.self = r.data;
      this.userService.set(this.self);
    } catch (error) {}
  }

  switchLanguage(lang: string) {
    this.languageService.set(lang);
    this.translate.use(lang);
  }

  region: any;
  async getIpRegion() {
    try {
      const r = await this.apiRegion.getIpRegion();
      this.region = r.data;
    } catch (error) {}
  }
}
