export const environment = {
  production: true,
  apiHost: '/api',
  paypalClientID:
    // 'ASZ1f8Q_xCBWNltM31xOtlLg2Gq0xytaHGDLK3anPpGiIitE78CorkGpsH9WMyt76FN3XImQC1SNYWFK',
    // 'AZf_fP1L6g8yxMbhhSmIItkrpyLyfccuepVdLjpSe90nljvClRmmBMkZBiKV0NN-IWmePW9Sy_QNXyf-',
    'ATXxHMRxsIpSnK4TFjR38oLwlY3GlCOVAp2O9mwN-CyzDgSjc6VIJcU56bn44LTGaQAIdKrZqXjW4t1g',
  // crispWebsiteID: '89141882-e055-4676-a30e-fd62e40f4e45',
  crispWebsiteID:'79c3f3e5-2a21-4025-af1c-6ea6f5198c68',
  googleOAuth2ClientID:
    '512404748384-saimik75teeu6scso1k21j3ho596gpt7.apps.googleusercontent.com',
    // '10846546863-04h6lgi6dqn2t1ikvss7f2u87kc5129b.apps.googleusercontent.com',
  googleReCaptchaSiteKey: '6LeJRBsjAAAAAJtsKBAhWAheB8VnzIhcKRRgHMuQ',
  timestamp: `{BUILD_VERSION}`,
  http: 'https://premlogin.com/',
  airwallexEnv: 'prod',
};
