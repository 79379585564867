import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component } from '@angular/core';

@Component({
  selector: 'app-user-reviews-panel',
  templateUrl: './user-reviews-panel.component.html',
  styleUrls: ['./user-reviews-panel.component.scss'],
})
export class UserReviewsPanelComponent {
  isSmall = false;
  constructor(private breakpointObserver: BreakpointObserver) {
    this.isSmall = this.breakpointObserver.isMatched([
      Breakpoints.XSmall,
      Breakpoints.Small,
    ]);
  }
}
