import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OrderApiService } from 'src/app/service/order-api.service';

@Component({
  selector: 'app-waiting-for-purchase-complete-modal',
  templateUrl: './waiting-for-purchase-complete-modal.component.html',
  styleUrls: ['./waiting-for-purchase-complete-modal.component.scss'],
})
export class WaitingForPurchaseCompleteModalComponent implements OnDestroy {
  interval: any;
  timeout = 60;
  constructor(
    private dialogRef: MatDialogRef<WaitingForPurchaseCompleteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private api: OrderApiService
  ) {
    this.interval = setInterval(() => {
      this.timeout -= 1;
      if (this.timeout <= 0) {
        this.close();
        return;
      }
      this.getUserOrderPaylog();
    }, 1500);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  close(r: boolean = false) {
    clearInterval(this.interval);
    this.dialogRef.close(r);
    if (this.router.url.startsWith('/subscription')) {
      location.reload();
    } else {
      this.router.navigate(['/subscription']);
    }
  }

  async getUserOrderPaylog() {
    try {
      const r = await this.api.getPaypalOrder(this.data.id);
      if (r.data) {
        this.close(true);
        return;
      }
    } catch (error) {}
  }
}
