<head>
  var value =
  {{
    data.purchase_units[0].amount.value
  }}; var currency =
  {{
    data.purchase_units[0].amount.currency_code
  }}; var transaction_id =
  {{
    data.purchase_units[0].reference_id
  }};
  <!-- Event snippet for 购买 conversion page -->
  <script>
    gtag("event", "conversion", {
      send_to: "AW-11426088332/gcSJCM7ezPoYEIyLscgq",
      value: value,
      currency: currency,
      transaction_id: transaction_id,
    });
  </script>
</head>

<mat-dialog-content class="mat-typography">
  <h2 class="mat-headline">
    {{ "modalWaitingForPurchase.Waiting" | translate }}
  </h2>
  <h3 class="mat-subheading-1">
    {{ "modalWaitingForPurchase.If" | translate }}
  </h3>
  <div class="lord">
    <lord-icon
      trigger="loop"
      src="../../../../assets/lottie/loading.json"
    ></lord-icon>
  </div>
</mat-dialog-content>
