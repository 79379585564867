import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'src/app/service/message.service';
import { WorkorderApiService } from 'src/app/service/workorder-api.service';
import { validateFormGroup } from 'src/app/utils/form';

@Component({
  selector: 'app-help-ticket-page',
  templateUrl: './help-ticket-page.component.html',
  styleUrls: ['./help-ticket-page.component.scss'],
})
export class HelpTicketPageComponent implements OnInit {
  formGroup: UntypedFormGroup;
  textMessage: any;
  localStorageLanguage = '';

  ticketID = 0;
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private api: WorkorderApiService,
    private router: Router,
    private message: MessageService,
    private translateService: TranslateService
  ) {
    this.formGroup = this.fb.group({
      detail: ['', [Validators.required]],
    });
    this.translateService.onLangChange.subscribe((text: any) => {
      this.translateService.get('textMessage').subscribe((text: string) => {
        this.textMessage = text;
      });
    });
    this.localStorageLanguage = localStorage.getItem('language') || '';
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.ticketID = params['id'];
    });
    if (this.ticketID !== 0) this.getTicket();

    this.translateService.get('textMessage').subscribe((text: string) => {
      this.textMessage = text;
    });
  }

  buttonLoading = false;
  async close() {
    try {
      this.buttonLoading = true;
      const r = await this.api.closeTicket(this.ticketID);
      if (r.status === 0) {
        this.message.info(this.textMessage.CloseSuccessful);
        this.router.navigate(['/help']);
      } else {
        this.message.warn(this.textMessage.Unknown);
      }
    } catch (error) {
    } finally {
      this.buttonLoading = false;
    }
  }

  async reply() {
    if (!validateFormGroup(this.formGroup)) {
      return;
    }
    const value = this.formGroup.value;
    try {
      this.buttonLoading = true;
      const r = await this.api.postUserreply(this.ticketID, value.detail);
      if (r.status === 0) {
        this.message.info(this.textMessage.MessageSuccessful);
        this.formGroup.patchValue({ detail: '' });
        this.getTicket();
      } else {
        this.message.warn(this.textMessage.Unknown);
      }
    } catch (error) {
    } finally {
      this.buttonLoading = false;
    }
  }

  loading = false;
  logs: any[] = [];
  async getTicket() {
    try {
      this.loading = true;
      const r = await this.api.getTicket(this.ticketID);
      this.logs = r.data || [];
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }
}
