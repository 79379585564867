import { Component, OnInit, Inject, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'src/app/service/message.service';
import { UserApiService } from 'src/app/service/user-api.service';
import { validateFormGroup } from 'src/app/utils/form';
import { AfterEnterYoutubeModalComponent } from '../after-enter-youtube-modal/after-enter-youtube-modal.component';

@Component({
  selector: 'app-enter-invitation-link-dialog',
  templateUrl: './enter-invitation-link-dialog.component.html',
  styleUrls: ['./enter-invitation-link-dialog.component.scss'],
})
export class EnterInvitationLinkDialogComponent implements OnInit {
  formGroup: UntypedFormGroup;
  error = false;
  textMessage: any;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<EnterInvitationLinkDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private api: UserApiService,
    private message: MessageService,
    private translateService: TranslateService
  ) {
    this.formGroup = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });

    this.formGroup.valueChanges.subscribe((newValues) => {
      this.error = false;
    });
  }

  ngOnInit(): void {
    this.translateService.get('textMessage').subscribe((text: string) => {
      this.textMessage = text;
    });
  }

  loading = false;
  async send() {
    if (!validateFormGroup(this.formGroup)) {
      this.error = true;
      return;
    }
    try {
      this.loading = true;
      const email = this.formGroup.value.email;
      const id = this.data.id;
      const r = await this.api.postInvitationLink(id, email);
      if (r.status === 0) {
        this.dialogRef.close();
        this.dialog
          .open(AfterEnterYoutubeModalComponent, {
            width: '600px',
            panelClass: 'order-dialog-container', // 添加自定义样式类
          })
          .afterClosed()
          .subscribe((r) => {
            location.reload();
          });
      } else {
        this.message.warn(this.textMessage.Unknown);
      }
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }
}
