import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-duration-price',
  templateUrl: './duration-price.component.html',
  styleUrls: ['./duration-price.component.scss'],
})
export class DurationPriceComponent {
  @Input() price: any;
  @Input() duration = '';
}
