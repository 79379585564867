import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-money',
  templateUrl: './money.component.html',
  styleUrls: ['./money.component.scss'],
})
export class MoneyComponent {
  @Input() price: any;
  @Input() color = '#ffffff';
  @Input() fontSize = '26px';
  @Input() fontWeight = '600px';

  modalPlatform: any;

  constructor(private translateService: TranslateService) {
    this.translateService.onLangChange.subscribe((text: any) => {
      this.translateService.get('modalPlatform').subscribe((text: string) => {
        this.modalPlatform = text;
      });
    });
  }

  ngOnInit(): void {
    this.translateService.get('modalPlatform').subscribe((text: string) => {
      this.modalPlatform = text;
    });
  }

  fuhao() {
    const m: any = {
      usd: '$',
      eur: '€',
    };
    return m[this.price?.currency] || this.modalPlatform.Unknown;
  }
}
