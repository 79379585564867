<div class="close">
  <button mat-dialog-close>
    <img
      alt="close"
      width="20"
      height="20"
      src="../../../../assets/images/base/Icon-del-default.svg"
    />
  </button>
</div>
<div class="title">{{ "pageAffiliate.UserOrderDetails" | translate }}</div>
<div class="content" mat-dialog-content>
  <table>
    <tr>
      <th>{{ "pageAffiliate.Product" | translate }}</th>
      <th>{{ "pageAffiliate.User" | translate }}</th>
      <th>{{ "pageAffiliate.PurchaseTime" | translate }}</th>
      <th>{{ "pageAffiliate.OfferAmount" | translate }}</th>
      <th>{{ "pageAffiliate.OrderAmount" | translate }}</th>
      <th>{{ "pageAffiliate.Commission" | translate }}</th>
      <th>{{ "pageAffiliate.CommissionStatus" | translate }}</th>
    </tr>
    <tr *ngFor="let data of orders">
      <td>
        <app-image
          [fileid]="data.bus_type_snapshot.platform.icon"
          width="40"
          height="17"
        ></app-image>
      </td>
      <td>{{ data.user.email }}</td>
      <td><app-datetime [text]="data?.paid_time"></app-datetime></td>
      <td>{{ data?.discount_price }} {{ data?.currency | uppercase }}</td>
      <td>{{ data?.pay_price | number }} {{ data?.currency | uppercase }}</td>
      <td>{{ data.commission }} {{ data?.currency | uppercase }}</td>
      <td>
        <app-commission-status
          [status]="data.commission_status"
        ></app-commission-status>
      </td>
    </tr>
  </table>
</div>
