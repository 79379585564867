<mat-card>
  <img
    src="../../../assets/images/contact-us/Icon-send-successful.png"
    alt="right"
    
  />
  <div class="title">{{ "pageContactUs.ThankYou" | translate }}</div>
  <div class="content">
    {{ "pageContactUs.Content" | translate }}
  </div>
  <div class="buttons">
    <app-button 
      type="2" 
      routerLink="/affiliate"
      height="60px"
      width="300px"
    >
      {{ "pageContactUs.Button1" | translate }}
    </app-button>
    <app-button 
      type="1"  
      routerLink="/"
      height="60px"
      width="300px"
    >
      {{ "pageContactUs.Button2" | translate }}
    </app-button>
  </div>
</mat-card>
