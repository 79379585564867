import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component } from '@angular/core';

@Component({
  selector: 'app-join-group-modal',
  templateUrl: './join-group-modal.component.html',
  styleUrls: ['./join-group-modal.component.scss'],
})
export class JoinGroupModalComponent {
  isSmall = false;
  constructor(private breakpointObserver: BreakpointObserver) {
    this.isSmall = this.breakpointObserver.isMatched([Breakpoints.XSmall]);
  }
}
