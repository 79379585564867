import {
  Component,
  ElementRef,
  ViewChild,
  HostListener,
  OnInit,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormBuilder } from '@angular/forms';
import { AbstractNgModelComponent } from '../../base/abstract-ng-model/abstract-ng-model.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { DealerApiService } from 'src/app/service/dealer-api.service';

@Component({
  selector: 'app-contact-information-input',
  templateUrl: './contact-information-input.component.html',
  styleUrls: ['./contact-information-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ContactInformationInputComponent,
    },
  ],
})
export class ContactInformationInputComponent
  extends AbstractNgModelComponent
  implements OnInit
{
  @ViewChild(MatMenuTrigger, { static: true, read: ElementRef })
  userMenu!: ElementRef<HTMLElement>;

  get userMenuData() {
    return {
      menuWidth: this.userMenu.nativeElement.clientWidth,
    };
  }

  value: any;

  isDropdownOpen = false;
  src = '../../../../assets/images/withdraw/Icon-down-arrow-min.svg';

  constructor(
    private elementRef: ElementRef,
    private fb: UntypedFormBuilder,
    private api: DealerApiService
  ) {
    super();
  }

  ngOnInit(): void {
    this.findContacts();
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
    if (this.isDropdownOpen) {
      this.src = '../../../../assets/images/withdraw/Icon-up-arrow-min.svg';
    } else {
      this.src = '../../../../assets/images/withdraw/Icon-down-arrow-min.svg';
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
      this.src = '../../../../assets/images/withdraw/Icon-down-arrow-min.svg';
    }
  }

  override onChange(): void {
    super.onChange();
  }

  contact = '';
  selectOption(option: any) {
    this.isDropdownOpen = false;
    this.src = '../../../../assets/images/withdraw/Icon-down-arrow-min.svg';
    this.contact = option;
    this.value = this.contacts.find(
      (item: { name: string }) => item.name === option
    ).id;
    this.onChange();
  }

  contacts: any = [];
  async findContacts() {
    try {
      const r = await this.api.findContacts();
      this.contacts = r.data.list || [];
    } catch (error) {
    } finally {
      for (let data of this.contacts) {
        if (data.id === this.value) {
          this.contact = data.name;
          return;
        }
      }
    }
  }
}
