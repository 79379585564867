import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input-gg';
import { AffiliateFormSuccessfulDialogComponent } from 'src/app/components/affiliate/affiliate-form-successful-dialog/affiliate-form-successful-dialog.component';
import { DealerApiService } from 'src/app/service/dealer-api.service';
import { MessageService } from 'src/app/service/message.service';
import { validateFormGroup } from 'src/app/utils/form';

@Component({
  selector: 'app-affiliate-form',
  templateUrl: './affiliate-form.component.html',
  styleUrls: ['./affiliate-form.component.scss'],
})
export class AffiliateFormComponent implements OnInit {
  formGroup: UntypedFormGroup;
  errorText = '';
  textError: any;
  textMessage: any;

  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private api: DealerApiService,
    private dialog: MatDialog,
    private message: MessageService,
    private translateService: TranslateService
  ) {
    this.formGroup = this.fb.group({
      name: ['', [Validators.required]],
      phone: [null, [Validators.required]],
      currency: ['usd', [Validators.required]],
      platform: [null, [Validators.required]],
      language: ['en', [Validators.required]],
      application: [1, [Validators.required]],
      tell: ['', [Validators.required]],
      payment: [],
      invitee: [''],
    });

    this.formGroup.valueChanges.subscribe(() => {
      this.error = false;
    });

    this.translateService.onLangChange.subscribe((text: any) => {
      this.translateService.get('textMessage').subscribe((text: string) => {
        this.textMessage = text;
      });
    });
  }

  ngOnInit(): void {
    this.translateService.get('textMessage').subscribe((text: string) => {
      this.textMessage = text;
    });
  }

  loading = false;

  error = false;
  send() {
    const name = this.formGroup.value['name'];
    const whats_app = this.formGroup.value['phone']?.dialCode;
    const phone = this.formGroup.value['phone']?.number;
    const currency = this.formGroup.value['currency'];
    const language = this.formGroup.value['language'];
    const code = this.formGroup.value['invitee'];
    const promotion = this.formGroup.value['platform'];
    const payment_method_id = this.formGroup.value['payment']?.map(
      (str: string) => parseInt(str, 10)
    );
    const contact_id = Number(this.formGroup.value['application']);
    const context = this.formGroup.value['tell'];

    if (
      name &&
      whats_app &&
      phone &&
      currency &&
      language &&
      promotion &&
      contact_id &&
      context
    ) {
      this.sendDealerInfo(
        name,
        whats_app,
        phone,
        currency,
        language,
        code,
        promotion,
        payment_method_id,
        contact_id,
        context
      );
    } else {
      this.error = true;
    }
  }

  currency = true;
  chooseCurrency() {
    this.currency = false;
  }

  async sendDealerInfo(
    name: string,
    whats_app: string,
    phone: string,
    currency: string,
    language: string,
    code: string,
    promotion: any,
    payment_method_id: any,
    contact_id: number,
    context: string
  ) {
    try {
      const r = await this.api.sendDealerInfo(
        name,
        whats_app,
        phone,
        currency,
        language,
        code,
        promotion,
        payment_method_id,
        contact_id,
        context
      );
      if (r.status === 0) {
        this.dialog.open(AffiliateFormSuccessfulDialogComponent);
      } else {
        this.message.warn(this.textMessage.Failure);
      }
    } catch (error) {}
  }
}
