<div class="container">
  <div class="content">
    <app-spinner [loading]="loading">
      <mat-card>
        <mat-card-header>{{
          "pageUpdateEmail.ChangeEmailAddress" | translate
        }}</mat-card-header>
        <mat-card-content>
          <form [formGroup]="formGroup">
            <div class="info-row">
              <app-no-border-input
                [label]="'inputLabel.Email' | translate"
                [placeholder]="'placeholder.EmailAddress' | translate"
                formControlName="email"
              ></app-no-border-input>
            </div>
            <div class="space space1"></div>
            <div class="info-row">
              <app-no-border-input
                [label]="'inputLabel.VerificationCode' | translate"
                [placeholder]="'placeholder.VerificationCode' | translate"
                formControlName="code"
                (keydown.enter)="$event.preventDefault(); submit()"
              ></app-no-border-input>
              <div>
                <app-send-update-code-button
                  [email]="formGroup.value['email']"
                  height="100%"
                  text="SEND"
                  width="100%"
                  style="margin-top: auto;"
                ></app-send-update-code-button>
              </div>
            </div>
            <div class="space"></div>
          </form>
        </mat-card-content>
        <mat-card-footer>
          <div>
            <app-error *ngIf="error?.code">{{
              "textError.RequireCode" | translate
            }}</app-error>
            <app-error
              *ngIf="
                error?.email && formGroup.controls['email'].hasError('required')
              "
              >{{ "textError.RequiredEmail" | translate }}</app-error
            >
            <app-error
              *ngIf="
                error?.email && formGroup.controls['email'].hasError('email')
              "
              >{{ "textError.EnterEmail" | translate }}</app-error
            >
          </div>
          <app-button
            color="#641F6C"
            fontColor="#ffffff"
            width="100%"
            height="48px"
            [loading]="loading"
            (click)="submit()"
            >{{ "button.Submit" | translate }}</app-button
          >
        </mat-card-footer>
      </mat-card>
    </app-spinner>
  </div>
</div>
