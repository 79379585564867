import { Component, OnInit, Inject } from '@angular/core';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'src/app/service/message.service';
import { UserApiService } from 'src/app/service/user-api.service';

@Component({
  selector: 'app-update-user-avatar-bottom-sheet',
  templateUrl: './update-user-avatar-bottom-sheet.component.html',
  styleUrls: ['./update-user-avatar-bottom-sheet.component.scss'],
})
export class UpdateUserAvatarBottomSheetComponent implements OnInit {
  avatars = [];
  textMessage: any;

  selected = '';

  constructor(
    private api: UserApiService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private message: MessageService,
    private bottomSheetRef: MatBottomSheetRef<UpdateUserAvatarBottomSheetComponent>,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.translateService.get('textMessage').subscribe((text: string) => {
      this.textMessage = text;
    });
    this.findUserAvatars();
    this.selected = this.data?.avatar;
  }

  close(r: any) {
    this.bottomSheetRef.dismiss(r);
  }
  select(fileid: string) {
    this.selected = fileid;
  }

  async submit() {
    if (!this.selected) {
      return;
    }
    try {
      this.loading = true;
      const r = await this.api.updateUserAvatar(this.selected);
      if (r.status !== 0) {
        this.message.warn(this.textMessage.Unknown);
      } else {
        this.message.info(this.textMessage.AvatarUpdated);
        this.close(r.data);
      }
    } catch (error) {
      this.message.error(this.textMessage.NetworkError);
    } finally {
      this.loading = false;
    }
  }

  loading = false;
  async findUserAvatars() {
    try {
      this.loading = true;
      const r = await this.api.findUserAvatars();
      this.avatars = r.data;
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }
}
