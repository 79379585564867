import { Component, OnInit } from '@angular/core'
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { Router } from '@angular/router';
import { UserApiService } from 'src/app/service/user-api.service';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
@Component({
    selector: 'app-be-affiliate-card',
    templateUrl: './be-affiliate-card.component.html',
    styleUrls: ['./be-affiliate-card.component.scss'],
})
export class BeAffiliateCardComponent implements OnInit {
    self: any = null;
    isSmall = false;
    constructor(
      private breakpointObserver: BreakpointObserver,
      private router: Router,
      private api: UserApiService,
      private dialog: MatDialog,
      private dialogRef: MatDialogRef<BeAffiliateCardComponent>
    ){
      this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
      .subscribe(result => {
        this.isSmall = result.matches; // 如果匹配手机模式，则为 true
      });

    }

    ngOnInit(): void{
      this.getSelf();
    }


    async getSelf() {
      try {
        const r = await this.api.getSelf();
        this.self = r.data;
      } catch (error) {}
    }

    goto() {
      this.dialogRef.close()
      if (!this.self) {
        this.router.navigate(['/login']);
      } else {
        if (this.self.affiliate.is_partner) {
          this.router.navigate(['/affiliate/dashboard']);
        } else this.router.navigate(['/affiliate/form']);
      }
    }
}