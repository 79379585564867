<div class="close">
  <button mat-dialog-close>
    <img
      alt="del"
      width="24"
      height="24"
      src="../../../../assets/images/base/Icon-del-default.svg"
    />
  </button>
</div>
<h1>{{ "modalSelectPayment.SelectPayment" | translate }}</h1>
<div class="top-margin"></div>
<div class="top-divider"></div>

<!-- 等支付接口对接好再上线 -->
<!-- <mat-dialog-content class="mat-content">
  <app-spinner [loading]="loading">
    <div class="tip">{{ "modalSelectPayment.Tip" | translate }}</div>
    <div class="payments">
      <div class="payment" style="width: 100%">
        <div class="title">{{ "modalSelectPayment.Method" | translate }} 1</div>
        <mat-radio-group
          [(ngModel)]="methodSelected.method"
          (ngModelChange)="setMethodSelected('airwallex')"
          aria-labelledby="example-radio-group-label"
          class="content"
        >
          <mat-radio-button class="example-margin" value="airwallex">
          </mat-radio-button>
          <div class="method">
            <ng-container *ngFor="let data of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]">
              <img
                src="../../../../assets/images/airwallex/{{ data + 1 }}.png"
                style="margin-right: 10px"
              />
            </ng-container>
          </div>

        </mat-radio-group>
        <div class="rate">+0.0% <span>+5.0%</span></div>
      </div>
      <div *ngFor="let p of payments; index as i" class="payment">
        <div class="title">
          {{ "modalSelectPayment.Method" | translate }} {{ i + 2 }}
        </div>
        <mat-radio-group
          [(ngModel)]="methodSelected.submethod"
          (ngModelChange)="setMethodSelected('Payssion')"
          aria-labelledby="example-radio-group-label"
          class="content"
        >
          <mat-radio-button
            class="example-margin"
            [value]="p.code"
          ></mat-radio-button>
          <div class="method">
            <img src="../../../../assets/images/payssion/{{ p.code }}.png" />
          </div>
        </mat-radio-group>
        <div class="rate">+0.0% <span>+5.0%</span></div>
      </div>
    </div>
    <div class="button">
      <app-button (click)="pay(methodSelected.method, methodSelected.submethod)"
      background="#612069"
      width="100%"
      height="50px"
      fontColor="#ffffff">
        {{ "button.CheckOut" | translate }}
      </app-button>
    </div>
  </app-spinner>
</mat-dialog-content> -->
<mat-dialog-content class="mat-content">
  <ngx-paypal [config]="payPalConfig"></ngx-paypal>
</mat-dialog-content>