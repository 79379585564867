import { Component, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-abstract-ng-model',
  templateUrl: './abstract-ng-model.component.html',
  styleUrls: ['./abstract-ng-model.component.scss'],
})
export abstract class AbstractNgModelComponent<T = any>
  implements ControlValueAccessor
{
  disabled: boolean = false;

  abstract value: T;

  onChange() {
    this.value = this.value;
    this.onFormChange(this.value);
  }

  writeValue(v: any) {
    this.value = v;
  }

  onFormChange = (v: any) => {};

  onFormTouched = () => {};

  registerOnChange(onChange: any) {
    this.onFormChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onFormTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
