import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-terms-condition-page',
  templateUrl: './terms-condition-page.component.html',
  styleUrls: ['./terms-condition-page.component.scss'],
})
export class TermsConditionPageComponent implements OnInit {
  isSmall: any;
  constructor(
    private title: Title,
    private meta: Meta,
    private breakpointObserver: BreakpointObserver
  ) {
    this.isSmall = this.breakpointObserver.isMatched([
      Breakpoints.XSmall,
      Breakpoints.Small,
    ]);
    if (this.isSmall) {
      this.title.setTitle('PremLogin');
    } else {
      this.title.setTitle('Terms & Conditions - PremLogin');
    }

    this.meta.updateTag({
      name: 'description',
      content: 'PremLogin Terms & Conditions of use',
    });
  }

  ngOnInit(): void {}
}
