<div class="container">
  <div class="header">
    <div class="title">{{"pageAboutUs.Title-1" | translate}}  <span> {{"pageAboutUs.Title-2" | translate}}</span></div>
    <div class="subtitle">{{ "pageAboutUs.Subtitle" | translate }}</div>
  </div>
  <div class="content">
    <div class="title">{{ "pageAboutUs.Content1-Title1" |translate }} <span>{{ "pageAboutUs.Content1-Title2" |translate }}</span></div>
    <app-timeline></app-timeline>
  </div>
  <div class="content">
    <div class="title">{{ "pageAboutUs.Content2-Title2" |translate }} <span>{{ "pageAboutUs.Content2-Title2" |translate }}</span></div>
    <app-team-carousel></app-team-carousel>
  </div>
  <div class="content">
    <div class="title">{{ "pageAboutUs.Content3-Title1" |translate }} <span>{{ "pageAboutUs.Content3-Title2" |translate }}</span></div>
    <div class="block"></div>
    <app-partner-panel></app-partner-panel>
  </div>
  <div class="content">
    <div class="title contact-title">{{ "pageAboutUs.Content4-Title1" |translate }} <span>{{ "pageAboutUs.Content4-Title2" |translate }}</span></div>
    <app-contact-us-panel></app-contact-us-panel>
  </div>

  
  

</div>
