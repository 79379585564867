import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { PromoCodeModalComponent } from '../promo-code-modal/promo-code-modal.component';
import { UserService } from 'src/app/service/store/user.service';
import { Router } from '@angular/router';
import { PaymentSelectModalComponent } from '../payment-select-modal/payment-select-modal.component';
import { WaitingForPurchaseCompleteModalComponent } from '../waiting-for-purchase-complete-modal/waiting-for-purchase-complete-modal.component';
import { StepsModalComponent } from '../steps-modal/steps-modal.component';

import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'src/app/service/message.service';
import { OrderApiService } from 'src/app/service/order-api.service';
import { validateFormGroup } from 'src/app/utils/form';

@Component({
  selector: 'app-platform-dialog',
  templateUrl: './platform-dialog.component.html',
  styleUrls: ['./platform-dialog.component.scss'],
})
export class PlatformDialogComponent implements OnInit {
  oldMoney = 0;
  promo: any;
  selectedBusType: any = null;

  // 下面是promo方法
  formGroup: UntypedFormGroup;
  error = false;
  textMessage: any;
  errorText = '';

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    private dialogRef: MatDialogRef<PlatformDialogComponent>,
    private router: Router,


    // 下面是promo组件方法
    private api: OrderApiService,
    private fb: UntypedFormBuilder,
    private message: MessageService,
    private translateService: TranslateService
  ) {
    
    // 下面是promo组件方法
    this.formGroup = this.fb.group({
      code: ['', [Validators.required]],
    });
  
    this.formGroup.valueChanges.subscribe(() => {
      this.errorText = '';
    });


  }

  // 获取月份图片
  getImageSrc(i: number): string {
    switch(i) {
      case 0:
        return '../../../../assets/images/index/month-1.svg';
      case 1:
        return '../../../../assets/images/index/month-2.svg';
      case 2:
        return '../../../../assets/images/index/month-3.svg';
      case 3:
        return '../../../../assets/images/index/month-4.svg';
      default:
        return '../../../../assets/images/index/month-1.svg';
    }
  }

  ngOnInit(): void {
    if (this.data?.bus_types?.length > 0) {
      this.selectedBusType = this.data.bus_types[0];
    }
    this.oldMoney = this.data?.price.value;

    // 下面是promo方法
    this.translateService.get('textMessage').subscribe((text: string) => {
      this.textMessage = text;
    });
  }

  enter() {
    const price = this.exclusive
      ? this.selectedBusType.exclusive_price.value
      : this.selectedBusType.price.value;
    this.dialog
      .open(PromoCodeModalComponent, {
        data: this.selectedBusType,
        width: '694px',
      })
      .afterClosed()
      .subscribe((r) => {
        if (r) {
          this.discountPrice = parseFloat(((1 - r.value) * price).toFixed(2));
          this.promo = r;
        } else {
          this.discountPrice = 0;
          this.promo = null;
        }
      });
  }
  discountPrice = 0;

  renderDiscountPrice() {
    const currency = this.selectedBusType?.price.currency === 'usd' ? '$' : '€';
    return ' - ' + currency + this.discountPrice;
  }

  pay() {
    if (!this.selectedBusType) {
      return;
    }
    if (!this.userService.self()) {
      this.dialogRef.close();
      this.router.navigate(['/login']);
      return;
    }
    if (
      this.data.delivery_method === 'Manual-Account' ||
      this.data.delivery_method === 'Manual-Account/Password'
    ) {
      //暂时还不需要这个功能
    } else {
      this.openPaymentSelect();
    }
  }

  openPaymentSelect(account: string = '', password: string = '') {
    this.dialog
      .open(PaymentSelectModalComponent, {
        data: {
          busSeatID: 0,
          busTypeID: this.selectedBusType.id,
          exclusive: this.exclusive,
          promoCode: this.promo?.code || '',
          currency: this.data.price.currency,
        },
        width: '800px',
        panelClass: 'order-dialog-container', // 添加自定义样式类
      })
      .afterClosed()
      .subscribe((r) => {
        if (r) {
          this.dialogRef.close(r);
          this.dialog.open(WaitingForPurchaseCompleteModalComponent, {
            disableClose: true,
            data: r,
          });
        }
      });
  }

  exclusive = false;

  selectBusType(data: any) {
    this.selectedBusType = data;
    console.log(this.selectedBusType)
    if (!this.selectedBusType.allow_exclusive) {
      this.exclusive = false;
    }
  }

  openSteps() {
    this.dialog.open(StepsModalComponent, {
      width: '717px',
    });
  }


  // 下面是用promo组件的方法


  apply() {
    if (!validateFormGroup(this.formGroup)) {
      // this.dialogRef.close('');
      return;
    }
    this.findPromoCode();
  }

  loading = false;
  async findPromoCode() {
    const code = this.formGroup.value.code;
    let busTypeId = this.data.bus_types[0].id;
    busTypeId = this.selectedBusType.id
    console.log(this.data.bus_types[0])
    try {
      this.loading = true;
      const r = await this.api.findPromoCode(code, busTypeId, 0);
      if (r.status === 1203) {
        this.errorText = this.textMessage.CodeExist;
      } else if (r.status === 1204) {
        this.errorText = this.textMessage.CodeExpired;
      } else if (r.status === 1205) {
        this.errorText = this.textMessage.CodeValid;
      } else if (r.status === 1206) {
        this.errorText = this.textMessage.CodeUsageLimit;
      } else if (r.status === 1207) {
        this.errorText = this.textMessage.CodeNotApplicable;
      } else if (r.status === 1208) {
        this.errorText = this.textMessage.CodeUsed;
      } else if (r.status === 1209) {
        this.errorText = this.textMessage.FirstOrder;
      } else if (r.status === 1210) {
        this.errorText = this.textMessage.FirstProduct;
      } else if (r.status === 1211) {
        this.errorText = this.textMessage.RenewalAvailable;
      } else if (r.status === 1212) {
        this.errorText = this.textMessage.NonRenewal;
      } else if (r.status !== 0) {
        this.message.warn(this.textMessage.Unknown);
      } else {

        // 这里是打折的逻辑，取消关闭窗口
        // this.dialogRef.close(r.data);
        const price = this.exclusive
        ? this.selectedBusType.exclusive_price.value
        : this.selectedBusType.price.value;
        if (r) {
          this.discountPrice = parseFloat(((1 - r.data.value) * price).toFixed(2));
          this.promo = r.data;
          console.log(this.discountPrice)
        } else {
          this.discountPrice = 0;
          this.promo = null;
        }
      }
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }

}
