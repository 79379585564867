import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Crisp } from 'crisp-sdk-web';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { lastValueFrom } from 'rxjs';
import { FileApiService } from 'src/app/service/file-api.service';
import { MessageService } from 'src/app/service/message.service';
import { LoadingDataService } from 'src/app/service/store/loading-data.service';
import { UserService } from 'src/app/service/store/user.service';
import { UserApiService } from 'src/app/service/user-api.service';

@Component({
  selector: 'app-login-with-password-card',
  templateUrl: './login-with-password-card.component.html',
  styleUrls: ['./login-with-password-card.component.scss'],
})
export class LoginWithPasswordCardComponent implements OnInit {
  formGroup: UntypedFormGroup;
  tip1 = false;
  tip2 = false;
  email = '';
  password = '';
  error = false;
  textMessage: any;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private api: UserApiService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private message: MessageService,
    private router: Router,
    private fileapi: FileApiService,
    private userService: UserService,
    private translateService: TranslateService,
    private loadingDataService: LoadingDataService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.email = params['email'];
    });

    this.formGroup = this.fb.group({
      email: [this.email, [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });

    this.formGroup.valueChanges.subscribe((newValues) => {
      this.error = false;
    });

    this.translateService.onLangChange.subscribe((text: any) => {
      this.translateService.get('textMessage').subscribe((text: string) => {
        this.textMessage = text;
      });
    });
  }

  ngOnInit(): void {
    this.translateService.get('textMessage').subscribe((text: string) => {
      this.textMessage = text;
    });
  }

  checkError() {
    const errors = this.formGroup.controls['password'].errors;
    if (errors && errors['required']) {
      this.error = true;
    }
  }

  loading = false;
  login() {
    this.checkError();
    if (!this.error) this.loginWithPassword();
  }

  async loginWithPassword() {
    const token = await lastValueFrom(
      this.recaptchaV3Service.execute('checkEmail')
    );
    if (!token) {
      this.message.error('recaptcha failed');
      return;
    }

    this.password = this.formGroup.value['password'];
    this.email = this.formGroup.value['email'];
    try {
      this.loading = true;
      this.loadingDataService.set(this.loading);
      const r = await this.api.loginWithPassword(
        this.email,
        this.password,
        token
      );
      if (r.status === 999) {
        this.message.warn(this.textMessage.AccountBanned);
      } else if (r.status === 1105) {
        this.message.warn(this.textMessage.PasswordError);
      } else if (r.status === 1106) {
        this.message.warn(this.textMessage.RobotError);
      } else if (r.status !== 0) {
        this.message.warn(this.textMessage.Unknown);
      } else {
        this.afterLogin(r.data);
      }
    } catch (error) {
      this.message.error(this.textMessage.NetworkError);
    } finally {
      this.loading = false;
      this.loadingDataService.set(this.loading);
    }
  }

  afterLogin(data: any) {
    this.router.navigate(['/']);
    this.userService.set(data);
    try {
      Crisp.user.setEmail(data.email);
      Crisp.user.setNickname(data.nickname);
      Crisp.user.setAvatar(this.fileapi.fileurl(data.avatar));
    } catch {}
  }

  changeLogin() {
    this.router.navigate(['/login/verification'], {
      queryParams: { email: this.email },
    });
  }
}
