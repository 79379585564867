<div class="finished" *ngIf="status === 'Finished'">
    <img src="../../../../assets/images/orders-list/finished.png" width="10px">
    <span class="{{ status }}" [ngStyle]="{ color: color }">{{ text() }} </span>
</div>
<div class="paid" *ngIf="status === 'Paid'">
    <img src="../../../../assets/images/orders-list/paid.png" width="10px">
    <span class="{{ status }}" [ngStyle]="{ color: color }">{{ text() }} </span>
</div>
<div class="refunded" *ngIf="status === 'Refunded'">
    <img src="../../../../assets/images/orders-list/refunded.png" width="10px">
    <span class="{{ status }}" [ngStyle]="{ color: color }">{{ text() }} </span>
</div>
<div class="refunded" *ngIf="status !== 'Refunded' && status !== 'Finished' && status !== 'Paid'">
    <img src="../../../../assets/images/orders-list/refunded.png" width="10px">
    <span class="{{ status }}" [ngStyle]="{ color: color }">{{ text() }} </span>
</div>

