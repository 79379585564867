<div class="content" (click)="goto()">
  <div class="item1">
    <app-image
      [fileid]="data.order.bus_type_snapshot.platform.icon"
    ></app-image>
    <span>{{ data.order?.bus?.bus_account.username }}</span>
  </div>
  <div class="divide"></div>
  <div class="item2" *ngIf="localStorageLanguage === 'ar'; else Else">
    <!-- <mat-icon style="margin-right: 35px"> keyboard_arrow_left </mat-icon> -->
    <span
      class="old"
      *ngIf="data.status === 'ReplyReceived'"
      style="text-align: end"
      >{{ "pageHelp.TicketStatus1" | translate }}</span
    >
    <span
      class="new"
      *ngIf="data.status === 'NoResponseReceived'"
      style="text-align: end"
      >{{ "pageHelp.TicketStatus2" | translate }}</span
    >
    <span
      class="close"
      *ngIf="
        data.status === 'ProactiveCompletion' ||
        data.status === 'PassiveCompletion'
      "
      style="text-align: end"
      >{{ "pageHelp.TicketStatus3" | translate }}</span
    >
    <img width="36px" src="../../../../assets/images/help/Icon-ticket-right.png">
  </div>
  <div class="divide"></div>
  <ng-template #Else>
    <div class="item2">
      <span
        class="old"
        *ngIf="data.status === 'ReplyReceived'"
        style="text-align: start"
        >{{ "pageHelp.TicketStatus1" | translate }}</span
      >
      <span
        class="new"
        *ngIf="data.status === 'NoResponseReceived'"
        style="text-align: start"
        >{{ "pageHelp.TicketStatus2" | translate }}</span
      >
      <span
        class="close"
        *ngIf="
          data.status === 'ProactiveCompletion' ||
          data.status === 'PassiveCompletion'
        "
        style="text-align: start"
        >{{ "pageHelp.TicketStatus3" | translate }}</span
      >
      <!-- <mat-icon style="margin-left: 35px"> keyboard_arrow_right </mat-icon> -->
      <img width="36px" src="../../../../assets/images/help/Icon-ticket-right.png">
    </div>
  </ng-template>
  <div
    class="item3"
    [ngStyle]="{
      direction: localStorageLanguage === 'ar' ? 'rtl' : 'ltr'
    }"
  >
    #{{ data.order.bus?.id }} {{ data.problem_platform_re[0].problem }}
  </div>
</div>

<div
  class="bottom"
  [ngClass]="{
    new: data.status === 'NoResponseReceived',
    old: data.status === 'ReplyReceived',
    close:
      data.status === 'ProactiveCompletion' ||
      data.status === 'PassiveCompletion'
  }"
></div>
