import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { lastValueFrom } from 'rxjs';
import { MessageService } from 'src/app/service/message.service';
import { LoadingDataService } from 'src/app/service/store/loading-data.service';
import { UserApiService } from 'src/app/service/user-api.service';

@Component({
  selector: 'app-send-update-code-button',
  templateUrl: './send-update-code-button.component.html',
  styleUrls: ['./send-update-code-button.component.scss']
})
export class SendUpdateCodeButtonComponent implements OnInit, OnChanges {
  @Input() sendButton = true;
  @Input() email = '';
  @Input() height = '38px';
  @Input() width = 'fit-content';
  timeout = 0;
  @Input() text = '';
  textMessage: any;

  constructor(
    private api: UserApiService,
    private message: MessageService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private translateService: TranslateService,
    private loadingDataService: LoadingDataService
  ) {
    this.translateService.onLangChange.subscribe((text: any) => {
      this.translateService.get('textMessage').subscribe((text: string) => {
        this.textMessage = text;
      });
    });
  }

  ngOnInit(): void {
    this.translateService.get('textMessage').subscribe((text: string) => {
      this.textMessage = text;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.email === '') this.sendButton = false;
    else this.sendButton = true;
  }

  loading = false;
  async sendCode() {
    const token = await lastValueFrom(
      this.recaptchaV3Service.execute('sendEmailCode')
    );
    if (!token) {
      this.message.error('recaptcha failed');
      return;
    }
    try {
      this.loading = true;
      this.loadingDataService.set(this.loading);
      const r = await this.api.sendUpdateCode(this.email, token);
      if (r.status === 1102) {
        this.message.warn(this.textMessage.SendFrequently);
        this.timeout = r.data;
      } else if (r.status === 1103) {
        this.message.warn(this.textMessage.Failure);
      } else if (r.status === 999) {
        this.message.warn(this.textMessage.AccountBanned);
      } else if (r.status !== 0) {
        this.message.warn(this.textMessage.SendFailed);
      } else {
        this.timeout = r.data;
        this.sendButton = false;
      }
      this.startTimeout();
    } catch (error) {
    } finally {
      this.loading = false;
      this.loadingDataService.set(this.loading);
    }
  }

  startTimeout() {
    if (this.timeout > 0) {
      let interval = setInterval(() => {
        this.sendButton = false;
        this.timeout -= 1;
        if (this.timeout === 0) {
          this.sendButton = true;
          clearInterval(interval);
        }
      }, 1000);
    }
  }
}
