<mat-card>
  <mat-card-header>
    <mat-card-title-group>
      <div class="title">{{ "pageLogin.SetUpPassword" | translate }}</div>
      <div class="subtitle">
        {{ "pageLogin.GetPassword" | translate }}
      </div>
      <div class="subtitle">
        {{ "pageLogin.SkipTip" | translate }}
      </div>
    </mat-card-title-group>
  </mat-card-header>
  <mat-card-content>
    <div class="space"></div>
    <form [formGroup]="formGroup">
      <div class="row">
        <app-input-icon
          [placeholder]="'placeholder.Password' | translate"
          type="password"
          formControlName="password1"
          icon="../../../../assets/images/login/Icon-lock.svg"
        ></app-input-icon>
      </div>
      <div class="row">
        <app-input-icon
          [placeholder]="'placeholder.Password' | translate"
          type="password"
          formControlName="password2"
          icon="../../../../assets/images/login/Icon-key.svg"
          (keydown.enter)="continue()"
        ></app-input-icon>
      </div>
    </form>

    <div class="tips">
      <app-tip
        [tip]="
          formGroup.controls['password1'].hasError('forbiddenName')
            ? false
            : true
        "
        >{{ "textError.Characters" | translate }}</app-tip
      >
      <app-tip
        [tip]="
          formGroup.controls['password1'].hasError('pattern') ? false : true
        "
        >{{ "textError.LettersAndNumbers" | translate }}</app-tip
      >
    </div>

    <app-button
      color="#602369"
      fontColor="#ffffff"
      height="48px"
      width="100%"
      fontSize="20px"
      [loading]="loading"
      (click)="continue()"
      >{{ "button.Continue" | translate }}</app-button
    >
    <div class="skip" routerLink="/">
      <span>{{ "pageLogin.Skip" | translate }}</span>
    </div>
  </mat-card-content>
  <mat-card-footer>
    <app-error *ngIf="error.ConfirmPassword">{{
      "textError.ConfirmPassword" | translate
    }}</app-error>
    <app-error *ngIf="error.PasswordsInconsistent">{{
      "textError.PasswordsInconsistent" | translate
    }}</app-error>
  </mat-card-footer>
</mat-card>
