import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-user-review-card',
  templateUrl: './user-review-card.component.html',
  styleUrls: ['./user-review-card.component.scss'],
})
export class UserReviewCardComponent implements OnChanges {
  @Input() data: any;
  starts: any = [];
  startsHalf: any = [];
  startsNon: any = [];

  ngOnChanges() {
    if (this.data) {
      this.starts = new Array(Math.floor(this.data.start));
      if (!Number.isInteger(this.data.start)) this.startsHalf = new Array(1);
      this.startsNon = new Array(
        5 - this.startsHalf.length - this.starts.length
      );
    }
  }
}
