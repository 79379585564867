<div class="container">


  <div
    class="title" 
  >
    <span class="highlight">{{"pageHome.Title1-1" | translate}} </span>
    <span class="title1">{{ "pageHome.Title1-2" | translate }}</span>
    <br>
    <div class="title2">{{ "pageHome.Title2" | translate }}</div>
    
    <div class="appreciate">
      <img 
        src="./../../../../assets/images/index/star.png"
        
      />
      <span>{{"pageHome.Appreciate" | translate}}</span>
    </div>
    
    
  </div>

  <div class="subtitle" (click)="login()">
    <div class="border-wrap" ><span>{{ "pageHome.Subtitle" | translate }}</span></div>
    
  </div>

  <div class="labels">
    <span class="label label1">
      <img
        alt="label"
        width="45"
        src="../../../../assets/images/index/header-label-support.png"
      />
      <span>{{ "pageHome.Label1" | translate }}</span>
    </span>
    <span class="label label2">
      <img
        alt="label"
        width="45"
        src="../../../../assets/images/index/header-label-fefund.png"
      />
      <span>{{ "pageHome.Label2" | translate }}</span>
    </span>
    <span class="label label3">
      <img
        alt="label"
        width="45"
        src="../../../../assets/images/index/header-label-safe.png"
      />
      <span>{{ "pageHome.Label3" | translate }}</span>
    </span>
  </div>

</div>