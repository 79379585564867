import { Component, OnInit, Input } from '@angular/core';
import { Crisp } from 'crisp-sdk-web';
import { RegionApiService } from 'src/app/service/region-api.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'

@Component({
  selector: 'app-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss'],
})
export class PageFooterComponent implements OnInit {
  isSmall: boolean = false
  payWayIcon: any = [
    {
      src: './../../../../../assets/images/page-footer/img-Visa.png'
    },
    {
      src: './../../../../../assets/images/page-footer/img-MasterCard.png'
    },
    {
      src: './../../../../../assets/images/page-footer/img-Stripe.png'
    },
    {
      src: './../../../../../assets/images/page-footer/img-Ideal.png'
    },
    {
      src: './../../../../../assets/images/page-footer/img-Paypal.png'
    },
    {
      src: './../../../../../assets/images/page-footer/img-Discover.png'
    },
    {
      src: './../../../../../assets/images/page-footer/img-Amazon.png'
    },
    {
      src: './../../../../../assets/images/page-footer/img-GooglePay.png'
    },
    {
      src: './../../../../../assets/images/page-footer/img-ApplePay.png'
    },
    {
      src: './../../../../../assets/images/page-footer/img-Amex.png'
    }
  ]
  @Input() communication = false;
  constructor(
    private api: RegionApiService,
    private breakpointObserver: BreakpointObserver
  ) {}
  ngOnInit(): void {
    this.getIpRegion();
    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
      .subscribe(result => {
        this.isSmall = result.matches; // 如果匹配手机模式，则为 true
      });
  }
  chat() {
    Crisp.chat.open();
    $crisp.push(['set', 'session:event', ['User:Welcome']]);
  }

  region: any;
  async getIpRegion() {
    try {
      const r = await this.api.getIpRegion();
      this.region = r.data;
    } catch (error) {}
  }
}
