import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-account-password',
  templateUrl: './account-password.component.html',
  styleUrls: ['./account-password.component.scss'],
})
export class AccountPasswordComponent implements OnChanges {
  @Input() text = '';

  show = false;

  ngOnChanges() {}
}
