import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-user-profile-menu-span',
  templateUrl: './user-profile-menu-span.component.html',
  styleUrls: ['./user-profile-menu-span.component.scss'],
})
export class UserProfileMenuSpanComponent {
  @Input() src = '';
}
