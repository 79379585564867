import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-selling-point-panel',
  templateUrl: './selling-point-panel.component.html',
  styleUrls: ['./selling-point-panel.component.scss'],
})
export class SellingPointPanelComponent implements OnInit {
  points: any = [];
  panelSellingPoint: any;
  isSmall = false;
  constructor(
    private translateService: TranslateService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.isSmall = this.breakpointObserver.isMatched([
      Breakpoints.XSmall,
      Breakpoints.Small,
    ]);
    this.translateService.onLangChange.subscribe((text: any) => {
      this.translateService
        .get('panelSellingPoint')
        .subscribe((text: string) => {
          this.panelSellingPoint = text;
          this.content();
        });
    });
  }

  ngOnInit(): void {
    this.translateService.get('panelSellingPoint').subscribe((text: string) => {
      this.panelSellingPoint = text;
      this.content();
    });
  }

  content() {
    if (this.panelSellingPoint) {
      this.points = [
        {
          alt: 'delivery',
          image: '../../../../assets/images/index/selling-point-img1.png',
          title: this.panelSellingPoint.Title1,
          content: this.panelSellingPoint.Content1,
        },
        {
          alt: 'support',
          image: '../../../../assets/images/index/selling-point-img2.png',
          title: this.panelSellingPoint.Title2,
          content: this.panelSellingPoint.Content2,
        },
        {
          alt: 'payment',
          image: '../../../../assets/images/index/selling-point-img3.png',
          title: this.panelSellingPoint.Title3,
          content: this.panelSellingPoint.Content3,
        },
        {
          alt: 'cosubscriber',
          image: '../../../../assets/images/index/selling-point-img4.png',
          title: this.panelSellingPoint.Title4,
          content: this.panelSellingPoint.Content4,
        },
        {
          alt: 'guarantee',
          image: '../../../../assets/images/index/selling-point-img5.png',
          title: this.panelSellingPoint.Title5,
          content: this.panelSellingPoint.Content5,
          // templateRef: 'button',
        },
        {
          alt: 'platforms',
          image: '../../../../assets/images/index/selling-point-img6.png',
          title: this.panelSellingPoint.Title6,
          content: this.panelSellingPoint.Content6,
        },
      ];
    }
  }
}
