<ng-container *ngIf="isAuto !== 'Auto'; else Auto">
  <span class="enter" (click)="enter()" *ngIf="!data.link_email">
    {{ "enterInvitationLink.EnterYour" | translate
    }}{{ data.bus.platform.name }}
    {{ "enterInvitationLink.LoginEmail" | translate }}
  </span>
  <span *ngIf="data.link_email" (click)="enter()" style="cursor: pointer">{{
    data.link_email
  }}</span>
</ng-container>
<ng-template #Auto>
  <span *ngIf="text !== ''; else elseBlock">{{ text }}</span>
  <ng-template #elseBlock>
    <span class="noAccount" (click)="chat()">{{
      "cardBusSeat.ContactCustomerService" | translate
    }}</span>
  </ng-template>
</ng-template>
