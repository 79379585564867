<button
  [matMenuTriggerData]="userMenuData"
  [matMenuTriggerFor]="menu"
  (click)="toggleDropdown()"
>
  {{ language }}
  <img alt="arrow" width="20" height="20" [src]="src" />
</button>

<mat-menu #menu="matMenu" class="my-menu">
  <ng-template matMenuContent let-menuWidth="menuWidth">
    <div [style.width.px]="menuWidth">
      <button
        mat-menu-item
        *ngFor="let data of languages"
        (click)="selectOption(data)"
      >
        <span>{{ data.name }}</span>
      </button>
    </div>
  </ng-template>
</mat-menu>
