<!-- <div
    class="check"
    
    (click)="checkDetail()"
  >
    <img
      width="18"
      height="13"
      src="../../../../assets/images/affiliate/Icon-chakan.svg"
      alt="check"
    />
    {{ "pageAffiliate.Check" | translate }}
  </div> -->

<div class="orderLists">
  

  <app-spinner [loading]="loading" *ngIf="loading"></app-spinner>
  <div class="no-data" *ngIf="orders.length === 0 && !loading">
    <app-no-data
      src="../../../../assets/images/base/empty-data.webp"
      [width]="338"
      [height]="274"
      [margin]="0"
      >{{ "pageAffiliate.Nodata" | translate }}</app-no-data
    >
  </div>
  <!-- <div class="order" *ngFor="let data of orders">
    <app-image
      [fileid]="data.bus_type_snapshot.platform.icon"
      width="122"
      height="51"
    ></app-image>
    <div class="detail">
      <div class="left">
        <div class="email">
          {{ data?.user.email }}
        </div>
        <div class="money discount_price" *ngIf="!isSmall">
          {{ "pageAffiliate.OfferAmount" | translate }}：{{
            data?.discount_price
          }}
          {{ data?.currency | uppercase }}
        </div>
        <div class="money commission" *ngIf="!isSmall">
          {{ "pageAffiliate.Commission" | translate }}：{{ data?.commission }}
          {{ data?.currency | uppercase }}
        </div>
        <app-commission-status
          [status]="data.commission_status"
          *ngIf="isSmall"
        ></app-commission-status>
      </div>
      <div class="right">
        <div class="total_price">
          <div class="money">
            <span *ngIf="!isSmall"
              >{{ "pageAffiliate.AmountOrder" | translate }}：</span
            >{{ data?.pay_price | number }}
            {{ data?.currency | uppercase }}
          </div>
          <div class="time">
            <app-datetime [text]="data?.paid_time"></app-datetime>
          </div>
        </div>
        <app-commission-status
          [status]="data.commission_status"
          *ngIf="!isSmall"
        ></app-commission-status>
      </div>
    </div>
  </div> -->
  <div class="content" mat-dialog-content>
    <table>
      <tr *ngIf="orders.length !== 0 && !loading">
        <th>{{ "pageAffiliate.Product" | translate }}</th>
        <th>{{ "pageAffiliate.User" | translate }}</th>
        <th>{{ "pageAffiliate.PurchaseTime" | translate }}</th>
        <th>{{ "pageAffiliate.OfferAmount" | translate }}</th>
        <th>{{ "pageAffiliate.OrderAmount" | translate }}</th>
        <th>{{ "pageAffiliate.Commission" | translate }}</th>
        <th>{{ "pageAffiliate.CommissionStatus" | translate }}</th>
      </tr>
      <tr *ngFor="let data of orders">
        <td>
          <app-image
            [fileid]="data.bus_type_snapshot.platform.icon"
            width="40"
            height="17"
          ></app-image>
        </td>
        <td>{{ data.user.email }}</td>
        <td><app-datetime [text]="data?.paid_time"></app-datetime></td>
        <td>{{ data?.discount_price }} {{ data?.currency | uppercase }}</td>
        <td>{{ data?.pay_price | number }} {{ data?.currency | uppercase }}</td>
        <td>{{ data.commission }} {{ data?.currency | uppercase }}</td>
        <td>
          <app-commission-status
            [status]="data.commission_status"
          ></app-commission-status>
        </td>
      </tr>
    </table>
  </div>
</div>
<div class="pagination" *ngIf="orders.length !== 0 && !loading">
  <app-paginator
    [currentPage]="page"
    [total]="total"
    [pageSize]="pageSize"
    (pageChange)="onPageChange($event)"
  ></app-paginator>
</div>
