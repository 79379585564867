<div class="top">
  <div class="content">
    <img
    *ngIf="isMobile"
    class="qiandaizi-ad"
    src="../../../assets/images/affiliate/affiliate-background-money.png"
    alt="money"
  />
    <div class="content1">
      {{"pageAffiliateAvert.Header-Title-1" | translate}} <span>{{ "pageAffiliateAvert.Header-Title-2" | translate }}</span> {{ "pageAffiliateAvert.Header-Title-3" | translate }}
    </div>
    <div class="content2">
      {{ "pageAffiliateAvert.Subtitle-1" | translate }} <span>{{ "pageAffiliateAvert.Subtitle-1-highlight" | translate }}</span><br />
      {{ "pageAffiliateAvert.Subtitle-2" | translate }} <span>{{ "pageAffiliateAvert.Subtitle-2-highlight" | translate }}</span>
    </div>
    <div class="button">
      <app-button
        
        (click)="goto()"
        fontColor="#fff"
        width="300px"
        height="50px"
      >
        {{ "pageAffiliateAvert.BecomeAffiliate" | translate }}
      </app-button>
    </div>
  </div>
  <img
    class="qiandaizi"
    *ngIf="!isMobile"
    src="../../../assets/images/affiliate/affiliate-background-money.png"
    alt="money"
  />
</div>

<div class="item1">
  <div class="arc">

  </div>
  <div class="title">
    {{ "pageAffiliateAvert.Title1" | translate }}
  </div>
  <div class="how-work">
    <app-how-work-panel></app-how-work-panel>
  </div>
  
</div>

<div class="title">
  {{ "pageAffiliateAvert.Title2-1" | translate }} <span>{{ "pageAffiliateAvert.Title2-2" | translate }}</span> {{"pageAffiliateAvert.Title2-3" | translate}}
</div>
<div class="item2">
  
  <div class="content">
    <div class="item" *ngIf="!isMobile">
      <span>
        <div class="text1">
          {{ "pageAffiliateAvert.Content2-1" | translate }}
        </div>
        <div class="text2">
          {{ "pageAffiliateAvert.Content2-2" | translate }}
        </div>
        <!-- <div class="text3" (click)="goto()">
          <span>{{ "pageAffiliateAvert.JoinNow" | translate }}</span>
        </div> -->
        <app-button
        (click)="goto()"
        fontColor="#fff"
        width="300px"
        height="50px"
        >
          {{ "pageAffiliateAvert.JoinNow" | translate }}
        </app-button>
      </span>
      <img
        src="../../../assets/images/affiliate/affiliate-pic1.png"
        alt="pic"
        width="541"
        height="336"
      />
    </div>
    <div class="item" *ngIf="isMobile">
      <img
        src="../../../assets/images/affiliate/affiliate-pic1.png"
        alt="pic"
        width="541"
        height="336"
      />
      <span>
        <div class="text1">
          {{ "pageAffiliateAvert.Content2-1" | translate }}
        </div>
        <div class="text2">
          {{ "pageAffiliateAvert.Content2-2" | translate }}
        </div>
        <!-- <div class="text3" (click)="goto()">
          <span>{{ "pageAffiliateAvert.JoinNow" | translate }}</span>
        </div> -->
        <app-button
        (click)="goto()"
        fontColor="#fff"
        width="300px"
        height="50px"
        >
          {{ "pageAffiliateAvert.JoinNow" | translate }}
        </app-button>
      </span>
    </div>
    <div class="space"></div>
    <div class="item">
      <img
        src="../../../assets/images/affiliate/affiliate-pic2.png"
        alt="pic"
        width="541"
        height="336"
      />
      <span>
        <div class="text1">
          {{ "pageAffiliateAvert.Content2-3" | translate }}
        </div>
        <div class="text2">
          {{ "pageAffiliateAvert.Content2-4" | translate }}
        </div>
        <!-- <div class="text3" (click)="goto()">
          <span>{{ "pageAffiliateAvert.JoinNow" | translate }}</span>
        </div> -->
        <app-button
        (click)="goto()"
        fontColor="#fff"
        width="300px"
        height="50px"
        >
          {{ "pageAffiliateAvert.JoinNow" | translate }}
        </app-button>
      </span>
    </div>
    <div class="space"></div>
    <div class="item" *ngIf="!isMobile">
      <span>
        <div class="text1">
          {{ "pageAffiliateAvert.Content2-5" | translate }}
        </div>
        <div class="text2">
          {{ "pageAffiliateAvert.Content2-6" | translate }}
        </div>
        <!-- <div class="text3" (click)="goto()">
          <span>{{ "pageAffiliateAvert.JoinNow" | translate }}</span>
        </div> -->
        <app-button
        (click)="goto()"
        fontColor="#fff"
        width="300px"
        height="50px"
        >
          {{ "pageAffiliateAvert.JoinNow" | translate }}
        </app-button>
      </span>
      <img
        src="../../../assets/images/affiliate/affiliate-pic3.png"
        alt="pic"
        width="541"
        height="336"
      />
    </div>
    <div class="item" *ngIf="isMobile">
      <img
        src="../../../assets/images/affiliate/affiliate-pic3.png"
        alt="pic"
        width="541"
        height="336"
      />
      <span>
        <div class="text1">
          {{ "pageAffiliateAvert.Content2-5" | translate }}
        </div>
        <div class="text2">
          {{ "pageAffiliateAvert.Content2-6" | translate }}
        </div>
        <!-- <div class="text3" (click)="goto()">
          <span>{{ "pageAffiliateAvert.JoinNow" | translate }}</span>
        </div> -->
        <app-button
        (click)="goto()"
        fontColor="#fff"
        width="300px"
        height="50px"
        >
          {{ "pageAffiliateAvert.JoinNow" | translate }}
        </app-button>
      </span>
    </div>
  </div>
</div>

<div class="title">
  <span>{{ "pageAffiliateAvert.Title3-1" | translate }}</span> {{"pageAffiliateAvert.Title3-2" | translate}}
</div>
<app-partner-panel></app-partner-panel>

<div class="title">
  {{ "pageAffiliateAvert.Title4-1" | translate }} <span>{{ "pageAffiliateAvert.Title4-2" | translate }}</span> {{"pageAffiliateAvert.Title4-3" | translate}}
</div>
<app-affiliate-partner-panel></app-affiliate-partner-panel>
