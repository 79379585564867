import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit{
  backgroundImageUrl = '/../../../assets/images/about-us/bg-1.png';
  @ViewChild('videoPlayer', { static: true }) videoPlayer!: ElementRef;
  @ViewChild('progressBar', { static: true }) progressBar!: ElementRef;

  isPlaying = false;
  currentTime = 0;
  duration = 0;



  playVideo() {
    this.videoPlayer.nativeElement.play();
  }

  pauseVideo() {
    this.videoPlayer.nativeElement.pause();
  }

  // togglePlayPause() {
  //   if (this.isPlaying) {
  //     this.videoPlayer.nativeElement.pause();
  //   } else {
  //     this.videoPlayer.nativeElement.play();
  //   }
  //   this.isPlaying = !this.isPlaying;
  // }

  // updateProgress() {
  //   const current = this.videoPlayer.nativeElement.currentTime;
  //   this.currentTime = current;
  //   this.progressBar.nativeElement.value = (current / this.duration) * 100;
  // }

  // initProgress() {
  //   this.duration = this.videoPlayer.nativeElement.duration;
  // }

  // seek(event: any) {
  //   const seekTime = (event.target.value / 100) * this.duration;
  //   this.videoPlayer.nativeElement.currentTime = seekTime;
  // }
  reviewData = [
    {
      year_1: '20',
      year_2: '24',
      title: '100 Members',
      description: 'Team grew to 100+ members.'
    },
    {
      year_1: '20',
      year_2: '23',
      title: 'KOL Marketing',
      description: 'Signed 1000+ KOLs to promote our products.'
    },
    {
      year_1: '20',
      year_2: '22',
      title: 'AI Products',
      description: 'Entered AIproducts with ChatGPT boom.'
    },
    {
      year_1: '20',
      year_2: '20',
      title: 'Streaming Market',
      description: 'Expanded to Netflix, YouTube, Disney+ etc.'
    },
    {
      year_1: '20',
      year_2: '18',
      title: 'Company Founded',
      description: 'Founded in Hong Kong with 5 colleagues.'
    }
  ];

  activeIndex: number = 0;
  prevIndex: number = 0;
  nextIndex: number = 0;
  preprevIndex: number = 0;
  nextnextIndex: number = 0;

  constructor(private elementRef:ElementRef){

  }

  ngOnInit() {
    this.showSlide(this.activeIndex);
    // this.setupSwipeGesture();
  }


  showSlide(index: number) {
    this.activeIndex = index;
    this.prevOrNextIndex();
  }

  nextSlide() {
    this.activeIndex = (this.activeIndex + 1) % this.reviewData.length;
    this.prevOrNextIndex();
  }

  prevSlide() {
    this.activeIndex =
      (this.activeIndex - 1 + this.reviewData.length) % this.reviewData.length;
    this.prevOrNextIndex();
  }

  // setupSwipeGesture(): void {
  //   const element = this.elementRef.nativeElement.querySelector(
  //     '.carousel-container'
  //   );
  //   const hammer = new Hammer(element);

  //   hammer.on('swipeleft', () => {
  //     this.nextSlide();
  //   });

  //   hammer.on('swiperight', () => {
  //     this.prevSlide();
  //   });
  // }

  toSlide(i: number) {
    this.activeIndex = i;
    this.prevOrNextIndex();
  }

  prevOrNextIndex() {
    this.prevIndex =
      this.activeIndex === 0
        ? this.reviewData.length - 1
        : this.activeIndex - 1;
    this.nextIndex =
      this.activeIndex === this.reviewData.length - 1
        ? 0
        : this.activeIndex + 1;

    this.preprevIndex =
      this.prevIndex === 0 ? this.reviewData.length - 1 : this.prevIndex - 1;

    this.nextnextIndex =
      this.activeIndex === this.reviewData.length - 2 ? 0 : this.nextIndex + 1;
  }


}