<section class="promotion-platform" [formGroup]="formGroup">
  <mat-checkbox
    *ngFor="let data of platforms; let i = index"
    [formControlName]="data.id"
    (change)="onCheckboxChange($event)"
  >
    <div class="left">
      <app-image [fileid]="data.icon" width="32" height="32"></app-image>
      <span *ngIf="!isSmall">{{ data.method }}</span>
    </div>
    <app-input
      [placeholder]="'pageAffiliateForm.PlaceholderLink' | translate"
      (input)="onInputChange($event, data.id)"
    ></app-input>
  </mat-checkbox>
</section>
