import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { PlatformModalComponent } from '../platform-modal/platform-modal.component';
import { DomSanitizer } from '@angular/platform-browser';
import { FileApiService } from 'src/app/service/file-api.service';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-platform-card',
  templateUrl: './platform-card.component.html',
  styleUrls: ['./platform-card.component.scss'],
})
export class PlatformCardComponent implements OnInit {
  @ViewChild('purchaseCard') purchaseCard!: ElementRef;
  @Input() data: any;
  isButtonType1: boolean = false;
  expanded = false;
  max = 3;
  rating: string = '4.8';

  constructor(
    private sanitizer: DomSanitizer,
    private fileapi: FileApiService,
    private myDialog: DialogService
  ) {}

  ngOnInit(): void {
    this.rating = (Math.round(Math.random() + 48) / 10.0).toFixed(1);
  }

  purchase() {
    const that = this;
    this.myDialog.open(PlatformModalComponent, {
      data: this.data,
      // 修改一下大小
      width: '800px',
      panelClass: 'order-dialog-container', // 添加自定义样式类
    });
  }

  fileurl(fileid: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      this.fileapi.fileurl(fileid)
    );
  }

  openHelp() {
    window.open(this.data.tips);
  }

  buttonOnMouseEnter(){
    this.isButtonType1 = true;
  }

  buttonOnMouseLeave(){
    this.isButtonType1 = false;
    
  }
}
