import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/service/store/language.service';
import { Languages } from 'src/app/utils/region';

@Component({
  selector: 'app-login-header',
  templateUrl: './login-header.component.html',
  styleUrls: ['./login-header.component.scss'],
})
export class LoginHeaderComponent {
  languages = Languages;
  isSmall = false;
  @Input() backgroundColor = '#000211'; //#000211   #f5ece3

  constructor(
    private languageService: LanguageService,
    private translate: TranslateService
  ) {}
  switchLanguage(lang: string) {
    this.languageService.set(lang);
    this.translate.use(lang);
  }
}
