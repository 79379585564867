<div class='card'>
  <div class="close">
    <button mat-dialog-close>
      <img
        alt="close"
        width="24"
        height="24"
        src="../../../../assets/images/base/Icon-del-default.svg"
      />
    </button>
  </div>
  <div class="title">{{ "RefreshNetflixCodeModal.Title" | translate }}</div>
  <div class="content">
    <div class="item">
      <div class="item-title">
        <img
          width="20"
          height="20"
          src="../../../../assets/images/subscription/Icon-wangluo.svg"
          alt=""
        />
        <span
          >{{ "RefreshNetflixCodeModal.Item1" | translate }}
          <img
            width="20"
            height="20"
            src="../../../../assets/images/subscription/Icon-duigouxiao.svg"
            alt=""
          />
          )</span
        >
      </div>
      <div class="text">
        1. {{ "RefreshNetflixCodeModal.Item1Text1" | translate }}
      </div>
      <div class="text">
        2.
        <span style="color: #FFB800">{{
          "RefreshNetflixCodeModal.Item1Text2-1" | translate
        }}</span
        >{{ "RefreshNetflixCodeModal.Item1Text2-2" | translate }}
      </div>
      <div class="text">
        3. {{ "RefreshNetflixCodeModal.Item1Text3" | translate }}
      </div>
    </div>
    <div class="item">
      <div class="item-title">
        <img
          width="20"
          height="20"
          src="../../../../assets/images/subscription/Icon-shebeiguanli.svg"
          alt=""
        />
        <span>{{ "RefreshNetflixCodeModal.Item2" | translate }}</span>
      </div>
      <div class="text">
        {{ "RefreshNetflixCodeModal.Item2Text" | translate }}
      </div>
    </div>
    <div class="item">
      <div class="item-title">
        <img
          width="20"
          height="20"
          src="../../../../assets/images/subscription/Icon-shebeipaibi.svg"
          alt=""
        />
        <span>{{ "RefreshNetflixCodeModal.Item3" | translate }}</span>
      </div>
      <div class="text">
        {{ "RefreshNetflixCodeModal.Item3Text1-1" | translate
        }}<span style="color: #602369">{{
          "RefreshNetflixCodeModal.Item3Text1-2" | translate
        }}</span
        >{{ "RefreshNetflixCodeModal.Item3Text1-3" | translate }}
      </div>
    </div>
  
    <div class="button">
      <app-button
        color="#612069"
        fontColor="#ffffff"
        width="100%"
        height="48px"
        (click)="onOk()"
        [disabled]="disabled"
      >
        {{ "RefreshNetflixCodeModal.ReFresh" | translate }}
        <span *ngIf="disabled">({{ timeout }} S)</span>
      </app-button>
    </div>
  </div>
  
</div>
