import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'src/app/service/message.service';
import { UserApiService } from 'src/app/service/user-api.service';
import { validateFormGroup } from 'src/app/utils/form';

@Component({
  selector: 'app-send-message-card',
  templateUrl: './send-message-card.component.html',
  styleUrls: ['./send-message-card.component.scss'],
})
export class SendMessageCardComponent implements OnInit {
  formGroup: UntypedFormGroup;
  errorText = '';
  textError: any;
  textMessage: any;

  constructor(
    private fb: UntypedFormBuilder,
    private api: UserApiService,
    private translateService: TranslateService,
    private router: Router,
    private message: MessageService
  ) {
    this.formGroup = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      message: ['', [Validators.required]],
      icon: ['', []],
    });

    this.translateService.onLangChange.subscribe((text: any) => {
      this.translateService.get('textError').subscribe((text: string) => {
        this.textError = text;
      });
      this.translateService.get('textMessage').subscribe((text: string) => {
        this.textMessage = text;
      });
    });

    this.formGroup.valueChanges.subscribe(() => {
      this.errorText = '';
    });
  }

  ngOnInit(): void {
    this.translateService.get('textError').subscribe((text: string) => {
      this.textError = text;
    });
    this.translateService.get('textMessage').subscribe((text: string) => {
      this.textMessage = text;
    });
  }

  send() {
    if (!validateFormGroup(this.formGroup)) {
      if (this.formGroup.controls['message'].errors)
        this.errorText = this.textError.RequiredMessage;
      if (this.formGroup.controls['email'].errors)
        this.errorText = this.textError.EnterEmail;
      if (this.formGroup.controls['name'].errors)
        this.errorText = this.textError.RequiredName;
      return;
    }
    this.sendMessage();
  }

  loading = false;
  async sendMessage() {
    const name = this.formGroup.value.name;
    const email = this.formGroup.value.email;
    const message = this.formGroup.value.message;
    const icon = this.formGroup.value.icon;

    try {
      this.loading = true;
      const r = await this.api.sendMessage('', email, message, name, icon);
      if (r.status === 0) {
        this.router.navigate(['/contact/successful']);
      } else {
        this.message.warn(this.textMessage.Unknown);
      }
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }
}
