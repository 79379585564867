import { Component, ElementRef, ViewChild, HostListener  } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractNgModelComponent } from '../../base/abstract-ng-model/abstract-ng-model.component';
import { MatMenuTrigger } from '@angular/material/menu';


@Component({
  selector: 'app-currency-radio-group',
  templateUrl: './currency-radio-group.component.html',
  styleUrls: ['./currency-radio-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CurrencyRadioGroupComponent,
    },
  ],
})
export class CurrencyRadioGroupComponent extends AbstractNgModelComponent {
  currencies = [
    { value: 'usd', name: 'USD($)' },
    { value: 'eur', name: 'EUR(€)' },
    { value: 'krw', name: 'KRW(₩)' },
  ];
  

  override writeValue(v: any): void {
    super.writeValue(v);
    this.currency = this.currencies.find((cur) => cur.value === v)?.name || '';
  }

  currency = '';

  @ViewChild(MatMenuTrigger, { static: true, read: ElementRef })
  userMenu!: ElementRef<HTMLElement>;

  get userMenuData() {
    return {
      menuWidth: this.userMenu.nativeElement.clientWidth,
    };
  }

  value: any = '';

  isDropdownOpen = false;
  src = '../../../../assets/images/withdraw/Icon-down-arrow-min.svg';

  constructor(private elementRef: ElementRef) {
    super();
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
    if (this.isDropdownOpen) {
      this.src = '../../../../assets/images/withdraw/Icon-up-arrow-min.svg';
    } else {
      this.src = '../../../../assets/images/withdraw/Icon-down-arrow-min.svg';
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
      this.src = '../../../../assets/images/withdraw/Icon-down-arrow-min.svg';
    }
  }

  override onChange(): void {
    super.onChange();
  }

  selectOption(option: any) {
    this.isDropdownOpen = false;
    this.src = '../../../../assets/images/withdraw/Icon-down-arrow-min.svg';
    this.value = option.value;
    this.currency = option.name
    this.onChange();
  }
}
