import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FileApiService } from 'src/app/service/file-api.service';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
})
export class UserAvatarComponent implements OnChanges {
  @Input() width = '42';
  @Input() height = '42';
  @Input() fileid = '';
  url = '';

  constructor(private api: FileApiService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.url = this.api.fileurl(this.fileid);
  }
}
