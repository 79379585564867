<div class="card">
  <div class="close">
    <button mat-dialog-close>
      <img
        alt="del"
        width="24"
        height="24"
        src="../../../../assets/images/base/Icon-del-default.svg"
      />
    </button>
  </div>
  <div
    class="title"
    [ngStyle]="{
      direction: localStorageLanguage === 'ar' ? 'rtl' : 'ltr'
    }"
  >
    {{ "modalCreateNewTicket.Ticket" | translate }}
  </div>
  <div
    class="subtitle"
    [ngStyle]="{
      direction: localStorageLanguage === 'ar' ? 'rtl' : 'ltr'
    }"
  >
    {{ "modalCreateNewTicket.SubTitle" | translate }}
  </div>
  <!-- <div class="divider"><p></p></div> -->
  <div class="content" [formGroup]="formGroup">
    <div class="item">
      <div
        class="question"
        [ngStyle]="{
          direction: localStorageLanguage === 'ar' ? 'rtl' : 'ltr'
        }"
      >
        {{ "modalCreateNewTicket.Select1" | translate }}<span>*</span>
      </div>
      <div class="answer">
        <app-subscription-select
          formControlName="subscription"
          (ngModelChange)="onValueChange($event)"
        ></app-subscription-select>
      </div>
    </div>
    <div class="item">
      <div
        class="question"
        [ngStyle]="{
          direction: localStorageLanguage === 'ar' ? 'rtl' : 'ltr'
        }"
      >
        {{ "modalCreateNewTicket.Select2" | translate }}<span>*</span>
      </div>
      <div class="answer">
        <app-issue-category-select
          [platform]="seat?.bus.platform.name"
          formControlName="issue"
        ></app-issue-category-select>
      </div>
    </div>
    <div class="editor">
      <div
        class="question"
        [ngStyle]="{
          direction: localStorageLanguage === 'ar' ? 'rtl' : 'ltr'
        }"
      >
        {{ "modalCreateNewTicket.Select3" | translate }}
      </div>
      <app-quill-editor
      formControlName="detail"
      [content]="localStorageLanguage === 'ar' ? 'ar' : ''"
    ></app-quill-editor>
    </div>
    
    <app-error *ngIf="error">{{ "textError.Require" | translate }}</app-error>
    <div class="button">
      <button class="button1" mat-stroked-button (click)="close()">
        {{ "button.Close" | translate }}
      </button>
      <button class="button2" mat-stroked-button (click)="submit()">
        {{ "button.Submit" | translate }}
      </button>
    </div>
  </div>  
</div>
