import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-selling-point-item',
  templateUrl: './selling-point-item.component.html',
  styleUrls: ['./selling-point-item.component.scss'],
})
export class SellingPointItemComponent implements OnChanges {
  @Input() image = '';
  @Input() title = '';
  @Input() content = '';
  @Input() alt = '';
  @Input() button = '';

  ngOnChanges(changes: SimpleChanges): void {}
}
