import { Component, ElementRef, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Pie, PieOptions } from '@antv/g2plot';

@Component({
  selector: 'app-g2-pie',
  templateUrl: './g2-pie.component.html',
  styleUrls: ['./g2-pie.component.scss'],
})
export class G2PieComponent implements OnChanges {
  @Input() cfg: PieOptions | null = null;
  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.render();
  }

  plot: Pie | null = null;
  render() {
    if (!this.cfg) {
      return;
    }
    if (!this.plot) {
      this.plot = new Pie(
        this.el.nativeElement.querySelector('#pie'),
        this.cfg
      );

    } else {
      this.plot.update(this.cfg);
    }
    this.plot.render();
  }

}
