<mat-card class="team-card">
    <div class="team-card-image-container" #backgroundElement>
        <img 
        mat-card-image class="team-card-image" 
        [src]="data.imageSrc"
        >
    </div>
    
    <mat-card-content>
        <h3>{{ data.name }}</h3>
        <p>{{ data.position }}</p>
    </mat-card-content>
</mat-card>