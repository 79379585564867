import { Component, OnChanges, Input } from '@angular/core';
import { LineOptions } from '@antv/g2plot';
import { TranslateService } from '@ngx-translate/core';
import { UserApiService } from 'src/app/service/user-api.service';

@Component({
  selector: 'app-orders-line',
  templateUrl: './orders-line.component.html',
  styleUrls: ['./orders-line.component.scss'],
})
export class OrdersLineComponent implements OnChanges {
  lineCfg: LineOptions | null = null;
  @Input() translatePageAffiliate: any;
  data: any;

  constructor(private api: UserApiService) {}

  ngOnChanges() {
    this.getUserAffiliateG2Orders();
  }

  setUpLineCfg(data: any) {
    this.lineCfg = {
      data,
      label: {
        style: {
          fill: '#ffffff'
        }
      },
      legend: {
        position: 'top',
        flipPage: false,
        title: {
          text: this.translatePageAffiliate.ChartLine,
          style: {
            fontSize: 18,
            fontWeight: 'bold',
            fill: '#ffffff',
          },
        },
      },

      xField: 'date',
      yField: 'order_count',
      seriesField: 'platform',
      xAxis: {
        title: {
          text: this.translatePageAffiliate.Date,
          style: {
            fill: '#ffffff',
          },
        },
      },
      yAxis: {
        line: {
          style: {
            stroke: '#aaa',
          },
        },
      },
      smooth: true,
      tooltip: {
        customItems: (items: any[]) => {
          let eur = 0;
          let usd = 0;
          for (const item of items) {
            if (item.name === 'usd' || item.name === 'eur') {
              continue;
            }

            eur = item.data.eur;
            usd += item.data.usd;
          }
          items.push({
            name: 'USD',
            value: '$' + usd.toFixed(2),
          });
          items.push({
            name: 'EUR',
            value: '€' + eur.toFixed(2),
          });
          return items;
        },
      },

      // 配置折线趋势填充
      area: {
        style: {
          fillOpacity: 0.15,
        },
      },
      animation: {
        appear: {
          animation: 'wave-in',
          duration: 1000,
        },
      },

      // animation: {
      //   appear: {
      //     animation: 'path-in',
      //     duration: 1000,
      //   },
      // },
    };
  }

  async getUserAffiliateG2Orders() {
    try {
      const r = await this.api.getUserAffiliateG2Orders();
      this.data = r.data;
      this.setUpLineCfg(this.data);
    } catch (error) {}
  }
}
