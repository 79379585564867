import { Component } from '@angular/core';
import { AbstractNgModelComponent } from '../../base/abstract-ng-model/abstract-ng-model.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-payment-method-select',
  templateUrl: './payment-method-select.component.html',
  styleUrls: ['./payment-method-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PaymentMethodSelectComponent,
    },
  ],
})
export class PaymentMethodSelectComponent extends AbstractNgModelComponent {
  options: any[] = [
    { value: 'Kakao Bank' },
    { value: 'Paypal' },
    { value: 'Bank Transfer' },
    { value: 'BTC' },
  ];
  value = '';
}
