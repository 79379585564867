import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss'],
})
export class PaymentMethodComponent implements OnInit {
  @Input() method = '';
  base: any;

  constructor(private translateService: TranslateService) {
    this.translateService.onLangChange.subscribe((text: any) => {
      this.translateService.get('base').subscribe((text: string) => {
        this.base = text;
      });
    });
  }

  ngOnInit(): void {
    this.translateService.get('base').subscribe((text: string) => {
      this.base = text;
    });
  }

  text() {
    const m: any = {
      Paypal: 'Paypal',
      'Kakao Pay': 'Kakao Pay',
      'Bank Transfer': 'Bank Transfer',
      BTC: 'BTC',
    };
    return m[this.method] || this.base?.Unknown;
  }
}
