<mat-card>
    <mat-card-content>
        <div class="item">
            <div class="title">
                {{ "pageAffiliate.Product" | translate }}
            </div>
            <div class="content">
                <app-image
                    [fileid]="data.bus_type_snapshot.platform.icon"
                    width="40"
                    height="17"
                ></app-image>
            </div>
        </div>

        <div class="item">
            <div class="title">
                {{ "pageAffiliate.User" | translate }}
            </div>
            <div class="content">
                {{ data.user.email }}
            </div>
        </div>

        <div class="item">
            <div class="title">
                {{ "pageAffiliate.PurchaseTime" | translate }}
            </div>
            <div class="content">
                <app-datetime [text]="data?.paid_time"></app-datetime>
            </div>
        </div>

        <div class="item">
            <div class="title">
                {{ "pageAffiliate.OfferAmount" | translate }}
            </div>
            <div class="content">
                {{ data?.discount_price }} {{ data?.currency | uppercase }}
            </div>
        </div>

        <div class="item">
            <div class="title">
                {{ "pageAffiliate.OrderAmount" | translate }}
            </div>
            <div class="content">
                {{ data?.pay_price | number }} {{ data?.currency | uppercase }}
            </div>
        </div>

        <div class="item">
            <div class="title">
                {{ "pageAffiliate.Commission" | translate }}
            </div>
            <div class="content">
                {{ data.commission }} {{ data?.currency | uppercase }}
            </div>
        </div>

        <div class="item">
            <div class="title">
                {{ "pageAffiliate.CommissionStatus" | translate }}
            </div>
            <div class="content">
                <app-commission-status
                    [status]="data.commission_status"
                ></app-commission-status>
            </div>
        </div>
    </mat-card-content>

    
</mat-card>