import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-order-list-page',
  templateUrl: './order-list-page.component.html',
  styleUrls: ['./order-list-page.component.scss'],
})
export class OrderListPageComponent {
  isSmall: any;
  constructor(
    private title: Title,
    private meta: Meta,
    private breakpointObserver: BreakpointObserver
  ) {
    this.isSmall = this.breakpointObserver.isMatched([
      Breakpoints.XSmall,
      Breakpoints.Small,
    ]);
    if (this.isSmall) {
      this.title.setTitle('PremLogin');
    } else {
      this.title.setTitle('Order List - PremLogin');
    }

    this.meta.updateTag({
      name: 'description',
      content: "User's order list. Paid, Refund, Finished.",
    });
  }
}
