import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.scss'],
})
export class NoDataComponent {
  @Input() src = '';
  @Input() width = 399;
  @Input() height = 341;
  @Input() margin = 40;
}
