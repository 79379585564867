<div
  [ngStyle]="{
    border: status === 'Completed' ? '1px solid green' : '1px solid red',
    color: status === 'Completed' ? 'green' : 'red'
  }"
>
  <span
    [ngStyle]="{
      color: status === 'Completed' ? 'green' : 'red'
    }"
    >{{ text() }}</span
  >
</div>