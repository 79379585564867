<mat-card *ngIf="!closed">
  <mat-card-title>
    {{ "modalCookieConsent.CookiesPrivacy" | translate }}
    <img
      alt="del"
      width="20"
      height="20"
      src="../../../assets/images/base/Icon-del.svg"
      (click)="close()"
    />
  </mat-card-title>
  <mat-card-content>
    {{ "modalCookieConsent.Content1" | translate
    }}<a routerLink="/privacypolicy">{{
      "modalCookieConsent.Content2" | translate
    }}</a
    >.
  </mat-card-content>
  <mat-card-actions>
    <app-button
      color="#602369"
      fontColor="#ffffff"
      fontSize="12px"
      width="295px"
      height="31px"
      (click)="accept()"
    >
      {{ "button.Accept" | translate }}</app-button
    >
  </mat-card-actions>
</mat-card>
