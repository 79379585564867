import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  isSmall = false;

  constructor(
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private breakpointObserver: BreakpointObserver
  ) {}

  open<T>(
    component: ComponentType<T>,
    config?: any,
    callback: Function | null = null
  ) {
    this.isSmall = this.breakpointObserver.isMatched([Breakpoints.XSmall]);
    if (this.isSmall) {
      const r = this.bottomSheet.open(component, config);
      if (callback != null) {
        r.afterDismissed().subscribe((r) => {
          callback(r);
        });
      }
    } else {
      const r = this.dialog.open(component, config);
      if (callback != null) {
        r.afterClosed().subscribe((r) => {
          callback(r);
        });
      }
    }
  }
}
