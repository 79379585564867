import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FileApiService } from 'src/app/service/file-api.service';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnChanges {
  @Input() fileid = '';
  @Input() width = 'auto';
  @Input() height = 'auto';

  url = '';

  constructor(private api: FileApiService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.url = this.api.fileurl(this.fileid);
  }
}
