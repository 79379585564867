import { Component, OnChanges, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-discount-money',
  templateUrl: './discount-money.component.html',
  styleUrls: ['./discount-money.component.scss'],
})
export class DiscountMoneyComponent implements OnChanges {
  @Input() busType: any;
  @Input() promo: any;
  @Input() exclusive: any;
  price: any = {};
  save: any = {};

  ngOnChanges(changes: SimpleChanges) {
    if (!this.busType) return;
    var discount = 0;

    if (this.exclusive === false) {
      if (this.promo?.value) {
        discount = parseFloat(
          (this.busType.price.value * (1 - this.promo.value)).toFixed(2)
        );
      }

      this.price.currency = this.busType?.price.currency;
      this.price.value = this.busType?.price.value - discount;

      if (this.price.currency === 'usd') {
        this.save.currency = '$';
      } else {
        this.save.currency = '€';
      }
      this.save.value = parseFloat(
        (this.busType?.original_price.value - this.price.value).toFixed(2)
      );
      this.save.discount = parseFloat(
        ((this.save.value / this.busType?.original_price.value) * 100).toFixed(
          2
        )
      );
    } else if (this.exclusive === true) {
      if (this.promo?.value) {
        discount = parseFloat(
          (
            this.busType?.exclusive_price.value *
            (1 - this.promo.value)
          ).toFixed(2)
        );
      }

      this.price.currency = this.busType?.exclusive_price.currency;
      this.price.value = this.busType?.exclusive_price.value - discount;

      if (this.price.currency === 'usd') {
        this.save.currency = '$';
      } else {
        this.save.currency = '€';
      }
      this.save.value = parseFloat(
        (
          this.busType?.original_exclusive_price.value - this.price.value
        ).toFixed(2)
      );
      this.save.discount = parseFloat(
        (
          (this.save.value / this.busType?.original_exclusive_price.value) *
          100
        ).toFixed(2)
      );
    }
  }
}
