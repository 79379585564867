<app-spinner [loading]="loading">
  <div class="close" (click)="close('')">
    <button>
      <img
        alt="del"
        width="24"
        height="24"
        src="../../../../assets/images/base/Icon-del-default.svg"
      />
    </button>
  </div>
  <div class="title">
    {{ "modalRenewCheck.OrderConfirm" | translate
    }}<span>（{{ "modalRenewCheck.Renew" | translate }}）</span>
  </div>
  <div class="top-background"></div>

  <div class="top-divider"></div>
  <div class="top-background"></div>
  <div class="content" mat-dialog-content>
    <mat-list role="list">
      <div class="listitem" role="listitem" style="margin-top: 0px;">
        <span class="key">{{ "modalRenewCheck.Platform" | translate }}：</span
        ><span class="value"
          ><app-image
            [fileid]="data.bus.platform.icon"
            width="72"
            height="30"
          ></app-image
        ></span>
      </div>
      <div class="listitem" role="listitem">
        <span class="key">{{ "modalRenewCheck.Duration" | translate }}：</span
        ><span class="value">{{ busType?.name }}</span>
      </div>
      <div class="listitem" role="listitem">
        <span class="key"
          >{{ "modalRenewCheck.AccountType" | translate }}：</span
        >
        <span class="value" *ngIf="data.bus.platform.name !== 'ChatGPT Plus'">
          {{
            data?.bus.exclusive
              ? data.bus.seat_count + ("modalRenewCheck.Profiles" | translate)
              : ("modalRenewCheck.Profile" | translate)
          }}
        </span>
        <span class="value" *ngIf="data.bus.platform.name === 'ChatGPT Plus'">
          {{ "modalRenewCheck.SharedPeople" | translate }}
        </span>
      </div>
      <div class="listitem" role="listitem">
        <span class="key">{{ "modalRenewCheck.Price" | translate }}：</span
        ><span class="value" style="font-weight: 600">
          <app-money
            [price]="
              data?.bus.exclusive ? busType?.exclusive_price : busType?.price
            "
            fontSize="16px"
            color="#D6392E"
          >
          </app-money
        ></span>
      </div>
      <!-- <div class="listitem" role="listitem">
        <span class="key"
          >{{ "modalRenewCheck.DiscountCode" | translate }}：</span
        ><span class="value">
          <button class="promo" (click)="enter()">
            <span [style.color]="discountPrice > 0 ? '#D6392E' : '#c1c1c1'">
              {{
                discountPrice > 0
                  ? renderDiscountPrice()
                  : ("modalRenewCheck.EnterPromoCode" | translate)
              }}
            </span>
          </button>
        </span>
      </div> -->

              <!-- 这里是新增的优惠码部分 -->
              <div class="purchase-promo-row">
                <div class="input-container">
                  <form [formGroup]="formGroup">
                    <div class="input-wrapper">
                      <input 
                      [attr.placeholder]="'placeholder.PromoCode' | translate"
                      formControlName="code"
                      >
                        <app-hollow-button
                        color="#612069"
                        fontColor="#ffffff"
                        width="80px"
                        height="46px"
                        [loading]="loading"
                        (click)="apply()"
                        >{{ "button.Apply" | translate }}
                        </app-hollow-button>
                        <!-- <app-discount-money
                        [busType]="selectedBusType"
                        [exclusive]="exclusive"
                        [promo]="promo"
                      ></app-discount-money> -->
                  </div>
                  <!-- <div class="discount">
                    <app-discount-money
                    [busType]="busType"
                    [promo]="promo"
                    [exclusive]="data?.bus.exclusive"
                  ></app-discount-money>
                  </div> -->
      
                </form>
                <div class="error">
                  <app-error *ngIf="errorText">{{ errorText }}</app-error>
                </div>
                <div class="discount">
                  <app-discount-money
                    [busType]="busType"
                    [promo]="promo"
                    [exclusive]="data?.bus.exclusive"
                    ></app-discount-money> 
                </div>
              </div>
              </div>
    </mat-list>
    <!-- <div class="action"> -->
      <!-- <app-discount-money
        [busType]="busType"
        [promo]="promo"
        [exclusive]="data?.bus.exclusive"
      ></app-discount-money> -->
      <div>
        <app-button
          color="#612069"
          fontColor="#ffffff"
          width="100%"
          height="48px"
          [loading]="loading"
          (click)="pay()"
          >{{ "button.CheckOut" | translate }}</app-button
        >
      </div>
    </div>
    <div class="top-background"></div>

  <!-- </div> -->
</app-spinner>
