<button
  [matMenuTriggerData]="userMenuData"
  [matMenuTriggerFor]="menu"
  (click)="toggleDropdown()"
>
  {{ contact }}
  <img alt="arrow" width="20" height="20" [src]="src" />
</button>
<mat-menu #menu="matMenu" class="my-menu">
  <ng-template matMenuContent let-menuWidth="menuWidth">
    <div [style.width.px]="menuWidth">
      <button
        mat-menu-item
        *ngFor="let data of contacts"
        (click)="selectOption(data.name)"
      >
        <app-image [fileid]="data.icon" width="32" height="32"></app-image>
        <span style="margin-left: 5px">{{ data.name }}</span>
      </button>
    </div>
  </ng-template>
</mat-menu>
