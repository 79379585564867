<div class="outside">
  <div class="close">
    <button mat-dialog-close>
      <img
        alt="del"
        width="40"
        height="40"
        src="../../../../assets/images/base/Icon-del-default.svg"
      />
    </button>
  </div>
  <div class="title">{{ "modalNoEmail.Title" | translate }}</div>
  <!-- <div class="content" mat-dialog-content>
    <ul>
      <li>{{ "modalNoEmail.Content1" | translate }}</li>
      <li>
        {{ "modalNoEmail.Content2" | translate }}
        <span style="font-weight: 600">noreply@premlogin.cc</span>
        {{ "modalNoEmail.Content3" | translate }}
      </li>
      <li>{{ "modalNoEmail.Content4" | translate }}</li>
    </ul>
  </div> -->
  <div class="new-content" mat-dialog-content>
    <ul>
      <div class="text-content">
        <img class="icon" src="../../../../assets/images/login/icon-no-email-1.svg">
        <div class="text">{{ "modalNoEmail.Content1" | translate }}</div>
      </div>
      <div class="text-content">
        <img class="icon" src="../../../../assets/images/login/icon-no-email-2.svg">
        <div class="text">
          {{ "modalNoEmail.Content2" | translate }}
          <span style="font-weight: 600">noreply@premlogin.cc</span>
          {{ "modalNoEmail.Content3" | translate }}
        </div>
      </div>
      <div class="text-content">
        <img class="icon" src="../../../../assets/images/login/icon-no-email-3.svg">
        <div class="text">{{ "modalNoEmail.Content4" | translate }}</div>
      </div>
    </ul>
  </div>
  <div class="action" mat-dialog-actions mat-dialog-close>
    <app-button color="#602369" fontColor="#ffffff" width="280px" height="48px">{{
      "modalNoEmail.Content5" | translate
    }}</app-button>
  </div>
</div>