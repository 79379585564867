import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-tip',
  templateUrl: './tip.component.html',
  styleUrls: ['./tip.component.scss'],
})
export class TipComponent implements OnChanges {
  @Input() tip = false;
  src = '';
  color = '';

  ngOnChanges(changes: SimpleChanges) {
    if (this.tip) {
      this.src = '../../../../assets/images/base/Icon-right.svg';
      this.color = '#2ED655';
    } else {
      this.src = '../../../../assets/images/base/Icon-warn.svg';
      this.color = '#D6392E';
    }
  }
}
