import {Component, Input, OnChanges, ViewChild, ElementRef, AfterViewInit, Renderer2} from '@angular/core'

@Component({
    selector: 'app-team-member-card',
    templateUrl: './team-member-card.component.html',
    styleUrls: ['./team-member-card.component.scss']
})
export class TeamMemberCardComponent implements OnChanges, AfterViewInit{
    @Input() data: any;
    @ViewChild('backgroundElement') backgroundElement!: ElementRef;

    constructor(private renderer: Renderer2) {}

    ngOnChanges(): void {
        if(this.data) {
            this.updateBackgroundGradient();
        }
    }

    ngAfterViewInit(): void {
        this.updateBackgroundGradient();
    }

    private updateBackgroundGradient(): void {
        if (this.backgroundElement && this.data.backgroundColor) {
            // this.backgroundElement.nativeElement.style.backgroundColor = this.data.backgroundColor;
            this.renderer.setStyle(this.backgroundElement.nativeElement, 'background', this.data.backgroundColor)
        }
    }
}