import { Component, DoCheck, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SpecialCouponDialogComponent } from 'src/app/components/coupon/special-version/special-coupon-dialog/special-coupon-dialog.component';
import { TranslateLoadingService } from 'src/app/service/store/translate-loading.service';

@Component({
  selector: 'app-page-wrapper',
  templateUrl: './page-wrapper.component.html',
  styleUrls: ['./page-wrapper.component.scss'],
})
export class PageWrapperComponent implements DoCheck, OnInit {
  loading = false;

  constructor(
    private translateLoading: TranslateLoadingService,
    private dialog: MatDialog
  ) {}

  normal = true;
  ngOnInit(): void {
    // const date = new Date().getTime();
    // const closeDate = new Date('2023/11/26 00:00:00').getTime();
    // if (date < closeDate) {
    // this.normal = false;
    // this.dialog.open(SpecialCouponDialogComponent, {panelClass: 'coupon-dialog'});
    // }
  }

  ngDoCheck(): void {
    this.loading = this.translateLoading.loading();
    this.setScrollbarVisibility();
  }
  isContentOverflowing() {
    return document.documentElement.scrollHeight > window.innerHeight;
  }

  setScrollbarVisibility() {
    if (this.isContentOverflowing()) {
      document.body.style.overflowY = 'auto';
    } else {
      document.body.style.overflowY = 'scroll';
      if (
        document.documentElement.querySelector('mat-dialog-container') ||
        document.documentElement.querySelector('mat-bottom-sheet-container')
      )
        document.body.style.overflowY = 'initial';
    }
  }
}
