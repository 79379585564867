// import { Component, Inject, OnInit } from '@angular/core';
// import {
//   UntypedFormBuilder,
//   UntypedFormGroup,
//   Validators,
// } from '@angular/forms';
// import { MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
// import { TranslateService } from '@ngx-translate/core';
// import { MessageService } from 'src/app/service/message.service';
// import { WorkorderApiService } from 'src/app/service/workorder-api.service';
// import { validateFormGroup } from 'src/app/utils/form';

// @Component({
//   selector: 'app-create-new-ticket-modal',
//   templateUrl: './create-new-ticket-modal.component.html',
//   styleUrls: ['./create-new-ticket-modal.component.scss'],
// })
// export class CreateNewTicketModalComponent implements OnInit {
//   formGroup: UntypedFormGroup;
//   textMessage: any;
//   localStorageLanguage = '';

//   constructor(
//     private fb: UntypedFormBuilder,
//     private dialogRef: MatDialogRef<CreateNewTicketModalComponent>,
//     private api: WorkorderApiService,
//     private message: MessageService,
//     private translateService: TranslateService,
//     @Inject(MAT_DIALOG_DATA) public data: any
//   ) {
//     this.formGroup = this.fb.group({
//       subscription: ['', [Validators.required]],
//       issue: ['', [Validators.required]],
//       detail: [''],
//     });

//     this.formGroup.valueChanges.subscribe((r) => {
//       this.error = false;
//     });

//     this.translateService.onLangChange.subscribe((text: any) => {
//       this.translateService.get('textMessage').subscribe((text: string) => {
//         this.textMessage = text;
//       });
//     });
//     this.localStorageLanguage = localStorage.getItem('language') || '';

//     if (this.data) {
//       this.formGroup.patchValue({
//         subscription: this.data,
//       });
//       this.onValueChange(this.data);
//     }
//   }

//   ngOnInit(): void {
//     this.translateService.get('textMessage').subscribe((text: string) => {
//       this.textMessage = text;
//     });
//   }

//   close(r: any = null) {
//     console.log(this.formGroup)
//     this.dialogRef.close(r);
//   }

//   error = false;
//   loading = false;
//   async submit() {
//     console.log(this.formGroup)
//     if (!validateFormGroup(this.formGroup)) {
//       this.error = true;
//       return;
//     }
//     const value = this.formGroup.value;
//     try {
//       this.loading = true;
//       const r = await this.api.postWordOrder(
//         this.seat.id,
//         value.issue.toString(),
//         value.detail,
//         'User'
//       );
//       if (r.status === 0) {
//         this.close(r);
//       } else {
//         this.message.warn(this.textMessage.Failure);
//       }
//     } catch (error) {}
//   }

//   seat: any;

//   onValueChange(data: any): void {
//     this.seat = data;
//   }
// }

import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'src/app/service/message.service';
import { WorkorderApiService } from 'src/app/service/workorder-api.service';
import { validateFormGroup } from 'src/app/utils/form';

@Component({
  selector: 'app-create-new-ticket-modal',
  templateUrl: './create-new-ticket-modal.component.html',
  styleUrls: ['./create-new-ticket-modal.component.scss'],
})
export class CreateNewTicketModalComponent implements OnInit {
  formGroup: UntypedFormGroup;
  textMessage: any;
  localStorageLanguage = '';

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CreateNewTicketModalComponent>,
    private api: WorkorderApiService,
    private message: MessageService,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.formGroup = this.fb.group({
      subscription: ['', [Validators.required]],
      issue: ['', [Validators.required]],
      detail: [''],
    });

    this.formGroup.valueChanges.subscribe((r) => {
      this.error = false;
    });

    this.translateService.onLangChange.subscribe((text: any) => {
      this.translateService.get('textMessage').subscribe((text: string) => {
        this.textMessage = text;
      });
    });
    this.localStorageLanguage = localStorage.getItem('language') || '';

    if (this.data) {
      console.log(this.data)
      this.formGroup.patchValue({
        subscription: this.data,
      });
      this.onValueChange(this.data);
    }
  }

  ngOnInit(): void {
    this.translateService.get('textMessage').subscribe((text: string) => {
      this.textMessage = text;
    });
  }

  close(r: any = null) {
    this.dialogRef.close(r);
  }

  error = false;
  loading = false;
  async submit() {
    if (!validateFormGroup(this.formGroup)) {
      this.error = true;
      return;
    }
    const value = this.formGroup.value;
    try {
      this.loading = true;
      const r = await this.api.postWordOrder(
        this.seat.id,
        value.issue.toString(),
        value.detail,
        'User'
      );
      if (r.status === 0) {
        this.close(r);
      } else if (r.data === 1401) {
        this.message.warn(this.textMessage.TicketRepeat);
      } else {
        this.message.warn(this.textMessage.Failure);
      }
    } catch (error) {}
  }

  seat: any;

  onValueChange(data: any): void {
    this.seat = data;
  }
}