<button [matMenuTriggerFor]="menuLanguage" class="btn">
  {{ "base.Language" | translate }}
  <img alt="arrow" width="20" height="20" [src]="src" />
</button>

<mat-menu #menuLanguage="matMenu">
  <button
    class="item"
    mat-menu-item
    *ngFor="let language of languages"
    (click)="switchLanguage(language.code)"
  >
    {{ language.name }}
    
  </button>
</mat-menu>
