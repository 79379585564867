import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-user-number',
  templateUrl: './user-number.component.html',
  styleUrls: ['./user-number.component.scss'],
})
export class UserNumberComponent {
  @Input() number = 0;
}
