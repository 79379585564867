<section class="payment" [formGroup]="formGroup">
  <mat-checkbox
    *ngFor="let data of payments"
    [formControlName]="data.id"
    (change)="onCheckboxChange($event)"
  >
    <app-image [fileid]="data.icon" width="32" height="32"></app-image>
    <span>{{ data.name }}</span>
  </mat-checkbox>
</section>
