import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-join-group-bottom-sheet',
  templateUrl: './join-group-bottom-sheet.component.html',
  styleUrls: ['./join-group-bottom-sheet.component.scss'],
})
export class JoinGroupBottomSheetComponent {
  constructor(
    private bottomSheetRef: MatBottomSheetRef<JoinGroupBottomSheetComponent>
  ) {}
  close() {
    this.bottomSheetRef.dismiss();
  }
}
