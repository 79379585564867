<div class="card">
  <div class="close">
    <button mat-dialog-close>
      <img
        alt="close"
        width="24"
        height="24"
        src="../../../../assets/images/base/Icon-del-default.svg"
      />
    </button>
  </div>
  <div class="title">{{ "TipNetflixCodeModal.Title" | translate }}</div>
  <div class="content">
    <div>
      {{ "TipNetflixCodeModal.Content1" | translate }}
    </div>
    <div>{{ "TipNetflixCodeModal.Copntent2" | translate }}</div>
    <div class="tip">
      <img
        src="../../../../assets/images/subscription/Icon-attention.svg"
        alt="attention"
        width="20"
        height="20"
      />
      {{ "TipNetflixCodeModal.Content3" | translate }}
    </div>
    <div class="button">
      <app-button
        fontColor="#ffffff"
        width="245px"
        height="48px"
        (click)="close()"
        type="0"
      >
        {{ "TipNetflixCodeModal.CLose" | translate }}
      </app-button>
      <app-button
        color="#612069"
        fontColor="#ffffff"
        width="245px"
        height="48px"
        (click)="onOk()"
      >
        {{ "TipNetflixCodeModal.OnOk" | translate }}
      </app-button>
    </div>
  </div>  
</div>
