<ng-container *ngIf="loading">
  <app-page-header></app-page-header>
  <div class="background-color"></div>
  <div style="flex: 1">
    <router-outlet></router-outlet>
  </div>
  <app-page-footer [communication]="true"></app-page-footer>

  <app-cookie-consent-modal></app-cookie-consent-modal>

  <!-- <ng-container *ngIf="normal">
    <app-normal-coupon-icon></app-normal-coupon-icon>
  </ng-container> -->
  <!-- <ng-container *ngIf="!normal">
    <app-special-coupon-icon></app-special-coupon-icon>
  </ng-container> -->
</ng-container>
