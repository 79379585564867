<mat-card>
  <mat-card-header style="color: white;">{{ "pageLogin.Login" | translate }}</mat-card-header>
  <mat-card-content>
    <div class="space"></div>
    <form [formGroup]="formGroup">
      <div class="row">
        <app-login-input-icon
          [placeholder]="'placeholder.EmailAddress' | translate"
          formControlName="email"
          icon="../../../../assets/images/login/Icon-lock.svg"
          height="90px"
        ></app-login-input-icon>
      </div>
      <div class="row">
        <app-login-input-icon
          formControlName="password"
          icon="../../../../assets/images/login/Icon-key.svg"
          [placeholder]="'placeholder.Password' | translate"
          type="password"
          height="90px"
          (keydown.enter)="login()"
        ></app-login-input-icon>
      </div>
    </form>

    <div class="change1" (click)="changeLogin()">
      <span>{{ "pageLogin.LoginWithCode" | translate }}</span>
      <img
        alt="arrow right"
        width="20"
        height="20"
        src="../../../../assets/images/base/Icon-arrow-right.svg"
      />
    </div>

    <app-button
      color="#602369"
      fontColor="#ffffff"
      height="60px"
      width="100%"
      fontSize="20px"
      [loading]="loading"
      (click)="login()"
      >{{ "button.Login" | translate }}</app-button
    >
    <!-- <div class="space1"></div> -->
    <div class="change2">
      <span
        >{{ "pageLogin.DoNotPassword" | translate }}<br />
        {{ "pageLogin.TryTo" | translate }}
        <span class="click" (click)="changeLogin()"
          >{{ "pageLogin.LoginWithCode" | translate }}
        </span></span
      >
    </div>
  </mat-card-content>
  <mat-card-footer>
    <app-error *ngIf="error">{{
      "textError.RequiredPassword" | translate
    }}</app-error>
  </mat-card-footer>
</mat-card>
