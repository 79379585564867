<mat-card>
  <mat-card-header
    ><mat-card-title-group>
      <div class="title">{{ "pageLogin.VerifyEmail" | translate }}</div>
      <div class="subtitle">
        {{ "pageLogin.WeSendCode" | translate }} {{ email }}.<br />
        {{ "pageLogin.EnterCodeToRegistration" | translate }}
      </div>
    </mat-card-title-group>
  </mat-card-header>
  <mat-card-content>
    <div class="space"></div>
    <div class="verification-code">
      <ng-otp-input
        (onInputChange)="onOtpChange($event)"
        [config]="{ length: 6 }"
        (keydown.enter)="continue()"
      ></ng-otp-input>
    </div>

    <app-button
      color="#602369"
      fontColor="#ffffff"
      fontSize="20px"
      height="48px"
      width="100%"
      [loading]="loading"
      (click)="continue()"
    >
      {{ "button.Continue" | translate }}</app-button
    >
    <app-button
      color="#E2E2E2"
      [fontColor]="resendButton ? '#602369' : '#757575'"
      height="48px"
      width="100%"
      fontSize="20px"
      (click)="resend()"
      [disabled]="timeout > 0 ? true : false"
      style="margin-bottom: 10px"
      >{{
        timeout === 0
          ? ("button.Resend" | translate)
          : ("button.Resend" | translate) + " ( " + timeout + "S )"
      }}</app-button
    >
    <div (click)="noEmail()">
      <span class="not-code">{{ "pageLogin.NoEmail" | translate }}</span>
    </div>
  </mat-card-content>
  <mat-card-footer>
    <app-error *ngIf="enterCode">{{
      "textError.EnterEmailCode" | translate
    }}</app-error>
  </mat-card-footer>
</mat-card>
