<div class="close">
  <button mat-dialog-close>
    <img
      alt="del"
      width="24"
      height="24"
      src="../../../../assets/images/base/Icon-del-default.svg"
    />
  </button>
</div>
<div class="title">
  <img
    src="../../../../assets/images/withdraw/check-right.svg"
    alt="check-right"
    width="90"
    height="90"
  />
  <div class="text">{{ "pageAffiliateForm.Successful" | translate }}</div>
</div>
<div class="content">
  <span>{{ count }}</span>
  {{ "pageAffiliateForm.JumpPage" | translate }}
</div>
