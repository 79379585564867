import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class RegionApiService {
  constructor(private http: HttpService) {}

  buildurl(path: string, queryMap: any = null): string {
    return this.http.buildurl('/region' + path, queryMap);
  }

  getIpRegion() {
    const url = this.buildurl('/ip/region');
    return this.http.fget(url);
  }
}
