<div class="content">
  <!-- <img
  *ngIf="data.avatar"
  [src]="data.avatar"
  alt="avatar"
  class="avatar-icon"
/> -->
  <span class="email">{{ data.email }}</span>
  <span class="more">{{
    "menuUserProfile.PersonalInformation" | translate
  }}</span>
</div>
<img
  alt="arrow"
  width="22"
  height="20"
  src="../../../../assets/images/base/Icon-arrow-right.svg"
/>
