<div class="bottom">
  <div *ngIf="region === 'kr'">
    <p>The rights to all copyrights, trademarks, and service marks are held by their respective owners.</p>
    <p>Copyright © 2024 PremLogin LIMITED (23 Grange Road, WAKEFIELD, WF69 5MK). All rights reserved.</p>
  </div>
  <div *ngIf="region !== 'kr'">
    <p>The rights to all copyrights, trademarks, and service marks are held by their respective owners.</p>
    <p>Copyright © 2024 PremLogin LIMITED (23 Grange Road, WAKEFIELD, WF69 5MK). All rights reserved.</p>
  </div>
  <div>
    <a routerLink="/terms-condition">TERMS AND CONDITION</a> and
    <a routerLink="/refundpolicy">PRIVACY POLICY</a>
  </div>
</div>
