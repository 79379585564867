<div class="container">
  <div class="content">
    <app-spinner [loading]="loading">
      <mat-card>
        <mat-card-header>
          {{ "button.ChangePassword" | translate }}
        </mat-card-header>
        <mat-card-content>
          <form [formGroup]="formGroup">
            <div class="info-row">
              <app-no-border-input
                type="password"
                [label]="'inputLabel.NewPassword' | translate"
                [placeholder]="'placeholder.Password' | translate"
                formControlName="password1"
              ></app-no-border-input>
            </div>
            <div class="space"></div>
            <div class="info-row">
              <app-no-border-input
                type="password"
                [label]="'inputLabel.RepeatPassword' | translate"
                [placeholder]="'placeholder.Password' | translate"
                formControlName="password2"
                (keydown.enter)="submit()"
              ></app-no-border-input>
            </div>
            <div class="space space2"></div>
            <div class="tips">
              <app-tip
                [tip]="
                  formGroup.controls['password1'].hasError('forbiddenName')
                    ? false
                    : true
                "
                >{{ "textError.Characters" | translate }}</app-tip
              >
              <app-tip
                [tip]="
                  formGroup.controls['password1'].hasError('pattern')
                    ? false
                    : true
                "
                >{{ "textError.LettersAndNumbers" | translate }}</app-tip
              >
            </div>
          </form>
        </mat-card-content>
        <mat-card-footer>
          <div>
            <app-error *ngIf="error.ConfirmPassword">{{
              "textError.ConfirmPassword" | translate
            }}</app-error>
            <app-error *ngIf="error.PasswordsInconsistent">{{
              "textError.PasswordsInconsistent" | translate
            }}</app-error>
          </div>
          <app-button
            color="#641F6C"
            fontColor="#ffffff"
            width="100%"
            height="48px"
            [loading]="loading"
            (click)="submit()"
            >{{ "button.Submit" | translate }}</app-button
          >
        </mat-card-footer>
      </mat-card>
    </app-spinner>
  </div>
</div>
