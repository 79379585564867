<div class="card">

  <div *ngIf="!isDevice4Sure">
    <div class="close">
      <button mat-dialog-close>
        <img
          alt="close"
          width="24"
          height="24"
          src="../../../../assets/images/base/Icon-del-default.svg"
        />
      </button>
    </div>
    <div class="title">{{ "NetflixCodeModal.Title" | translate }}</div>
    
    <div class="option-section">
      <span class="select-title"> {{"NetflixCodeModal.SelectTitle" | translate}}</span>
      <div class="options">
        <button (click)="chooseMobile()">
          <span>
            {{ "NetflixCodeModal.MobileVersion" | translate}}
          </span>
          
        </button>
  
        <button (click)="chooseDesktop()">
          <span>
            {{ "NetflixCodeModal.DesktopVersion" |translate}}
          </span>
          
        </button>
      </div>
  
      <div class="tip">
        <img
            src="../../../../assets/images/subscription/Icon-attention.svg"
            alt="attention"
            width="20"
            height="20"
          />
          {{ "NetflixCodeModal.Tip" | translate }}
      </div>
    </div>
  </div>
  
  
  
  <div *ngIf="!isMobile && isDevice4Sure">
    <div class="close">
      <button mat-dialog-close>
        <img
          alt="close"
          width="24"
          height="24"
          src="../../../../assets/images/base/Icon-del-default.svg"
        />
      </button>
    </div>
    <div class="title">{{ "NetflixCodeModal.Title" | translate }}</div>
    
    <div class="tabs">
      <div
        *ngFor="let data of [1, 2, 3, 4, 5]"
        class="tab"
        (click)="tab(data)"
        [ngClass]="{ active: data === activeIndex }"
      >{{ data }}</div>
    </div>
    
    <div class="content" [ngClass]="{ active: 1 === activeIndex }">
      <div class="step"><span>{{ "NetflixCodeModal.Step1" | translate }}</span></div>
      <div class="tip">
        <img
          src="../../../../assets/images/subscription/Icon-attention.svg"
          alt="attention"
          width="20"
          height="20"
        />
        {{ "NetflixCodeModal.Tip" | translate }}
      </div>
      <div class="desktop-detail">
        <div class="sub-detail">
          <div class="detail-text">
            <button class="detail-number">1</button>
            <span>{{"NetflixCodeModal.DesktopDetail1-1" | translate}}</span>
          </div>
          <img
          class="img-desktop-step-1-1"
          src="../../../../assets/images/subscription/desktop-step1-1.png"
          >
          
        </div>
        <div class="sub-detail">
          <div class="detail-text">
            <button class="detail-number">2</button>
            <span>{{"NetflixCodeModal.DesktopDetail1-2" | translate}}</span>
          </div>
          <img
          class="img-desktop-step-1-1"
          src="../../../../assets/images/subscription/desktop-step1-2.png"
          >
          
        </div>

      </div>
    </div>
    <div class="content" [ngClass]="{ active: 2 === activeIndex }">
      <div class="step"><span>{{ "NetflixCodeModal.Step2" | translate }}</span></div>
      <div class="desktop-detail">
        <div class="sub-detail">
          <div class="detail-text">
            <button class="detail-number">1</button>
            <span>{{"NetflixCodeModal.DesktopDetail1-1" | translate}}</span>
          </div>
          <img
          class="img-desktop-step-2-1"
          src="../../../../assets/images/subscription/desktop-step2-1.png"
          >
          
        </div>
        <div class="sub-detail">
          <div class="detail-text">
            <button class="detail-number">2</button>
            <span>{{"NetflixCodeModal.DesktopDetail2-2" | translate}}</span>
          </div>
          <img
          class="img-desktop-step-1-1"
          src="../../../../assets/images/subscription/desktop-step2-2.png"
          >
          
        </div>
        
      </div>
    </div>
    <div class="content" [ngClass]="{ active: 3 === activeIndex }">
      <div class="step"><span>{{ "NetflixCodeModal.Step3" | translate }}</span></div>
      <div class="desktop-detail">
        <div class="sub-detail">
          <div class="detail-text">
            <button class="detail-number">1</button>
            <span>{{"NetflixCodeModal.DesktopDetail3-1" | translate}}</span>
          </div>
          <img
          class="img-desktop-step-1-1"
          src="../../../../assets/images/subscription/desktop-step3-1.png"
          >
          
        </div>
        <div class="sub-detail">
          <div class="detail-text">
            <button class="detail-number">2</button>
            <span>{{"NetflixCodeModal.DesktopDetail3-2" | translate}}</span>
          </div>
          <img
          class="img-desktop-step-1-1"
          src="../../../../assets/images/subscription/desktop-step3-2.png"
          >
          
        </div>



      </div>
    </div>
    <div class="content" [ngClass]="{ active: 4 === activeIndex }">
      <div class="step"><span>{{ "NetflixCodeModal.Step4" | translate }}</span></div>
      <div class="desktop-detail">
        <div class="sub-detail2">
          <div class="detail-text">
            <button class="detail-number">1</button>
            <span>{{"NetflixCodeModal.DesktopDetail4" | translate}}</span>
          </div>
          <img
          class="img-desktop-step-1-1"
          src="../../../../assets/images/subscription/desktop-step4.png"
          >
          
        </div>
        <!-- <div class="sub-detail">
          <div class="detail-text">
            <button class="detail-number">2</button>
            <span>{{"NetflixCodeModal.DesktopDetail1-2" | translate}}</span>
          </div>
          <img
          class="img-desktop-step-1-1"
          src="../../../../assets/images/subscription/desktop-step1-2.png"
          >
          
        </div> -->



      </div>
    </div>
    <div class="content" [ngClass]="{ active: 5 === activeIndex }">
      <div class="step"><span>{{ "NetflixCodeModal.Step5" | translate }}</span></div>
      <div class="desktop-detail">
        <div class="sub-detail">
          <div class="detail-text">
            <button class="detail-number">1</button>
            <span>{{"NetflixCodeModal.DesktopDetail5-1" | translate}}</span>
          </div>
          <img
          class="img-desktop-step-1-1"
          src="../../../../assets/images/subscription/desktop-step5-1.png"
          >
          
        </div>
        <div class="sub-detail">
          <div class="detail-text">
            <button class="detail-number">2</button>
            <span>{{"NetflixCodeModal.DesktopDetail5-2" | translate}}</span>
          </div>
          <img
          class="img-desktop-step-1-1"
          src="../../../../assets/images/subscription/desktop-step5-2.png"
          >
          
        </div>


      </div>
    </div>
  
    
    <div
      class="button"
      *ngFor="let index of [1, 2, 3, 4]"
      [ngClass]="{ active: index === activeIndex }"
    >
      <app-button
        class="button-step1"
        fontColor="#ffffff"
        width="245px"
        height="40px"
        (click)="next(index)"
        type="0"
      >
        {{ "NetflixCodeModal.Next" | translate }}
      </app-button>
      <app-button
        class="button-step1"
        fontColor="#ffffff"
        width="245px"
        height="40px"
        (click)="skip()"
      >
        {{ "NetflixCodeModal.Skip" | translate }}
      </app-button>
    </div>
    
    <div class="button" [ngClass]="{ active: 5 === activeIndex }">
      <app-button
        style="width: 100%"
        color="#612069"
        fontColor="#ffffff"
        width="100%"
        height="40px"
        (click)="onOk()"
      >
        {{ "NetflixCodeModal.GetLink" | translate }}
      </app-button>
    </div>
    
  </div>
  
  <div *ngIf="isMobile && isDevice4Sure">
    <div class="close">
      <button mat-dialog-close>
        <img
          alt="close"
          width="24"
          height="24"
          src="../../../../assets/images/base/Icon-del-default.svg"
        />
      </button>
    </div>
    <div class="title">{{ "NetflixCodeModal.Title" | translate }}</div>


    <div class="tabs">
      <div
        *ngFor="let data of [1, 2, 3, 4, 5]"
        class="tab"
        (click)="tab(data)"
        [ngClass]="{ active: data === activeIndex }"
      >{{ data }}</div>
    </div>
    
    <div class="content" [ngClass]="{ active: 1 === activeIndex }">
      <div class="step"><span>{{ "NetflixCodeModal.Step1" | translate }}</span></div>
      <div class="tip">
        <img
          src="../../../../assets/images/subscription/Icon-attention.svg"
          alt="attention"
          width="20"
          height="20"
        />
        {{ "NetflixCodeModal.Tip" | translate }}
      </div>
      <div class="detail">
        {{ "NetflixCodeModal.MobileDetail1" | translate }} <br />
        <ng-container>
          <div class="img-container">
            <img
              class="mobile-step1"
              src="../../../../assets/images/subscription/step1-1.png"
              alt=""
              width="40%"
            />
          </div>
          
        </ng-container>
      </div>
    </div>
    <div class="content" [ngClass]="{ active: 2 === activeIndex }">
      <div class="step"><span>{{ "NetflixCodeModal.Step2" | translate }}</span></div>
      <div class="detail">
        {{ "NetflixCodeModal.MobileDetail2" | translate }}<br />
        <ng-container>
          <div class="img-container">
            <img
              class="mobile-step2"
              src="../../../../assets/images/subscription/step2-1.png"
              alt=""
            />
          </div>
        </ng-container>
        
      </div>
    </div>
    <div class="content" [ngClass]="{ active: 3 === activeIndex }">
      <div class="step"><span>{{ "NetflixCodeModal.Step3" | translate }}</span></div>
      <div class="detail">
        {{ "NetflixCodeModal.MobileDetail3" | translate }}<br />
        <div class="img-container">
          <img
            class="mobile-step3"
            src="../../../../assets/images/subscription/step3.png"
            alt=""
            height="253"
          />
        </div>
      </div>
    </div>
    <div class="content" [ngClass]="{ active: 4 === activeIndex }">
      <div class="step"><span>{{ "NetflixCodeModal.Step4" | translate }}</span></div>
      <div class="detail">
        {{ "NetflixCodeModal.MobileDetail4" | translate }}<br />
        <ng-container
          >
          <div class="img-container">
            <img
            class="mobile-step4"
              src="../../../../assets/images/subscription/step4.png"
              alt=""
              height="253"
            />
          </div>
        </ng-container>
      </div>
    </div>
    <div class="content" [ngClass]="{ active: 5 === activeIndex }">
      <div class="step"><span>{{ "NetflixCodeModal.Step5" | translate }}</span></div>
      <div class="detail">
        {{ "NetflixCodeModal.MobileDetail5" | translate }}<br />
        <ng-container>
          <div class="img-container">
            <img
            class="mobile-step5"
              src="../../../../assets/images/subscription/step5.png"
              alt=""
              height="253"
            />
          </div>
        </ng-container>
      </div>
    </div>
  
    
    <div
      class="button"
      *ngFor="let index of [1, 2, 3, 4]"
      [ngClass]="{ active: index === activeIndex }"
    >
      <app-button
        class="button-step1"
        fontColor="#ffffff"
        width="245px"
        height="40px"
        (click)="next(index)"
        type="0"
      >
        {{ "NetflixCodeModal.Next" | translate }}
      </app-button>
      <app-button
        class="button-step1"
        fontColor="#ffffff"
        width="245px"
        height="40px"
        (click)="skip()"
      >
        {{ "NetflixCodeModal.Skip" | translate }}
      </app-button>
    </div>
    
    <div class="button" [ngClass]="{ active: 5 === activeIndex }">
      <app-button
        style="width: 100%"
        color="#612069"
        fontColor="#ffffff"
        width="100%"
        height="40px"
        (click)="onOk()"
      >
        {{ "NetflixCodeModal.GetLink" | translate }}
      </app-button>
    </div>
    
  </div>
  
</div>

