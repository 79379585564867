import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TranslateLoadingService {
  constructor() {}
  loading = signal<any>(null);
  set(d: any) {
    this.loading.set(d);
  }
}
