<mat-card>
  <!-- <h3>{{ "pageContactUs.SendAMessage" | translate }}</h3> -->
  <div class="title">Send <span>Message</span></div>
  <p>We'd love to help! Send us your questions.</p>
  <form [formGroup]="formGroup">
    <div class="input">
      <span class="name">
        <div>{{ "inputLabel.Name" | translate }}</div>
        <app-input
          [placeholder]="'placeholder.Name' | translate"
          formControlName="name"
        ></app-input>
      </span>
      <span class="email">
        <div>{{ "inputLabel.Email" | translate }}</div>
        <app-input
          [placeholder]="'placeholder.EmailAddress' | translate"
          formControlName="email"
        ></app-input>
      </span>
    </div>
    <div class="input" style="margin-bottom: 0px">
      <span class="message">
        <div>{{ "inputLabel.Message" | translate }}</div>
        <textarea
          formControlName="message"
          [placeholder]="'placeholder.LeaveMessage' | translate"
        ></textarea>
      </span>
    </div>
    <!-- <div class="input" style="margin-bottom: 0px">
      <app-image-uploader
        formControlName="icon"
        width="250px"
        height="100px"
        [ratio]="2.5"
      ></app-image-uploader>
    </div> -->
  </form>
  <div class="error">
    <app-error *ngIf="errorText">
      {{ errorText }}
    </app-error>
  </div>
  <app-button
    color="#602369"
    fontColor="#ffffff"
    fontSize="18px"
    width="460px"
    height="72px"
    (click)="send()"
    [loading]="loading"
  >
    {{ "button.SubmitNow" | translate }}
  </app-button>
</mat-card>  
