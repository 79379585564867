<app-spinner>
  <div class="content">
    <div class="tip">
      <img
        src="../../../../assets/images/subscription/Icon-tip-new.svg"
        alt="tip"
        height="30"
        width="30"
      />
      <span
        >{{ "enterInvitationLink.Tip1" | translate }}
        {{ data.bus.platform.name }}
        {{ "enterInvitationLink.Tip2" | translate }}
        <br />
        {{ "enterInvitationLink.Tip3" | translate }}</span
      >
    </div>
    <!-- <div class="input">
      <form
        [formGroup]="formGroup"
        [ngStyle]="{
          border: error ? '1px solid #D9232E' : '1px solid #979797'
        }"
      >
        <input (keydown.enter)="send()" formControlName="email" />
        <span
          (click)="send()"
          [ngStyle]="{
            borderLeft: error ? '1px solid #D9232E' : '1px solid #979797'
          }"
          ><img
            src="../../../../assets/images/subscription/Icon-send.svg"
            alt="send"
            width="22"
            height="22"
        /></span>
      </form>
    </div> -->

    <div class="new-input">
      <form
        [formGroup]="formGroup"
      >
        <input (keydown.enter)="send()" formControlName="email" />
        <img
            class="icon"
            src="../../../../assets/images/subscription/Icon-send-new.svg"
            alt="send"
            (click)="send()"
        />
      </form>
    </div>

    <div class="error">
      <span *ngIf="error">{{ "textError.EnterEmail" | translate }}</span>
    </div>
  </div>
</app-spinner>
