import { Component, OnInit } from '@angular/core';
import { PieOptions, measureTextWidth } from '@antv/g2plot';
import { UserApiService } from 'src/app/service/user-api.service';

@Component({
  selector: 'app-orders-pie',
  templateUrl: './orders-pie.component.html',
  styleUrls: ['./orders-pie.component.scss'],
})
export class OrdersPieComponent implements OnInit {
  cfg: PieOptions | null = null;
  renderStatistic(containerWidth:any, text:any, style:any) {
    const textWidth = measureTextWidth(text, style);
    const textHeight = style.lineHeight || style.fontSize;
    const R = containerWidth / 2;
    let scale = 1;
    if (containerWidth < textWidth) {
      scale = Math.min(
        Math.sqrt(Math.abs(R * R / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))),
        1
      );
    }
    const textStyleStr = `width:${containerWidth}px;`;
    return `<div style="${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : 'inherit'};">${text}</div>`;
  }

  constructor(private api: UserApiService) {}

  ngOnInit(): void {
    this.getUserAffiliateG2Platforms();
  }

  data: any = {};

  setUpPieCfg(data: any) {
    this.cfg = {
      appendPadding: 10,
      data: data,
      angleField: 'count',
      colorField: 'platform_name',
      radius: 1,
      innerRadius: 0.6,
      label: {
        type: 'inner',
        offset: '-30%',
        content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
        style: {
          fontSize: 14,
          textAlign: 'center',
          fill: '#ffffff'
        },
      },
      interactions: [{ type: 'element-selected' },{ type: 'element-active' }],
      tooltip: {
        customItems: (items: any[]) => {
          let eur = 0;
          let usd = 0;
          for (const item of items) {
            if (item.name === 'usd' || item.name === 'eur') {
              continue;
            }

            eur = item.data.eur;
            usd += item.data.usd;
          }
          items.push({
            name: 'USD',
            value: '$' + usd.toFixed(2),
          });
          items.push({
            name: 'EUR',
            value: '€' + eur.toFixed(2),
          });
          return items;
        },
      },
      statistic: {
        title: {
          style: {
            color: '#ffffff', // 设置标题的字体颜色为红色
          },
          content: 'Revenue', // 这里是标题内容
        },
        content: {
          style: {
            whiteSpace: 'pre-wrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: '#ffffff',  // 设置字体颜色为红色
            
          },
          customHtml: (container, view, datum:any, data:any) => {
            const { width } = container.getBoundingClientRect();
            console.log(datum)
            let text = '100%';
            return this.renderStatistic(width, text, { fontSize: 32, color: '#ffffff' });

          }
        },
      },
    };
  }
  async getUserAffiliateG2Platforms() {
    try {
      const r = await this.api.getUserAffiliateG2Platforms();
      this.data = r.data;
      this.data.forEach((item: any) => {
        item.platform_name = item.platform.name;
      });
      this.setUpPieCfg(this.data);
    } catch (error) {}
  }
}
