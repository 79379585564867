import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component } from '@angular/core';

@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss'],
})
export class ShareModalComponent {
  isSmall = false;
  constructor(private breakpointObserver: BreakpointObserver) {
    this.isSmall = this.breakpointObserver.isMatched([Breakpoints.XSmall]);
  }
}
