import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PromoCodeModalComponent } from '../../index/promo-code-modal/promo-code-modal.component';
import { ProductApiService } from 'src/app/service/product-api.service';
import { UserService } from 'src/app/service/store/user.service';
import { Router } from '@angular/router';
import { PaymentSelectModalComponent } from '../../index/payment-select-modal/payment-select-modal.component';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { WaitingForPurchaseCompleteModalComponent } from '../../index/waiting-for-purchase-complete-modal/waiting-for-purchase-complete-modal.component';

import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'src/app/service/message.service';
import { OrderApiService } from 'src/app/service/order-api.service';
import { validateFormGroup } from 'src/app/utils/form';

@Component({
  selector: 'app-renew-check-bottom-sheet',
  templateUrl: './renew-check-bottom-sheet.component.html',
  styleUrls: ['./renew-check-bottom-sheet.component.scss'],
})
export class RenewCheckBottomSheetComponent implements OnInit {
  text = 'Enter promo code';
  promo: any;
  busType: any;

  // 下面是promo方法
  formGroup: UntypedFormGroup;
  error = false;
  textMessage: any;
  errorText = '';

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private dialog: MatDialog,
    private api: ProductApiService,
    private userService: UserService,
    private bottomSheetRef: MatBottomSheetRef<RenewCheckBottomSheetComponent>,
    private router: Router,

    // 下面是promo组件方法
    private promoApi: OrderApiService,
    private fb: UntypedFormBuilder,
    private message: MessageService,
    private translateService: TranslateService
  ) {

    // 下面是promo组件方法
    this.formGroup = this.fb.group({
      code: ['', [Validators.required]],
    });
  
    this.formGroup.valueChanges.subscribe(() => {
      this.errorText = '';
    });
  }

  ngOnInit(): void {
    this.findBusType();

    // 下面是promo方法
    this.translateService.get('textMessage').subscribe((text: string) => {
      this.textMessage = text;
    });
  }

  close(r: any) {
    this.bottomSheetRef.dismiss(r);
  }
  enter() {
    const price = this.data.bus.exclusive
      ? this.busType.exclusive_price.value
      : this.busType.price.value;
    this.dialog
      .open(PromoCodeModalComponent, {
        data: this.data.bus.bus_type,
        width: '500px',
        panelClass: 'app-full-dialog',
      })
      .afterClosed()
      .subscribe((r) => {
        if (r) {
          this.discountPrice = parseFloat(((1 - r.value) * price).toFixed(2));
          this.promo = r;
        } else {
          this.discountPrice = 0;
          this.promo = null;
        }
      });
  }

  loading = false;
  async findBusType() {
    const region = localStorage.getItem('region') || '';
    try {
      this.loading = true;
      const r = await this.api.findBusType(region, this.data.bus.bus_type.id);
      this.busType = r.data;
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }

  pay() {
    if (!this.userService.self) {
      this.close('');
      this.router.navigate(['/login']);
      return;
    }
    if (
      this.data.delivery_method === 'Manual-Account' ||
      this.data.delivery_method === 'Manual-Account/Password'
    ) {
      //暂时还不需要这个功能
    } else {
      this.openPaymentSelect();
    }
  }

  openPaymentSelect(account: string = '', password: string = '') {
    this.dialog
      .open(PaymentSelectModalComponent, {
        data: {
          busSeatID: this.data.id,
          busTypeID: this.busType.id,
          exclusive: this.data.bus.exclusive,
          promoCode: this.promo?.code || '',
          currency: this.busType.price.currency,
        },
        width: '560px',
      })
      .afterClosed()
      .subscribe((r) => {
        if (r) {
          this.close(r);
          this.dialog.open(WaitingForPurchaseCompleteModalComponent, {
            disableClose: true,
            data: r,
          });
        }
      });
  }

  discountPrice = 0;
  renderDiscountPrice() {
    const currency = this.busType?.price.currency === 'usd' ? '$' : '€';
    return ' - ' + currency + this.discountPrice;
  }

    // 下面是用promo组件的方法


  apply() {
    if (!validateFormGroup(this.formGroup)) {
      // this.dialogRef.close('');
      return;
    }
    this.findPromoCode();
  }

  async findPromoCode() {
    const code = this.formGroup.value.code;
    const busTypeId = this.data.bus.bus_type.id;
    try {
      this.loading = true;
      const r = await this.promoApi.findPromoCode(code, busTypeId, 0);
      if (r.status === 1203) {
        this.errorText = this.textMessage.CodeExist;
      } else if (r.status === 1204) {
        this.errorText = this.textMessage.CodeExpired;
      } else if (r.status === 1205) {
        this.errorText = this.textMessage.CodeValid;
      } else if (r.status === 1206) {
        this.errorText = this.textMessage.CodeUsageLimit;
      } else if (r.status === 1207) {
        this.errorText = this.textMessage.CodeNotApplicable;
      } else if (r.status === 1208) {
        this.errorText = this.textMessage.CodeUsed;
      } else if (r.status === 1209) {
        this.errorText = this.textMessage.FirstOrder;
      } else if (r.status === 1210) {
        this.errorText = this.textMessage.FirstProduct;
      } else if (r.status === 1211) {
        this.errorText = this.textMessage.RenewalAvailable;
      } else if (r.status === 1212) {
        this.errorText = this.textMessage.NonRenewal;
      } else if (r.status !== 0) {
        this.message.warn(this.textMessage.Unknown);
      } else {
        // this.close(r.data);
        const price = this.data.bus.exclusive
        ? this.busType.exclusive_price.value
        : this.busType.price.value;
        if (r) {
          this.discountPrice = parseFloat(((1 - r.data.value) * price).toFixed(2));
          this.promo = r.data;
        } else {
          this.discountPrice = 0;
          this.promo = null;
        }
      }
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }
}
