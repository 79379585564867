import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class ProductApiService {
  constructor(private http: HttpService) {}

  buildurl(path: string, queryMap: any = null): string {
    return this.http.buildurl('/product' + path, queryMap);
  }

  findPlatforms(region: string, language: string) {
    const url = this.buildurl('/platforms', { region, language });
    return this.http.fget(url);
  }

  findBusType(region: string, id: number) {
    const url = this.buildurl(`/bus/type/${id}`, { region });
    return this.http.fget(url);
  }
}
