<button
  [matMenuTriggerData]="userMenuData"
  [matMenuTriggerFor]="menu"
  (click)="toggleDropdown()"
  [ngStyle]="{
    direction: localStorageLanguage === 'ar' ? 'rtl' : 'ltr'
  }"
>
  <ng-container *ngIf="problem">{{ problem?.problem }} </ng-container>
  <ng-container *ngIf="!problem">{{
    "modalCreateNewTicket.Select2Pla" | translate
  }}</ng-container>
  <img alt="arrow" width="20" height="20" [src]="src" />
</button>
<mat-menu #menu="matMenu" class="my-menu">
  <ng-template matMenuContent let-menuWidth="menuWidth">
    <div [style.width.px]="menuWidth">
      <button mat-menu-item *ngIf="problems.length === 0">
        {{ "modalCreateNewTicket.NoData" | translate }}
      </button>
      <button
        mat-menu-item
        *ngFor="let data of problems"
        (click)="selectOption(data)"
      >
        <span>{{ data.problem }}</span>
      </button>
    </div>
  </ng-template>
</mat-menu>
