<div class="container">
  <div class="content">
    <div class="title">Contact <span>Us</span></div>
    <div class="panel-container">
      <app-contact-us-panel></app-contact-us-panel>
    </div>
    
    <div class="send-card">
      <app-send-message-card></app-send-message-card>
      
    </div>
  </div>
</div>
