import { Component, Input } from '@angular/core';
import { AbstractNgModelComponent } from '../abstract-ng-model/abstract-ng-model.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-no-border-input',
  templateUrl: './no-border-input.component.html',
  styleUrls: ['./no-border-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: NoBorderInputComponent,
    },
  ],
})
export class NoBorderInputComponent extends AbstractNgModelComponent {
  @Input() label = '';
  @Input() type = 'text';
  @Input() placeholder = '';
  value = '';
  passwordVisible = false;
}
