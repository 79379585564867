<div class="select">
  <div class="selected-option" (click)="toggleDropdown()">
    <span>{{ value | uppercase }}</span>
    <img alt="arrow" width="20" height="20" [src]="src" />
  </div>
  <div class="options" *ngIf="isDropdownOpen">
    <button *ngFor="let option of options" (click)="selectOption(option)">
      {{ option?.value | uppercase }}
    </button>
  </div>
</div>
