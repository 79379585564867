<span>
  <app-account-password
    *ngIf="text !== ''"
    [text]="text"
  ></app-account-password>
  <ng-container *ngIf="text === ''">{{ text }}</ng-container>
</span>
<!-- <mat-icon (click)="copy()" *ngIf="text" inline style="color: #CF6CFF"
  >content_copy</mat-icon
> -->
