import { Component, OnInit, Input } from '@angular/core';
import { UserApiService } from 'src/app/service/user-api.service';

@Component({
  selector: 'app-affiliate-order-list-cards',
  templateUrl: './affiliate-order-list-cards.component.html',
  styleUrls: ['./affiliate-order-list-cards.component.scss'],
})
export class AffiliateOrderListCardsComponent implements OnInit {
  page = 1;
  pageSize = 5;
  total = 0;
  orders: any[] = [];
  @Input() typelist = 0;

  isSmall = false;
  constructor(
    private api: UserApiService
  ) {

  }

  ngOnInit(): void {
    this.findAffiliateOrderList();
  }

  onPageChange(e: any) {
    this.page = e;
    this.findAffiliateOrderList();
  }

  loading = false;
  async findAffiliateOrderList() {
    try {
      this.loading = true;
      const r = await this.api.getAffiliateCommissionList(
        this.typelist,
        this.page,
        this.pageSize
      );
      this.orders = r.data.list;
      this.page = r.data.page;
      this.pageSize = r.data.page_size;
      this.total = r.data.total;
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }

}
