import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ticket-card',
  templateUrl: './ticket-card.component.html',
  styleUrls: ['./ticket-card.component.scss'],
})
export class TicketCardComponent implements OnChanges {
  @Input() data: any;
  localStorageLanguage = '';

  constructor(private router: Router) {
    this.localStorageLanguage = localStorage.getItem('language') || '';
  }

  ngOnChanges(changes: SimpleChanges): void {}

  goto() {
    this.router.navigate(['/help/ticket'], {
      queryParams: { id: this.data.id },
    });
  }
}
