import { Component, Input } from '@angular/core';
import { AbstractNgModelComponent } from '../../base/abstract-ng-model/abstract-ng-model.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-ticket-card-search',
  templateUrl: './ticket-card-search.component.html',
  styleUrls: ['./ticket-card-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TicketCardSearchComponent,
    },
  ],
})
export class TicketCardSearchComponent extends AbstractNgModelComponent {
  @Input() placeholder = '';
  value = '';
  localStorageLanguage = '';
  constructor() {
    super();
    this.localStorageLanguage = localStorage.getItem('language') || '';
  }
}
