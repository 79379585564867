import { Component, OnInit } from '@angular/core';
import { RegionApiService } from 'src/app/service/region-api.service';
import { UserService } from 'src/app/service/store/user.service';
import { UserApiService } from 'src/app/service/user-api.service';
// import { Route } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'app-index-title',
  templateUrl: './index-title.component.html',
  styleUrls: ['./index-title.component.scss'],
})
export class IndexTitleComponent implements OnInit {
  self: any = null;
  constructor(
    private regionApi: RegionApiService,
    private userApi: UserApiService,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getSelf();
    this.userService.getSelf();
    this.getIpRegion();
  }

  async login() {
    if(!this.self){
      this.router.navigate(['/login'])
    }
  }

  async getSelf() {
    try {
      const r = await this.userApi.getSelf();
      this.self = r.data;
      this.userService.set(this.self);
    } catch (error) {}
  }

  region: any;
  async getIpRegion() {
    try {
      const r = await this.regionApi.getIpRegion();
      this.region = r.data;
    } catch (error) {}
  }
}
