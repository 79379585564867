// import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Component, OnInit} from '@angular/core'
// import { TranslateService } from '@ngx-translate/core'

@Component({
    selector: 'app-how-work-panel',
    templateUrl: './how-work-panel.component.html',
    styleUrls: ['./how-work-panel.component.scss'],
})
export class HowWorkPanelComponent implements OnInit {
    points: any = [];
    panelSellingPoint: any;
    isSmall = false;
    constructor() {

    }

    ngOnInit(): void {

    }

    contents = [
        {
            alt: 'Join',
            image: '../../../../assets/images/affiliate/how-work-join.png',
            title: '1. Join',
            content: 'Free and fast Affiliate registration! Start earning in minutes. Channels with over 10k fans will be contacted by our business manager.',
        },
        {
            alt: 'Promote',
            image: '../../../../assets/images/affiliate/how-work-promote.png',
            title: '2. Promote',
            content: 'Share your affiliate link with your fans and friends. Feature your exclusive link on your website, blog, Facebook, Twitter, YouTube Channel, Instagram, and more.',
        },
        {
            alt: 'Get Commission',
            image: '../../../../assets/images/affiliate/how-work-get-commission.png',
            title: '3. Get Commission',
            content: 'Earn commission on every new user registration and purchase through your referral link.',
        },
        
    ]
}