import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-union-card',
  templateUrl: './union-card.component.html',
  styleUrls: ['./union-card.component.scss'],
})
export class UnionCardComponent {
  @Input() data: any;
}
