import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  data = '';

  constructor() {
    this.data = localStorage.getItem('language') || '';
  }

  observer = new Subject();
  public subscribe$ = this.observer.asObservable();

  set(data: string) {
    this.data = data;
    localStorage.setItem('language', this.data);
    this.observer.next(data);
  }
}
