
<ng-container>
  
    <mat-card [ngClass]="{ expanded: expanded }" [id]="data.name"
      #purchaseCard
      (mouseenter)="buttonOnMouseEnter()"
      (mouseleave)="buttonOnMouseLeave()"
    >
      <div class="gradient-border">
      <div class="help">
        <a (click)="openHelp()" *ngIf="data.tips">{{
          "modalPlatform.UseHelp" | translate
        }}</a>
      </div>
      <mat-card-title>
        <app-image [fileid]="data.icon" width="200" height="100"></app-image>
      </mat-card-title>
      <div class="subtitle">
        <app-user-number [number]="data.user_count"></app-user-number>
        <app-duration-price
          [price]="data.price"
          [duration]="data.price_duration"
        ></app-duration-price>
      </div>
      <div class="divide-line">
      </div>
      <mat-list role="list">
        <ng-container *ngFor="let f of data.features; let i = index">
          
          <mat-list-item role="listitem" *ngIf="i < max || expanded">
            <img
              alt="icon"
              width="20"
              height="20"
              src="../../../../assets/images/index/Icon-right.svg"
            />
            <span
              style="color:#ffffff"
              [ngStyle]="{
                whiteSpace: expanded ? 'normal' : 'nowrap',
                overflow: expanded ? 'visible' : 'hidden'
              }"
              >{{ f }}</span
            >
          </mat-list-item>
          <div
          style="color:#ffffff"
            *ngIf="
              (!expanded && i === max - 1 && data.features.length >= max) ||
              (expanded && i === data.features.length - 1)
            "
            (click)="expanded = !expanded"
          >
            
            <img width="35px" *ngIf="!expanded" src='../../../../assets/images/index/Icon-arrow-down.png'>
            <img width="35px" *ngIf="expanded" src="../../../../assets/images/index/Icon-arrow-up.png">
            
                
          </div>
        </ng-container>
      </mat-list>
      <div class="divide-line">
      </div>
      <mat-card-actions>
        <app-button
          *ngIf="isButtonType1"
          height="100%"
          width="100%"
          (click)="purchase()"
          fontColor="#fff"
          type="1"
        >
          <span>{{ "button.Purchase" | translate }}</span>
        </app-button>
        <app-button
          *ngIf="!isButtonType1"
          height="100%"
          width="100%"
          (click)="purchase()"
          fontColor="#fff"
          type="2"
        >
          <span>{{ "button.Purchase" | translate }}</span>
        </app-button>
      </mat-card-actions>
    </div>
    </mat-card>

</ng-container>


<div itemtype="https://schema.org/Product" itemscope>
  <meta itemprop="name" [content]="data.name" />
  <link itemprop="image" [href]="fileurl(data.icon)" />
  <meta
    itemprop="description"
    [content]="data.name + ' premium subscription'"
  />
  <div itemprop="offers" itemtype="https://schema.org/AggregateOffer" itemscope>
    <meta itemprop="lowPrice" [content]="data.price.value" />
    <meta itemprop="highPrice" [content]="data.price.value" />
    <meta itemprop="offerCount" content="1" />
    <meta itemprop="priceCurrency" [content]="data.price.currency" />
  </div>
  <div
    itemprop="aggregateRating"
    itemtype="https://schema.org/AggregateRating"
    itemscope
  >
    <meta itemprop="reviewCount" content="99" />
    <meta itemprop="ratingValue" [content]="rating" />
  </div>
</div>


