<div>
    <input
      [placeholder]="placeholder"
      [(ngModel)]="value"
      (ngModelChange)="onChange()"
      [disabled]="disabled"
      [type]="passwordVisible ? 'text' : type"
    />
    <ng-container *ngIf="type === 'password'">
      <img
        alt="eye"
        width="20"
        height="20"
        style="margin-right: 5px; cursor: pointer"
        [src]="
          passwordVisible
            ? '../../../../assets/images/base/Icon-eye.svg'
            : '../../../../assets/images/base/Icon-eyeclose.svg'
        "
        (click)="passwordVisible = !passwordVisible"
      />
    </ng-container>
    <span
      class="icon"
      *ngIf="icon"
      [ngStyle]="{ 'border-left': '1px solid #c1c1c1' }"
    >
      <img alt="icon" width="30" height="30" [src]="icon" style="margin-right: 10px;" />
    </span>
    <app-send-code-button *ngIf="!icon" [email]="email"> </app-send-code-button>
  </div>
  