<div class="container">
  <mat-card>
    <h3>{{ "pageAffiliateForm.Title" | translate }}</h3>
    <p>
      {{ "pageAffiliateForm.Subtitle" | translate }}
    </p>
    <form [formGroup]="formGroup">
      <div class="input">
        <span class="name">
          <div class="title">
            
            <span>{{ "pageAffiliateForm.NameLabel" | translate }}</span>
            <span style="color: #ffffff; margin-right: 5px">*</span>
            
          </div>
          <app-input
            [placeholder]="'placeholder.Name' | translate"
            formControlName="name"
          ></app-input>
        </span>
      </div>
      <div class="input">
        <span class="name">
          <div class="title">
            <!-- <span style="color: #e60023; margin-right: 5px">*</span> -->
            <span>{{ "pageAffiliateForm.PhoneLabel" | translate }}</span>
            <span style="color: #ffffff; margin-right: 5px">*</span>
          </div>
          <ngx-intl-tel-input

            [preferredCountries]="preferredCountries"
            [enableAutoCountrySelect]="true"
            [enablePlaceholder]="true"
            [searchCountryFlag]="true"
            [searchCountryField]="[
              SearchCountryField.Iso2,
              SearchCountryField.Name
            ]"
            [selectFirstCountry]="false"
            [selectedCountryISO]="CountryISO.India"
            [maxLength]="15"
            [phoneValidation]="true"
            [separateDialCode]="separateDialCode"
            [numberFormat]="PhoneNumberFormat.National"
            name="phone"
            formControlName="phone"
          >
          </ngx-intl-tel-input>
        </span>
      </div>
      <div class="input">
        <span class="name">
          <div class="title">
            <!-- <span style="color: #e60023; margin-right: 5px">*</span> -->
            <span>{{ "pageAffiliateForm.CurrencyLabel" | translate }}</span>
            <span style="color: #ffffff; margin-right: 5px">*</span>
          </div>

          <!-- <div class="currency" *ngIf="currency">
            USD ($)
            <span (click)="chooseCurrency()">{{
              "pageAffiliateForm.ChooseCurrency" | translate
            }}</span>
          </div> -->
          <app-currency-radio-group
            formControlName="currency"
          ></app-currency-radio-group>
        </span>
      </div>
      <div class="input">
        <span class="name">
          <div class="title">
            <!-- <span style="color: #e60023; margin-right: 5px">*</span> -->
            <span>{{ "pageAffiliateForm.PromotionLabel" | translate }}</span>
            <span style="color: #ffffff; margin-right: 5px">*</span>
          </div>
          <app-promotion-platform-checkbox
            formControlName="platform"
          ></app-promotion-platform-checkbox>
        </span>
      </div>
      <div class="input">
        <span class="name">
          <div class="title">
            <!-- <span style="color: #e60023; margin-right: 5px">*</span> -->
            <span>{{ "pageAffiliateForm.LanguageLabel" | translate }}</span>
            <span style="color: #ffffff; margin-right: 5px">*</span>
          </div>
          <app-promotional-language-select
            formControlName="language"
          ></app-promotional-language-select>
        </span>
      </div>
      <div class="input">
        <span class="name">
          <div class="title">
            <!-- <span style="color: #e60023; margin-right: 5px">*</span> -->
            <span>{{ "pageAffiliateForm.ContactLabel" | translate }}</span>
            <span style="color: #ffffff; margin-right: 5px">*</span>
          </div>
          <div class="contact">
            <app-contact-information-input
              formControlName="application"
            ></app-contact-information-input>
            <app-input
              [placeholder]="'pageAffiliateForm.PlaceholderContact' | translate"
              formControlName="tell"
            ></app-input>
          </div>
        </span>
      </div>
      <div class="input">
        <span class="name">
          <div class="title">
            {{ "pageAffiliateForm.PaymentLabel" | translate }}
          </div>
          <app-payment-checkbox
            formControlName="payment"
          ></app-payment-checkbox>
        </span>
      </div>
      <div class="input">
        <span class="name">
          <div class="title">
            {{ "pageAffiliateForm.InviteeLabel" | translate }}
          </div>
          <app-input
            [placeholder]="'pageAffiliateForm.PlaceholderCode' | translate"
            formControlName="invitee"
          ></app-input>
        </span>
      </div>
    </form>
    <app-error *ngIf="error">{{
      "pageAffiliateForm.Error" | translate
    }}</app-error>
    <app-button
      color="#602369"
      fontColor="#ffffff"
      fontSize="18px"
      width="380px"
      height="48px"
      (click)="send()"
      [loading]="loading"
    >
      {{ "button.SubmitNow" | translate }}
    </app-button>
  </mat-card>
</div>
