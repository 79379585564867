import { Injectable, signal } from '@angular/core';
import { UserApiService } from '../user-api.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  // data: any;
  // constructor() {}
  // observer = new Subject();
  // public subscribe$ = this.observer.asObservable();
  // set(data: any) {
  //   this.data = data;
  //   this.observer.next(data);
  // }
  self = signal<any>(null);
  constructor(private api: UserApiService) {}
  set(d: any) {
    this.self.set(d);
  }
  async getSelf() {
    try {
      const r = await this.api.getSelf();
      this.self.set(r.data);
    } catch (error) {}
    return this.self;
  }
}
