import {Component, OnInit} from '@angular/core'

@Component({
    selector: 'app-team-carousel',
    templateUrl: './team-carousel.component.html',
    styleUrls: ['./team-carousel.component.scss']
})
export class TeamCarouselComponent implements OnInit{
    reviewData = [
        {
            imageSrc: '../../../../assets/images/about-us/team-member-1.png',
            name: 'James Smith',
            position: 'CEO',
            backgroundColor: 'linear-gradient(to right, #379363, #D47C23)',
        },
        {
            imageSrc: '../../../../assets/images/about-us/team-member-2.png',
            name: 'Charlotte Brown',
            position: 'CHO',
            backgroundColor: 'linear-gradient(to right, #B13DF2, #42B5FC)',
        },
        {
            imageSrc: '../../../../assets/images/about-us/team-member-3.png',
            name: 'Oliver Johnson',
            position: 'CTO',
            backgroundColor: 'linear-gradient(to right, #FFA743, #FD584A)',
        },
        {
            imageSrc: '../../../../assets/images/about-us/team-member-4.png',
            name: 'Emma Watson',
            position: 'CMO',
            backgroundColor: 'linear-gradient(to right, #E74CAE, #593FF1)',
        }
    ]


    activeIndex: number = 0;
    nextIndex: number = 0;
    next2Index: number = 0;
    next3Index: number = 0;

    constructor(){

    }
    ngOnInit() {
        this.showSlide(this.activeIndex);
    }

    showSlide(index: number){
        this.activeIndex = index
        this.prevOrNextIndex();
    }

    nextSlide() {
        this.activeIndex = (this.activeIndex + 1) % this.reviewData.length;
        this.prevOrNextIndex();
    }

    prevSlide() {
        this.activeIndex =
            (this.activeIndex - 1 + this.reviewData.length) % this.reviewData.length;
        this.prevOrNextIndex();
    }

    toSlide(i: number) {
        this.activeIndex = i;
        this.prevOrNextIndex();
    }

    prevOrNextIndex() {
        this.nextIndex = (this.activeIndex + 1) % this.reviewData.length;

        this.next2Index = (this.activeIndex + 2) % this.reviewData.length;
        
        this.next3Index = (this.activeIndex + 3) % this.reviewData.length;

        
    }

}