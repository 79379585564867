<div
  class="carousel-container"
  (swipeleft)="nextSlide()"
  (swiperight)="prevSlide()"
>
  <span (click)="prevSlide()" class="zuojiantou"
    ><img
      alt="arrow"
      width="25"
      height="25"
      src="../../../../assets/images/index/Icon-arrow-card-left.svg"
  /></span>
  <div
    class="carousel-slide"
    *ngFor="let data of reviewData; let i = index"
    [ngClass]="{
      active: i === activeIndex,
      prev: i === prevIndex,
      next: i === nextIndex,
      preprev: i === preprevIndex,
      nextnext: i === nextnextIndex,
      prepreprev: i === prepreprevIndex,
      nextnextnext: i === nextnextnextIndex
    }"
  >
    <app-user-review-card [data]="data"></app-user-review-card>
  </div>
  <span (click)="nextSlide()" class="youjiantou"
    ><img
      alt="arrow"
      width="25"
      height="25"
      src="../../../../assets/images/index/Icon-arrow-card-right.svg"
  /></span>
</div>
<div class="carousel-footer">
  <span
    *ngFor="let data of reviewData; let i = index"
    (click)="toSlide(i)"
    [ngClass]="{ active: i === activeIndex }"
  >
  </span>
</div>
