import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class DealerApiService {
  constructor(private http: HttpService) {}

  buildurl(path: string, queryMap: any = null): string {
    return this.http.buildurl('/dealer' + path, queryMap);
  }

  findPlatforms() {
    const url = this.buildurl('/promotion');
    return this.http.fget(url);
  }

  findPayments() {
    const url = this.buildurl('/payment');
    return this.http.fget(url);
  }

  findContacts() {
    const url = this.buildurl('/contact');
    return this.http.fget(url);
  }

  sendDealerInfo(
    name: string,
    whats_app: string, // 区号
    phone: string, // 电话号码
    currency: string,
    language: string,
    code: string,
    promotion: any,
    payment_method_id: any,
    contact_id: number, // 应用程序id
    context: string // 应用程序联系方式
  ) {
    const url = this.buildurl('/subinfo');
    const body = {
      name,
      whats_app,
      phone,
      currency,
      language,
      code,
      promotion,
      payment_method_id,
      contact_id,
      context,
    };
    return this.http.post(url, body);
  }
}
