import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AffiliateOrderDetailDialogComponent } from '../affiliate-order-detail-dialog/affiliate-order-detail-dialog.component';
import { UserApiService } from 'src/app/service/user-api.service';

@Component({
  selector: 'app-affiliate-order-list',
  templateUrl: './affiliate-order-list.component.html',
  styleUrls: ['./affiliate-order-list.component.scss'],
})
export class AffiliateOrderListComponent implements OnInit {
  page = 1;
  pageSize = 5;
  total = 0;
  orders: any[] = [];
  @Input() typelist = 0;

  isSmall = false;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    private api: UserApiService
  ) {
    this.isSmall = this.breakpointObserver.isMatched([
      Breakpoints.XSmall,
      Breakpoints.Small,
    ]);
  }

  ngOnInit(): void {
    this.findAffiliateOrderList();
  }

  onPageChange(e: any) {
    this.page = e;
    this.findAffiliateOrderList();
  }

  loading = false;
  async findAffiliateOrderList() {
    try {
      this.loading = true;
      const r = await this.api.getAffiliateCommissionList(
        this.typelist,
        this.page,
        this.pageSize
      );
      this.orders = r.data.list;
      this.page = r.data.page;
      this.pageSize = r.data.page_size;
      this.total = r.data.total;
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }

  checkDetail() {
    this.dialog.open(AffiliateOrderDetailDialogComponent, {
      data: this.orders,
    });
  }
}
