import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { RegionApiService } from 'src/app/service/region-api.service';
import { UserApiService } from 'src/app/service/user-api.service';

@Component({
  selector: 'app-affiliate-avert-page',
  templateUrl: './affiliate-avert-page.component.html',
  styleUrls: ['./affiliate-avert-page.component.scss'],
})
export class AffiliateAvertPageComponent implements OnInit {
  isSmall: any;
  self: any = null;
  isMobile:boolean = false;

  constructor(
    private title: Title,
    private meta: Meta,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private api: UserApiService,
    private apiRegion: RegionApiService
  ) {
    this.isSmall = this.breakpointObserver.isMatched([
      Breakpoints.XSmall,
      Breakpoints.Small,
    ]);
    if (this.isSmall) {
      this.title.setTitle('PremLogin');
    } else {
      this.title.setTitle('Affiliate - PremLogin');
    }

    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
      .subscribe(result => {
        this.isMobile = result.matches
      })
    this.meta.updateTag({
      name: 'description',
      content: 'Affiliate. View cooperation information.',
    });
  }

  ngOnInit(): void {
    this.getSelf();
    this.getIpRegion();
  }

  async getSelf() {
    try {
      const r = await this.api.getSelf();
      this.self = r.data;
    } catch (error) {}
  }

  goto() {
    if (!this.self) {
      this.router.navigate(['/login']);
    } else {
      if (this.self.affiliate.is_partner) {
        this.router.navigate(['/affiliate/dashboard']);
      } else this.router.navigate(['/affiliate/form']);
    }
  }

  region: any;
  async getIpRegion() {
    try {
      const r = await this.apiRegion.getIpRegion();
      this.region = r.data;
    } catch (error) {}
  }
}
