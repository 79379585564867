<div class="container">
  <div class="arc"></div>
  <div class="title">
    <h2>
      {{ "pageHome.WhyChoose" | translate }}
      <span>
        {{ "pageHome.Premlogin" | translate }}
      </span>
    </h2>
  </div>
  <div class="content">
    <app-selling-point-item
      *ngFor="let data of points"
      [alt]="data.alt"
      [image]="data.image"
      [title]="data.title"
      [content]="data.content"
      [button]="data.templateRef"
    >
    </app-selling-point-item>
  </div>
</div>
