import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class WorkorderApiService {
  constructor(private http: HttpService) {}

  buildurl(path: string, queryMap: any = null): string {
    return this.http.buildurl('/workorder' + path, queryMap);
  }

  postWordOrder(
    busSeatID: number,
    issueType: string,
    message: string,
    sender: string
  ) {
    const url = this.buildurl('/workorder');
    const body = {
      bus_seat_id: busSeatID,
      issue_type: issueType,
      message,
      sender,
    };
    return this.http.post(url, body);
  }

  findSeatInfo() {
    const url = this.buildurl('/getseatinfo');
    return this.http.fget(url);
  }

  findProblem(platformName: string, la: string) {
    const q = { platform_name: platformName, la };
    const url = this.buildurl('/get/problem', q);
    return this.http.fget(url);
  }

  findTicket(account: string) {
    const url = this.buildurl('/get/workorder', { account });
    return this.http.fget(url);
  }

  getTicket(id: number) {
    const url = this.buildurl(`/getdialogs/${id}`);
    return this.http.fget(url);
  }

  closeTicket(id: number) {
    const url = this.buildurl(`/completion/${id}`);
    return this.http.fget(url);
  }

  postUserreply(id: number, message: string) {
    const url = this.buildurl(`/userreply/${id}`);
    const body = {
      message,
    };
    return this.http.post(url, body);
  }
}
