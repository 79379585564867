import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(
    httpClient,
    '/assets/i18n/',
    '.json?v=' + environment.timestamp
  );
}

import { MaterialModule } from './material.module';
import { PageHeaderComponent } from './components/header/page-header/page-header.component';
import { PageFooterComponent } from './components/footer/page-footer/page-footer.component';
import { PageWrapperComponent } from './pages/page-wrapper/page-wrapper.component';
import { UserAvatarWithMenuComponent } from './components/header/user-avatar-with-menu/user-avatar-with-menu.component';
import { PageHeaderLinkButtonComponent } from './components/header/page-header-link-button/page-header-link-button.component';
import { CookieConsentModalComponent } from './components/cookie-consent-modal/cookie-consent-modal.component';
import { UserAvatarComponent } from './components/base/user-avatar/user-avatar.component';
import { PlatformListComponent } from './components/index/platform-list/platform-list.component';
import { IndexPageComponent } from './pages/index-page/index-page.component';
import { SpinnerComponent } from './components/base/spinner/spinner.component';
import { PlatformCardComponent } from './components/index/platform-card/platform-card.component';
import { ImageComponent } from './components/base/image/image.component';
import { DurationPriceComponent } from './components/base/duration-price/duration-price.component';
import { UserNumberComponent } from './components/index/user-number/user-number.component';
import { SellingPointItemComponent } from './components/index/selling-point-item/selling-point-item.component';
import { UserReviewCardComponent } from './components/index/user-review-card/user-review-card.component';
import { UserProfileMenuButtonComponent } from './components/header/user-profile-menu-button/user-profile-menu-button.component';
import { UserProfileMenuSpanComponent } from './components/header/user-profile-menu-span/user-profile-menu-span.component';
import { ButtonComponent } from './components/base/button/button.component';
import { HelpPageComponent } from './pages/help-page/help-page.component';
import { PlatformModalComponent } from './components/index/platform-modal/platform-modal.component';
import { PromoCodeModalComponent } from './components/index/promo-code-modal/promo-code-modal.component';
import { InputComponent } from './components/base/input/input.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { UserProfilePageComponent } from './pages/user-profile-page/user-profile-page.component';
import { UpdateUserPasswordPageComponent } from './pages/update-user-password-page/update-user-password-page.component';
import { UpdateUserEmailPageComponent } from './pages/update-user-email-page/update-user-email-page.component';
import { NoBorderInputComponent } from './components/base/no-border-input/no-border-input.component';
import { TipComponent } from './components/base/tip/tip.component';
import { LoginOrSignUpCardComponent } from './components/login/login-or-sign-up-card/login-or-sign-up-card.component';
import { SetUpPasswordCardComponent } from './components/login/set-up-password-card/set-up-password-card.component';
import { LoginWithPasswordCardComponent } from './components/login/login-with-password-card/login-with-password-card.component';
import { LoginWithVerificationCardComponent } from './components/login/login-with-verification-card/login-with-verification-card.component';
import { PaymentSelectModalComponent } from './components/index/payment-select-modal/payment-select-modal.component';
import { WaitingForPurchaseCompleteModalComponent } from './components/index/waiting-for-purchase-complete-modal/waiting-for-purchase-complete-modal.component';
import { TimelineComponent } from './components/about-us/timeline/timeline.component';
import { HowWorkCardComponent } from './components/affiliate/how-work-card/how-work-card.component';
import { HowWorkPanelComponent } from './components/affiliate/how-work-panel/how-work-panel.component';
// import { AffiliatePartnerCardComponent } from './components/affiliate/affiliate-partner-card/affiliate-partner-card.component';
import { AffiliatePartnerPanelComponent } from './components/affiliate/affiliate-partner-panel/affiliate-partner-panel.component';
import { AffiliateOrderListCardComponent } from './components/affiliate/affiliate-order-list-card/affiliate-order-list-card.component';
import { AffiliateOrderListCardsComponent } from './components/affiliate/affiliate-order-list-cards/affiliate-order-list-cards.component';
import { WithdrawalListCardComponent } from './components/withdraw/withdrawal-list-card/withdrawal-list-card.component';
import { WithdrawalListCardsComponent } from './components/withdraw/withdrawal-list-cards/withdrawal-list-cards.component';
import { NetflixCodeModalComponent } from './components/subscription/netflix-code-modal/netflix-code-modal.component';
import { RefreshNetflixCodeModalComponent } from './components/subscription/refresh-netflix-code-modal/refresh-netflix-code-modal.component';
import { TipNetflixCodeModalComponent } from './components/subscription/tip-netflix-code-modal/tip-netflix-code-modal.component';
import { CreateNewTicketModalComponent } from './components/help/create-new-ticket-modal/create-new-ticket-modal.component';
import { IssueCategorySelectComponent } from './components/help/issue-category-select/issue-category-select.component';
import { SubscriptionSelectComponent } from './components/help/subscription-select/subscription-select.component';
import { TicketCardComponent } from './components/help/ticket-card/ticket-card.component';
import { TicketCardSearchComponent } from './components/help/ticket-card-search/ticket-card-search.component';
// import { CkeditorComponent } from './components/base/ckeditor/ckeditor.component';

import {
  SocialLoginModule,
  SocialAuthServiceConfig,
  GoogleSigninButtonModule,
} from '@abacritt/angularx-social-login';
import { GoogleLoginProvider } from '@abacritt/angularx-social-login';
import { EmailSignUpCardComponent } from './components/login/email-sign-up-card/email-sign-up-card.component';
import { ErrorComponent } from './components/base/error/error.component';
import {
  RECAPTCHA_BASE_URL,
  RECAPTCHA_V3_SITE_KEY,
  RecaptchaV3Module,
} from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { SendCodeButtonComponent } from './components/base/send-code-button/send-code-button.component';
import { InputIconComponent } from './components/base/input-icon/input-icon.component';
import { DiscountMoneyComponent } from './components/index/discount-money/discount-money.component';
import { MoneyComponent } from './components/base/money/money.component';
import { NgxPayPalModule } from 'ngx-paypal';
import { SubscriptionPageComponent } from './pages/subscription-page/subscription-page.component';
import { BusSeatCardComponent } from './components/subscription/bus-seat-card/bus-seat-card.component';
import { DatetimeComponent } from './components/base/datetime/datetime.component';
import { BusSeatStatusComponent } from './components/subscription/bus-seat-status/bus-seat-status.component';
import { AccountPasswordComponent } from './components/base/account-password/account-password.component';
import { CopyableTextComponent } from './components/base/copyable-text/copyable-text.component';
import { AccountComponent } from './components/base/account/account.component';
import { RenewCheckModalComponent } from './components/subscription/renew-check-modal/renew-check-modal.component';
import { OrderListPageComponent } from './pages/order-list-page/order-list-page.component';
import { UserOrderListComponent } from './components/order/user-order-list/user-order-list.component';
import { UserOrderStatusComponent } from './components/order/user-order-status/user-order-status.component';
import { RefundUserOrderModalComponent } from './components/order/refund-user-order-modal/refund-user-order-modal.component';
import { AffiliatePageComponent } from './pages/affiliate-page/affiliate-page.component';
import { CommissionStatusComponent } from './components/affiliate/commission-status/commission-status.component';
import { WithdrawPageComponent } from './pages/withdraw-page/withdraw-page.component';
import { SelectComponent } from './components/base/select/select.component';
import { CurrencySelectComponent } from './components/withdraw/currency-select/currency-select.component';
import { PaymentMethodComponent } from './components/withdraw/payment-method/payment-method.component';
import { G2LineComponent } from './components/base/g2-line/g2-line.component';
import { G2PieComponent } from './components/base/g2-pie/g2-pie.component';
import { OrdersPieComponent } from './components/affiliate/orders-pie/orders-pie.component';
import { OrdersLineComponent } from './components/affiliate/orders-line/orders-line.component';
import { RegionSelectComponent } from './components/footer/region-select/region-select.component';
import { CarouselComponent } from './components/base/carousel/carousel.component';
import { ContactUsPageComponent } from './pages/contact-us-page/contact-us-page.component';
import { RefundPolicyPageComponent } from './pages/refund-policy-page/refund-policy-page.component';
import { AboutUsPageComponent } from './pages/about-us-page/about-us-page.component';
import { TermsConditionPageComponent } from './pages/terms-condition-page/terms-condition-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { NoEmailModalComponent } from './components/login/no-email-modal/no-email-modal.component';
import { SellingPointPanelComponent } from './components/index/selling-point-panel/selling-point-panel.component';
import { UserReviewsPanelComponent } from './components/index/user-reviews-panel/user-reviews-panel.component';
import { UpdateUserNicknameModalComponent } from './components/user-profile/update-user-nickname-modal/update-user-nickname-modal.component';
import { UpdateUserAvatarModalComponent } from './components/user-profile/update-user-avatar-modal/update-user-avatar-modal.component';
import { PaginatorComponent } from './components/base/paginator/paginator.component';
import { WithdrawalListComponent } from './components/withdraw/withdrawal-list/withdrawal-list.component';
import { NoDataComponent } from './components/base/no-data/no-data.component';
import { AffiliateOrderListComponent } from './components/affiliate/affiliate-order-list/affiliate-order-list.component';
import { TotalEarnedCardComponent } from './components/affiliate/total-earned-card/total-earned-card.component';
import { ReferLinkCardComponent } from './components/affiliate/refer-link-card/refer-link-card.component';
import { LoginHeaderComponent } from './components/login/login-header/login-header.component';
import { LoginFooterComponent } from './components/login/login-footer/login-footer.component';
import { PrivacyPolicyPageComponent } from './pages/privacy-policy-page/privacy-policy-page.component';
import { LanguageSelectComponent } from './components/footer/language-select/language-select.component';
import { IndexTitleComponent } from './components/index/index-title/index-title.component';
import { PlatformDialogComponent } from './components/index/platform-dialog/platform-dialog.component';
import { PlatformBottomSheetComponent } from './components/index/platform-bottom-sheet/platform-bottom-sheet.component';
import { RefundUserOrderBottomSheetComponent } from './components/order/refund-user-order-bottom-sheet/refund-user-order-bottom-sheet.component';
import { RefundUserOrderDialogComponent } from './components/order/refund-user-order-dialog/refund-user-order-dialog.component';
import { RenewCheckDialogComponent } from './components/subscription/renew-check-dialog/renew-check-dialog.component';
import { RenewCheckBottomSheetComponent } from './components/subscription/renew-check-bottom-sheet/renew-check-bottom-sheet.component';
import { UpdateUserAvatarDialogComponent } from './components/user-profile/update-user-avatar-dialog/update-user-avatar-dialog.component';
import { UpdateUserAvatarBottomSheetComponent } from './components/user-profile/update-user-avatar-bottom-sheet/update-user-avatar-bottom-sheet.component';
import { UpdateUserNicknameBottomSheetComponent } from './components/user-profile/update-user-nickname-bottom-sheet/update-user-nickname-bottom-sheet.component';
import { UpdateUserNicknameDialogComponent } from './components/user-profile/update-user-nickname-dialog/update-user-nickname-dialog.component';
import { UnionCardComponent } from './components/affiliate/union-card/union-card.component';
import { ContactCardComponent } from './components/contact-us/contact-card/contact-card.component';
import { SendMessageCardComponent } from './components/contact-us/send-message-card/send-message-card.component';
import { CarouselImgComponent } from './components/footer/carousel-img/carousel-img.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ShareModalComponent } from './components/affiliate/share-modal/share-modal.component';
import { ShareDialogComponent } from './components/affiliate/share-dialog/share-dialog.component';
import { ShareBottomSheetComponent } from './components/affiliate/share-bottom-sheet/share-bottom-sheet.component';
import { ApiInterceptor } from './service/api.interceptor';
import { JoinGroupModalComponent } from './components/subscription/join-group-modal/join-group-modal.component';
import { CarpoolUsersModalComponent } from './components/subscription/carpool-users-modal/carpool-users-modal.component';
import { AfterEnterYoutubeModalComponent } from './components/subscription/after-enter-youtube-modal/after-enter-youtube-modal.component';
import { EnterInvitationLinkBottomSheetComponent } from './components/subscription/enter-invitation-link-bottom-sheet/enter-invitation-link-bottom-sheet.component';
import { EnterInvitationLinkDialogComponent } from './components/subscription/enter-invitation-link-dialog/enter-invitation-link-dialog.component';
import { EnterInvitationLinkModalComponent } from './components/subscription/enter-invitation-link-modal/enter-invitation-link-modal.component';
import { JoinGroupDialogComponent } from './components/subscription/join-group-dialog/join-group-dialog.component';
import { JoinGroupBottomSheetComponent } from './components/subscription/join-group-bottom-sheet/join-group-bottom-sheet.component';
import { ContactUsSendSuccessfulPageComponent } from './pages/contact-us-send-successful-page/contact-us-send-successful-page.component';
import { PayssionButtonComponent } from './components/index/payssion-button/payssion-button.component';
import { AffiliateAvertPageComponent } from './pages/affiliate-avert-page/affiliate-avert-page.component';
import { PaymentMethodSelectComponent } from './components/withdraw/payment-method-select/payment-method-select.component';
import { AfterWithdrawModalComponent } from './components/withdraw/after-withdraw-modal/after-withdraw-modal.component';
import { WithdrawalStatusComponent } from './components/withdraw/withdrawal-status/withdrawal-status.component';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { NormalCouponDialogComponent } from './components/coupon/normal-version/normal-coupon-dialog/normal-coupon-dialog.component';
import { NormalCouponIconComponent } from './components/coupon/normal-version/normal-coupon-icon/normal-coupon-icon.component';
import { SpecialCouponDialogComponent } from './components/coupon/special-version/special-coupon-dialog/special-coupon-dialog.component';
import { SpecialCouponIconComponent } from './components/coupon/special-version/special-coupon-icon/special-coupon-icon.component';
import { StepsModalComponent } from './components/index/steps-modal/steps-modal.component';
import { CommonModule } from '@angular/common';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { AffiliateOrderDetailDialogComponent } from './components/affiliate/affiliate-order-detail-dialog/affiliate-order-detail-dialog.component';
import { ImageUploaderComponent } from './components/contact-us/image-uploader/image-uploader.component';
import { AffiliateFormComponent } from './pages/affiliate-form/affiliate-form.component';
import { PromotionalLanguageSelectComponent } from './components/affiliate/promotional-language-select/promotional-language-select.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { CurrencyRadioGroupComponent } from './components/affiliate/currency-radio-group/currency-radio-group.component';
import { PromotionPlatformCheckboxComponent } from './components/affiliate/promotion-platform-checkbox/promotion-platform-checkbox.component';
import { PaymentCheckboxComponent } from './components/affiliate/payment-checkbox/payment-checkbox.component';
import { ContactInformationInputComponent } from './components/affiliate/contact-information-input/contact-information-input.component';
import { AffiliateFormSuccessfulDialogComponent } from './components/affiliate/affiliate-form-successful-dialog/affiliate-form-successful-dialog.component';
import { CompanyPageComponent } from './pages/company-page/company-page.component';
import { StartupScreenComponent } from './startup-screen/startup-screen.component';
import { TeamCarouselComponent } from './components/about-us/team-carousel/team-carousel.component';
import { TeamMemberCardComponent } from './components/about-us/team-member-card/team-member-card.component';
import { ContactUsPanelComponent } from './components/about-us/contact-us-panel/contact-us-panel.component';
import { ContactUsCardComponent } from './components/about-us/contact-us-card/contact-us-card.component';
import { PartnerPanelComponent } from './components/about-us/partner-panel/partner-panel.component';
import { PartnerCardComponent } from './components/about-us/partner-card/partner-card.component';
import { HollowButtonComponent } from './components/base/hollow-button/hollow-button.component';
import { LoginInputIconComponent } from './components/base/login-input-icon/login-input-icon.component';
import { CarpoolImageComponent } from './components/base/carpool-image/carpool-image.component';
import { SendUpdateCodeButtonComponent } from './components/base/send-update-code-button/send-update-code-button.component';
import { BeAffiliateCardComponent } from './components/header/be-affiliate-card/be-affiliate-card.component';
import { QuillEditorComponent } from './components/base/quill-editor/quill-editor.component';
import { HelpTicketPageComponent } from './pages/help-ticket-page/help-ticket-page.component';

@NgModule({
  declarations: [
    AppComponent,
    PageHeaderComponent,
    PageFooterComponent,
    PageWrapperComponent,
    UserAvatarWithMenuComponent,
    PageHeaderLinkButtonComponent,
    SellingPointPanelComponent,
    CookieConsentModalComponent,
    UserAvatarComponent,
    PlatformListComponent,
    IndexPageComponent,
    SpinnerComponent,
    PlatformCardComponent,
    ImageComponent,
    DurationPriceComponent,
    UserNumberComponent,
    SellingPointItemComponent,
    UserReviewsPanelComponent,
    UserReviewCardComponent,
    UserProfileMenuButtonComponent,
    UserProfileMenuSpanComponent,
    ButtonComponent,
    HelpPageComponent,
    PlatformModalComponent,
    PromoCodeModalComponent,
    InputComponent,
    LoginPageComponent,
    UserProfilePageComponent,
    UpdateUserPasswordPageComponent,
    UpdateUserEmailPageComponent,
    NoBorderInputComponent,
    TipComponent,
    UpdateUserNicknameModalComponent,
    UpdateUserAvatarModalComponent,
    InputIconComponent,
    LoginPageComponent,
    LoginOrSignUpCardComponent,
    SetUpPasswordCardComponent,
    LoginWithPasswordCardComponent,
    LoginWithVerificationCardComponent,
    PaymentSelectModalComponent,
    WaitingForPurchaseCompleteModalComponent,
    EmailSignUpCardComponent,
    ErrorComponent,
    SendCodeButtonComponent,
    DiscountMoneyComponent,
    MoneyComponent,
    SubscriptionPageComponent,
    BusSeatCardComponent,
    DatetimeComponent,
    BusSeatStatusComponent,
    AccountPasswordComponent,
    CopyableTextComponent,
    AccountComponent,
    AccountPasswordComponent,
    RenewCheckModalComponent,
    OrderListPageComponent,
    UserOrderListComponent,
    UserOrderStatusComponent,
    RefundUserOrderModalComponent,
    AffiliatePageComponent,
    CommissionStatusComponent,
    WithdrawPageComponent,
    SelectComponent,
    CurrencySelectComponent,
    PaymentMethodComponent,
    G2LineComponent,
    G2PieComponent,
    OrdersPieComponent,
    OrdersLineComponent,
    RegionSelectComponent,
    CarouselComponent,
    ContactUsPageComponent,
    RefundPolicyPageComponent,
    AboutUsPageComponent,
    TermsConditionPageComponent,
    NotFoundPageComponent,
    PrivacyPolicyPageComponent,
    NoEmailModalComponent,
    PaginatorComponent,
    WithdrawalListComponent,
    NoDataComponent,
    AffiliateOrderListComponent,
    TotalEarnedCardComponent,
    ReferLinkCardComponent,
    LoginHeaderComponent,
    LoginFooterComponent,
    LanguageSelectComponent,
    IndexTitleComponent,
    PlatformDialogComponent,
    PlatformBottomSheetComponent,
    RefundUserOrderBottomSheetComponent,
    RefundUserOrderDialogComponent,
    RenewCheckDialogComponent,
    RenewCheckBottomSheetComponent,
    UpdateUserAvatarDialogComponent,
    UpdateUserAvatarBottomSheetComponent,
    UpdateUserNicknameBottomSheetComponent,
    UpdateUserNicknameDialogComponent,
    UnionCardComponent,
    ContactCardComponent,
    SendMessageCardComponent,
    CarouselImgComponent,
    ShareModalComponent,
    ShareDialogComponent,
    ShareBottomSheetComponent,
    JoinGroupModalComponent,
    CarpoolUsersModalComponent,
    AfterEnterYoutubeModalComponent,
    EnterInvitationLinkBottomSheetComponent,
    EnterInvitationLinkDialogComponent,
    EnterInvitationLinkModalComponent,
    JoinGroupDialogComponent,
    JoinGroupBottomSheetComponent,
    ContactUsSendSuccessfulPageComponent,
    PayssionButtonComponent,
    AffiliateAvertPageComponent,
    PaymentMethodSelectComponent,
    AfterWithdrawModalComponent,
    WithdrawalStatusComponent,
    NormalCouponDialogComponent,
    NormalCouponIconComponent,
    SpecialCouponDialogComponent,
    SpecialCouponIconComponent,
    StepsModalComponent,
    LandingPageComponent,
    AffiliateOrderDetailDialogComponent,
    ImageUploaderComponent,
    AffiliateFormComponent,
    PromotionalLanguageSelectComponent,
    CurrencyRadioGroupComponent,
    PromotionPlatformCheckboxComponent,
    PaymentCheckboxComponent,
    ContactInformationInputComponent,
    ImageComponent,
    AffiliateFormSuccessfulDialogComponent,
    CompanyPageComponent,
    StartupScreenComponent,
    TimelineComponent,
    TeamCarouselComponent,
    TeamMemberCardComponent,
    ContactUsPanelComponent,
    ContactUsCardComponent,
    PartnerPanelComponent,
    PartnerCardComponent,

    
    HollowButtonComponent,
    LoginInputIconComponent,
    CarpoolImageComponent,
    SendUpdateCodeButtonComponent,
    HowWorkCardComponent,
    HowWorkPanelComponent,
    // AffiliatePartnerCardComponent,
    AffiliatePartnerPanelComponent,
    AffiliateOrderListCardComponent,
    AffiliateOrderListCardsComponent,
    WithdrawalListCardComponent,
    WithdrawalListCardsComponent,
    BeAffiliateCardComponent,
    NetflixCodeModalComponent,
    RefreshNetflixCodeModalComponent,
    TipNetflixCodeModalComponent,
    CreateNewTicketModalComponent,
    IssueCategorySelectComponent,
    SubscriptionSelectComponent,
    TicketCardComponent,
    TicketCardSearchComponent,
    QuillEditorComponent,
    HelpTicketPageComponent,
    // CkeditorComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    MaterialModule,
    SocialLoginModule,
    GoogleSigninButtonModule,
    RecaptchaV3Module,
    NgxPayPalModule,
    NgOtpInputModule,
    QRCodeModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    CommonModule,
    NgxIntlTelInputModule,
    QuillModule.forRoot(
      {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'], // 按钮
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['image'],
            ['clean'],  // 清除格式按钮
            
          ]
        },
        theme: 'snow' // 或者 'bubble'
      }
    ) // 配置 Quill 模块

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleOAuth2ClientID),
          },
        ],
        onError: (err) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.googleReCaptchaSiteKey,
    },
    {
      provide: RECAPTCHA_BASE_URL,
      useValue: 'https://recaptcha.net/recaptcha/api.js', // use recaptcha.net script source for some of our users
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
