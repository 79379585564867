<div class="point">
  <img [alt]="alt" [src]="image" height="90" />

  <div class="description">
    <div class="title">{{ title }}</div>
    <div class="content">{{ content }}</div>

    <span *ngIf="button" routerLink="/refundpolicy"
      >{{ "pageFooter.RefundPolicy" | translate }}></span
    >
  </div>
</div>
<!-- <div class="space"></div> -->
