import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Meta, Title } from '@angular/platform-browser';
import { Crisp } from 'crisp-sdk-web';
import { UpdateUserAvatarModalComponent } from 'src/app/components/user-profile/update-user-avatar-modal/update-user-avatar-modal.component';
import { UpdateUserNicknameModalComponent } from 'src/app/components/user-profile/update-user-nickname-modal/update-user-nickname-modal.component';
import { DialogService } from 'src/app/service/dialog.service';
import { FileApiService } from 'src/app/service/file-api.service';
import { UserService } from 'src/app/service/store/user.service';
import { UserApiService } from 'src/app/service/user-api.service';
import { MessageService } from 'src/app/service/message.service';

import { concatMapTo } from 'rxjs';

@Component({
  selector: 'app-user-profile-page',
  templateUrl: './user-profile-page.component.html',
  styleUrls: ['./user-profile-page.component.scss'],
})
export class UserProfilePageComponent implements OnInit {
  formGroup: UntypedFormGroup;

  self: any = null;
  isSmall: any;

  InputDisabled: boolean = true; // 默认禁用
  errorText = false;
  textMessage: any;




  constructor(
    private fb: UntypedFormBuilder,
    private message: MessageService,
    private myDialog: DialogService,
    private api: UserApiService,
    private userService: UserService,
    private fileapi: FileApiService,
    private title: Title,
    private meta: Meta,
    private breakpointObserver: BreakpointObserver,
    private translateService: TranslateService

  ) {
      this.formGroup = this.fb.group({
        nickname: ['', [Validators.required]],
      });
      this.isSmall = this.breakpointObserver.isMatched([
      Breakpoints.XSmall,
      Breakpoints.Small,
    ]);
    if (this.isSmall) {
      this.title.setTitle('PremLogin');
    } else {
      this.title.setTitle('User Profile - PremLogin');
    }

    this.meta.updateTag({
      name: 'description',
      content: 'User profile. View and change user avatar, nickname and email.',
    });
  }

  ngOnInit(): void {
    this.translateService.get('textMessage').subscribe((text: string) => {
      this.textMessage = text;
    });
    this.getSelf();
  }

  async getSelf() {
    try {
      const r = await this.api.getSelf();
      this.self = r.data;
      this.formGroup.patchValue({
        nickname: this.self.nickname,
      });      this.userService.set(r.data);
    } catch (error) {}
  }

  updateNickname() {
    const that = this;
    this.myDialog.open(
      UpdateUserNicknameModalComponent,
      {
        data: this.self,
        width: '694px',
      },
      function callback(r: any) {
        if (r) {
          that.self.nickname = r.nickname;
          that.userService.set(r);
          Crisp.user.setNickname(r.nickname);
        }
      }
    );
  }

  // 新的更新方法
  loading = false;
  async apply() {
    let input = this.formGroup.value.nickname;
    if (!input) {
      this.errorText = true;
      return;
    }
    try {
      this.loading = true;
      const r = await this.api.updateNickname(input);
      if (r.status !== 0) {
        this.message.warn(this.textMessage.Unknown);
      } else {
        this.message.info(this.textMessage.NicknameUpdated);
      }
      this.self.nickname = r.nickname;
      this.userService.set(r);
      Crisp.user.setNickname(r.nickname);
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }

  updateAvatar() {
    const that = this;
    console.log(this.formGroup.value.nickname)
    this.myDialog.open(
      UpdateUserAvatarModalComponent,
      {
        width: '694px',
        data: this.self,
      },
      function callback(r: any) {
        if (r) {
          that.self.avatar = r.avatar;
          that.userService.set(r);
          Crisp.user.setAvatar(
            'https://premlogin.com' + that.fileapi.fileurl(r.avatar)
          );
        }
      }
    );
  }
}
