import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
import * as Hammer from 'hammerjs';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit, OnDestroy {
  reviewData = [
    {
      nickname: 'Michael Smith',
      avatar: '../../../../assets/images/index/Michael-Smith.png',
      region: 'USA',
      content:
        "I absolutely love purchasing through PremLogin! The service saves money and is totally worth it. I highly recommend it, 10/10!",
      start: 5,
      time: 'Nine Months+',
    },
    {
      nickname: '박현주',
      avatar: '../../../../assets/images/index/avatar2.png',
      region: 'South Korea',
      content:
        "PremLogin 서비스에 매우 만족합니다! 사용 경험이 매끄럽고, 가격과 품질 모두 뛰어나서 강력 추천합니다!",
      start: 5,
      time: 'A Year+',
    },
    {
      nickname: 'Antoine Dubois',
      avatar: '../../../../assets/images/index/avatar3.png',
      region: 'France',
      content:
        "Le prix est extrêmement abordable ! Il suffit de passer commande et de fournir mon adresse e-mail pour l'activer, PremLogin est un excellent service.",
      start: 4.5,
      time: 'Seven Months+',
    },
    {
      nickname: 'Juanvi carrasco',
      avatar: '../../../../assets/images/index/avatar4.png',
      region: 'Spain',
      content:
        'Me encanta comprar a través de PremLogin. Ahorra dinero y merece totalmente la pena.',
      start: 5,
      time: 'Two Months+',
    },
    {
      nickname: 'Adam Chervulri',
      avatar: '../../../../assets/images/index/avatar5.png',
      region: 'Morocco',
      content:
        'مع PremLogin، يمكنني الاستمتاع بالخدمات المتميزة دون أن أنفق الكثير من المال. إنه مريح للغاية',
      start: 4,
      time: 'Five Months+',
    },
    {
      nickname: 'Ragazzi Phyre',
      avatar: '../../../../assets/images/index/avatar6.png',
      region: 'Italy',
      content:
        "Uso PremLogin da un po' di tempo e sono entusiasta del loro servizio! L'account funziona a meraviglia.",
      start: 5,
      time: 'A Year+',
    },
    {
      nickname: '相沢ありさ',
      avatar: '../../../../assets/images/index/avatar7.png',
      region: 'Japan',
      content:
        "予算が限られている大学生...PremLoginは、超お手頃価格で優れたサービスを提供してくれる！",
      start: 5,
      time: 'Five Months+',
    },
  ];

  activeIndex: number = 0;
  prevIndex: number = 0;
  nextIndex: number = 0;
  preprevIndex: number = 0;
  nextnextIndex: number = 0;
  prepreprevIndex: number = 0;
  nextnextnextIndex: number = 0;

  interval: any;

  constructor(private elementRef: ElementRef) {
    this.interval = setInterval(() => {
      this.nextSlide();
    }, 10000);
  }

  ngOnInit() {
    this.showSlide(this.activeIndex);
    this.setupSwipeGesture();
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  showSlide(index: number) {
    this.activeIndex = index;
    this.prevOrNextIndex();
  }

  nextSlide() {
    this.activeIndex = (this.activeIndex + 1) % this.reviewData.length;
    this.prevOrNextIndex();
  }

  prevSlide() {
    this.activeIndex =
      (this.activeIndex - 1 + this.reviewData.length) % this.reviewData.length;
    this.prevOrNextIndex();
  }

  setupSwipeGesture(): void {
    const element = this.elementRef.nativeElement.querySelector(
      '.carousel-container'
    );
    const hammer = new Hammer(element);

    hammer.on('swipeleft', () => {
      this.nextSlide();
    });

    hammer.on('swiperight', () => {
      this.prevSlide();
    });
  }

  toSlide(i: number) {
    this.activeIndex = i;
    this.prevOrNextIndex();
  }

  prevOrNextIndex() {
    this.prevIndex =
      this.activeIndex === 0
        ? this.reviewData.length - 1
        : this.activeIndex - 1;
    this.nextIndex =
      this.activeIndex === this.reviewData.length - 1
        ? 0
        : this.activeIndex + 1;

    this.preprevIndex =
      this.prevIndex === 0 ? this.reviewData.length - 1 : this.prevIndex - 1;

    this.nextnextIndex =
      this.activeIndex === this.reviewData.length - 2 ? 0 : this.nextIndex + 1;

    this.prepreprevIndex =
      this.preprevIndex === 0
        ? this.reviewData.length - 1
        : this.preprevIndex - 1;

    this.nextnextnextIndex =
      this.activeIndex === this.reviewData.length - 3
        ? 0
        : this.nextnextIndex + 1;
  }
}
