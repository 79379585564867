<mat-toolbar>
  <div class="icon" routerLink="/">
    <img
      alt="logo"
      height="34"
      src="../../../../assets/images/base/logo-min.svg"
    />
  </div>

  <div class="space"></div>

  <div class="language" [matMenuTriggerFor]="menu">
    <img
      class="language"
      alt="language"
      width="20"
      height="20"
      src="../../../../assets/images/base/Icon-world.svg"
    />
    <span>{{ "base.Language" | translate }}</span>
    <img
      class="arrow"
      alt="arrow"
      width="20"
      height="20"
      src="../../../../assets/images/login/Icon-arrow-down.svg"
    />
  </div>
</mat-toolbar>
<!-- <mat-toolbar [ngStyle]="{ 'background-color': backgroundColor }">
  <div class="container">
    <div style="width: 100%">
      <div class="icon" routerLink="/">
        <img
          alt="logo"
          width=auto
          height="34"
          src="../../../../assets/images/base/logo-min.svg" 
        />
      </div>



      <div class="menu">
        <ng-container *ngIf="!isSmall">
          <app-page-header-link-button url="/">{{
            "pageHeader.Home" | translate
          }}</app-page-header-link-button>

          <app-page-header-link-button url="/affiliate">{{
            "pageHeader.Affiliate" | translate
          }}</app-page-header-link-button>

          <app-page-header-link-button url="/help">
            {{ "pageHeader.HelpSupport" | translate }}
          </app-page-header-link-button>
        </ng-container>

        <app-page-header-link-button
          url="/subscription"
          [ngClass]="{ small: isSmall }"
        >
          {{ "pageHeader.Subscription" | translate }}
        </app-page-header-link-button>

        
      </div>
      <span class="spacer"></span>
      <app-user-avatar-with-menu></app-user-avatar-with-menu>
    </div>
  </div>
</mat-toolbar> -->


<mat-menu #menu="matMenu">
  <button
    mat-menu-item
    *ngFor="let language of languages"
    (click)="switchLanguage(language.code)"
  >
    {{ language.name }}
  </button>
</mat-menu>
