<div class="paginator">
  <button
    mat-button
    class="prevBtn"
    (click)="Previous()"
    [disabled]="currentPage === 1 ? true : false"
    style="padding-right: 15px"
  >
    <mat-icon>navigate_before</mat-icon>
    Previous
  </button>

  <button
    mat-icon-button
    class="iconBtn"
    (click)="Previous()"
    [disabled]="currentPage === 1 ? true : false"
    style="display: none"
  >
    <mat-icon>navigate_before</mat-icon>
  </button>

  <ng-container *ngFor="let page of visiblePages">
    <button
      [ngClass]="{ active: page === currentPage }"
      (click)="Goto(page)"
      *ngIf="page != -1"
    >
      {{ page }}
    </button>
    <span class="ellipsis" *ngIf="page === -1">...</span>
  </ng-container>
  <button
    mat-button
    class="nextBtn"
    (click)="Next()"
    [disabled]="totalPages === currentPage ? true : false"
    style="padding-left: 15px"
  >
    Next
    <mat-icon iconPositionEnd>navigate_next</mat-icon>
  </button>

  <button
    mat-icon-button
    class="iconBtn"
    (click)="Next()"
    [disabled]="totalPages === currentPage ? true : false"
    style="display: none"
  >
    <mat-icon>navigate_next</mat-icon>
  </button>

  <span class="currentPage">{{ currentPage }}</span>
  /
  <span class="totalPages">{{ totalPages }}</span>
  <span class="goTo">Go to </span>
  <input [(ngModel)]="value" (ngModelChange)="onChange()" class="goTo" />
  <span class="goTo">Page</span>
  <button (click)="Goto(0)" class="goTo">View</button>
</div>
