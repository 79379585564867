import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { RegionService } from './store/region.service';

@Injectable({
  providedIn: 'root',
})
export class OrderApiService {
  constructor(private http: HttpService, private region: RegionService) {}
  buildurl(path: string, queryMap: any = null): string {
    return this.http.buildurl('/order' + path, queryMap);
  }

  getPaypalOrder(id: string) {
    const url = this.buildurl(`/paypal/order/${id}`);
    return this.http.fget(url);
  }

  findPromoCode(code: string, bus_type_id: number, bus_seat_id: number) {
    const url = this.buildurl(`/promo/code/${code}`, {
      bus_type_id,
      bus_seat_id,
    });
    return this.http.fget(url);
  }

  createUserOrder(
    bus_seat_id: number,
    bus_type_id: number,
    exclusive: boolean,
    promo_code: string,
    currency: string,
    method: string = '',
    submethod: string = ''
  ) {
    const url = this.buildurl('/order');
    const body = {
      bus_seat_id,
      bus_type_id,
      exclusive,
      promo_code,
      currency,
      method,
      submethod,
    };
    return this.http.post(url, body);
  }

  findUserOrders(
    query: string,
    status: string,
    page: number,
    page_size: number
  ) {
    const url = this.buildurl('/orders', { query, status, page, page_size });
    return this.http.fget(url);
  }

  refundUserOrder(id: number, remark: string) {
    const url = this.buildurl(`/order/${id}/refund`);
    const body = { remark };
    return this.http.put(url, body);
  }

  findPayssionPayments() {
    const url = this.buildurl('/payssion/payments', {
      region: this.region.data || '',
    });
    return this.http.fget(url);
  }

  getNetflixLink(id: number) {
    const url = this.buildurl(`/netfilx/${id}/link`);
    return this.http.fget(url);
  }

  getNetflixSelectLink(id: number) {
    const url = this.buildurl(`/netfilx/${id}/selectlink`);
    return this.http.fget(url);
  }

  findClientBusSeats(classification: number, status: number, region: string) {
    const q = { classification, status, region };
    const url = this.buildurl('/my', q);
    return this.http.fget(url);
  }
}
