<!-- <div *ngFor="let p of currencies; index as i">
  <mat-radio-group
    [(ngModel)]="value"
    (ngModelChange)="onChange()"
    aria-labelledby="example-radio-group-label"
    class="content"
  >
    <mat-radio-button
      class="example-margin"
      [value]="p.value"
    ></mat-radio-button>
    <div class="currency">
      {{ p.name }}
    </div>
  </mat-radio-group>
</div> -->

<button
  [matMenuTriggerData]="userMenuData"
  [matMenuTriggerFor]="menu"
  (click)="toggleDropdown()"
>
  {{ currency }}
  <img alt="arrow" width="20" height="20" [src]="src" />
</button>

<mat-menu #menu="matMenu" class="my-menu">
  <ng-template matMenuContent let-menuWidth="menuWidth">
    <div [style.width.px]="menuWidth">
      <button
        mat-menu-item
        *ngFor="let data of currencies"
        (click)="selectOption(data)"
      >
        <span>{{ data.name }}</span>
      </button>
    </div>
  </ng-template>
</mat-menu>
