<app-spinner [loading]="loading">
  <div class="outside">
    <div class="close">
      <button mat-dialog-close>
        <img
          alt="del"
          width="24"
          height="24"
          src="../../../../assets/images/base/Icon-del-default.svg"
        />
      </button>
    </div>
    <div class="title">
      {{ "Carpool.Title" | translate }}
    </div>
    <div class="content" mat-dialog-content>
      <div
        class="item"
        *ngFor="let data of seatUsers; let i = index"
        [ngStyle]="{ opacity: data.self ? '1' : '0.5' }"
      >
        <ng-container *ngIf="!data.avatar">
          <div class="avatar">
            <img class="avatar-img"
              alt="add-user"
              src="../../../../assets/images/subscription/seat-add.svg"
            /></div
        ></ng-container>
        <ng-container *ngIf="data.avatar">
          <div class="avatar">
            <!-- <div class="circle"></div> -->
            <div class="no">{{ data.no }}</div>
            <app-carpool-image class="avatar-img" [fileid]="data.avatar" ></app-carpool-image>
          </div>
          <div class="nickname">{{ data.nickname }}</div>
        </ng-container>
      </div>
    </div>
  </div>
</app-spinner>
