<ng-content></ng-content>
<div class="cover" *ngIf="loading">
  <div class="cover-container">
    <div class="lord">
      <lord-icon
        trigger="loop"
        src="../../../../assets/lottie/loading.json"
      ></lord-icon>
    </div>
  </div>
</div>
