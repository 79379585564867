<div class="container">
  <div class="content">
    <div class="note">
      <img
        alt="horn"
        width="40"
        height="40"
        src="../../../assets/images/help/Icon-horn.svg"
      />
      <span>{{ "pageHelp.Note" | translate }}</span>
    </div>

    <div class="text">
      {{ "pageHelp.ResponsePossibly-1" | translate }}
      <span>{{ "pageHelp.ResponsePossibly-2" | translate}}</span>
      {{ "pageHelp.ResponsePossibly-3" | translate}}
    </div>
    <div class="cards" *ngIf="!isSmall">
      <div class="item">
        <mat-card matRipple>
          <!-- <div class="arc"></div> -->
          <div class="img-container">
            <img src="../../../assets/images/help/Icon-help-center.png">
            <div class="arc"></div>
          </div>
          
          <mat-card-title>{{ "pageHelp.HelpCenter" | translate }}</mat-card-title>
          <mat-card-subtitle
            > {{ "pageHelp.HelpCenterService" | translate }}</mat-card-subtitle
          >
        </mat-card>
      </div>
      <div class="item">
        
        <mat-card matRipple (click)="newTicket()">
          <div class="img-container">
            <img src="../../../assets/images/help/Icon-new-ticket.png">
            <div class="arc"></div>
          </div>
          
          <mat-card-title>{{ "pageHelp.NewTicket" | translate }}</mat-card-title>
          <mat-card-subtitle
            > {{ "pageHelp.NewTicketService" | translate }}</mat-card-subtitle
          >
        </mat-card>
      </div>
      <div class="item">
        
        <mat-card matRipple (click)="chat()">
          <div class="img-container">
            <img src="../../../assets/images/help/Icon-live-chat.png">
            <div class="arc"></div>
          </div>
          
          <mat-card-title>{{ "pageHelp.LiveChat" | translate }}</mat-card-title>
          <mat-card-subtitle
            > {{ "pageHelp.LiveChatService" | translate }}</mat-card-subtitle
          >
        </mat-card>
      </div>
    </div>
    <div class="items" *ngIf="isSmall">
      <div class="item">
        <mat-card matRipple>
          <!-- <div class="arc"></div> -->
          <div class="img-container">
            <img src="../../../assets/images/help/Icon-help-center.png">
            <div class="arc"></div>
          </div>
          <div class="text-container">
            <mat-card-title>{{ "pageHelp.HelpCenter" | translate }}</mat-card-title>
            <mat-card-subtitle
              > {{ "pageHelp.HelpCenterService" | translate }}</mat-card-subtitle
            >
          </div>
          
          
        </mat-card>
      </div>
      <div class="item">
        
        <mat-card matRipple (click)="newTicket()">
          <div class="img-container">
            <img src="../../../assets/images/help/Icon-new-ticket.png">
            <div class="arc"></div>
          </div>
          
          <div class="text-container">
            <mat-card-title>{{ "pageHelp.NewTicket" | translate }}</mat-card-title>
            <mat-card-subtitle
              > {{ "pageHelp.NewTicketService" | translate }}</mat-card-subtitle
            >
          </div>
            
        </mat-card>
      </div>
      <div class="item">
        
        <mat-card matRipple (click)="chat()">
          <div class="img-container">
            <img src="../../../assets/images/help/Icon-live-chat.png">
            <div class="arc"></div>
          </div>
          
          <div class="text-container">
            <mat-card-title>{{ "pageHelp.LiveChat" | translate }}</mat-card-title>
            <mat-card-subtitle
              > {{ "pageHelp.LiveChatService" | translate }}</mat-card-subtitle
            >
          </div>
            
        </mat-card>
      </div>
    </div>
  </div>
  <div class="content2">
    <div class="title">
      <span>{{ "pageHelp.TicketTitle-span" | translate }}</span>
      {{ "pageHelp.TicketTitle" | translate }}
    </div>
    <div class="subtitle">
      {{ "pageHelp.TicketSubtitle" | translate }}
    </div>
    <div class="search">
      <app-ticket-card-search
        [placeholder]="'pageHelp.TicketSearch' | translate"
        (input)="onInputChange($event)"
      ></app-ticket-card-search>
    </div>
    <app-spinner [loading]="loading">
      <div class="ticket">
        <div class="no-ticket" *ngIf="ticket?.length === 0">
          <img
            src="../../../assets/images/help/no-data.webp"
            alt=""
            width="234"
            height="199"
          />
          <span>{{ "pageHelp.NoTicket" | translate }}</span>
        </div>
        <div class="has-ticket" *ngIf="ticket?.length !== 0">
          <app-ticket-card
            *ngFor="let item of ticket"
            [data]="item"
          ></app-ticket-card>
        </div></div
    ></app-spinner>
  </div>
</div>
