import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'src/app/service/message.service';
import { OrderApiService } from 'src/app/service/order-api.service';
import { validateFormGroup } from 'src/app/utils/form';

@Component({
  selector: 'app-promo-code-modal',
  templateUrl: './promo-code-modal.component.html',
  styleUrls: ['./promo-code-modal.component.scss'],
})
export class PromoCodeModalComponent implements OnInit {
  formGroup: UntypedFormGroup;
  error = false;
  textMessage: any;
  errorText = '';
  constructor(
    private dialogRef: MatDialogRef<PromoCodeModalComponent>,
    private api: OrderApiService,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private message: MessageService,
    private translateService: TranslateService
  ) {
    this.formGroup = this.fb.group({
      code: ['', [Validators.required]],
    });

    this.formGroup.valueChanges.subscribe(() => {
      this.errorText = '';
    });
  }

  ngOnInit(): void {
    this.translateService.get('textMessage').subscribe((text: string) => {
      this.textMessage = text;
    });
  }

  apply() {
    if (!validateFormGroup(this.formGroup)) {
      this.dialogRef.close('');
      return;
    }
    this.findPromoCode();
  }

  loading = false;
  async findPromoCode() {
    const code = this.formGroup.value.code;
    const busTypeId = this.data.id;
    try {
      this.loading = true;
      const r = await this.api.findPromoCode(code, busTypeId, 0);
      if (r.status === 1203) {
        this.errorText = this.textMessage.CodeExist;
      } else if (r.status === 1204) {
        this.errorText = this.textMessage.CodeExpired;
      } else if (r.status === 1205) {
        this.errorText = this.textMessage.CodeValid;
      } else if (r.status === 1206) {
        this.errorText = this.textMessage.CodeUsageLimit;
      } else if (r.status === 1207) {
        this.errorText = this.textMessage.CodeNotApplicable;
      } else if (r.status === 1208) {
        this.errorText = this.textMessage.CodeUsed;
      } else if (r.status === 1209) {
        this.errorText = this.textMessage.FirstOrder;
      } else if (r.status === 1210) {
        this.errorText = this.textMessage.FirstProduct;
      } else if (r.status === 1211) {
        this.errorText = this.textMessage.RenewalAvailable;
      } else if (r.status === 1212) {
        this.errorText = this.textMessage.NonRenewal;
      } else if (r.status !== 0) {
        this.message.warn(this.textMessage.Unknown);
      } else {
        this.dialogRef.close(r.data);
      }
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }
}
