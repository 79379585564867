import { Component, OnInit } from '@angular/core';
import { RegionApiService } from 'src/app/service/region-api.service';

@Component({
  selector: 'app-login-footer',
  templateUrl: './login-footer.component.html',
  styleUrls: ['./login-footer.component.scss'],
})
export class LoginFooterComponent implements OnInit {
  region: any;
  constructor(private api: RegionApiService) {}

  ngOnInit(): void {
    this.getIpRegion();
  }

  async getIpRegion() {
    try {
      const r = await this.api.getIpRegion();
      this.region = r.data;
    } catch (error) {}
  }
}
