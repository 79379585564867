<mat-card>
    <div class="close">
        <button mat-dialog-close>
          <img
            alt="del"
            width="35"
            height="35"
            src="../../../../assets/images/base/Icon-del-default.svg"
          />
        </button>
    </div>
    <div class="title">
        Be A PremLogin Affiliate!
    </div>
    <div class="content">
        Register as a PremLogin affiliate now and share your prompt links with your parents, friends, and on social media. You will earn up to a 15% commission when someone makes a purchase through your link. The more you share, the more money you can earn!
    </div>
    <div class="pic">
        <img src="../../../../assets/images/page-header/be-affiliate-pic.png"
        width="80%"
        >
    </div>
    <div class="btn">
      <app-button
        (click)="goto()"
        fontColor="#fff"
        width="80%"
        height="50px"
      >
        {{ "pageAffiliateAvert.JoinNow" | translate }}
      </app-button>
        
    </div>
</mat-card>
