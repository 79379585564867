import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component } from '@angular/core';

@Component({
  selector: 'app-enter-invitation-link-modal',
  templateUrl: './enter-invitation-link-modal.component.html',
  styleUrls: ['./enter-invitation-link-modal.component.scss'],
})
export class EnterInvitationLinkModalComponent {
  isSmall = false;
  constructor(private breakpointObserver: BreakpointObserver) {
    this.isSmall = this.breakpointObserver.isMatched([Breakpoints.XSmall]);
  }
}
