import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'src/app/service/message.service';
import { LoadingDataService } from 'src/app/service/store/loading-data.service';
import { UserApiService } from 'src/app/service/user-api.service';
import {
  letterAndNumberValidator,
  passwordValidator,
} from 'src/app/utils/form';

@Component({
  selector: 'app-set-up-password-card',
  templateUrl: './set-up-password-card.component.html',
  styleUrls: ['./set-up-password-card.component.scss'],
})
export class SetUpPasswordCardComponent implements OnInit {
  formGroup: UntypedFormGroup;
  tip1 = false;
  tip2 = false;
  error = { ConfirmPassword: false, PasswordsInconsistent: false };
  textMessage: any;

  constructor(
    private fb: UntypedFormBuilder,
    private api: UserApiService,
    private router: Router,
    private translateService: TranslateService,
    private loadingDataService: LoadingDataService,
    private message: MessageService
  ) {
    this.formGroup = this.fb.group({
      password1: ['', [passwordValidator, letterAndNumberValidator]],
      password2: ['', [Validators.required]],
    });

    this.formGroup.valueChanges.subscribe((newValues) => {
      this.error.ConfirmPassword = false;
      this.error.PasswordsInconsistent = false;
    });

    this.translateService.onLangChange.subscribe((text: any) => {
      this.translateService.get('textMessage').subscribe((text: string) => {
        this.textMessage = text;
      });
    });
  }

  ngOnInit(): void {
    this.translateService.get('textMessage').subscribe((text: string) => {
      this.textMessage = text;
    });
  }

  continue() {
    const error1 = this.formGroup.controls['password1'].errors;
    const error2 = this.formGroup.controls['password2'].errors;
    if (!error1 && !error2) {
      this.setUpPassword();
    } else if (error2) {
      this.error.ConfirmPassword = true;
    }
  }

  checkError() {
    this.continue();
    if (!this.error) this.setUpPassword();
  }

  loading = false;
  async setUpPassword() {
    const password1 = this.formGroup.value['password1'];
    const password2 = this.formGroup.value['password2'];
    if (password1 != password2) {
      this.error.PasswordsInconsistent = true;
      return;
    }
    try {
      this.loading = true;
      this.loadingDataService.set(this.loading);
      const r = await this.api.updateEmailPassword(password1);
      this.router.navigate(['']);
    } catch (error) {
      this.message.warn(this.textMessage.NetworkError);
    } finally {
      this.loading = false;
      this.loadingDataService.set(this.loading);
    }
  }
}
