<span (click)="prevSlide()" class="zuojiantou"
  ><mat-icon>chevron_left</mat-icon></span
>
<div
  class="carousel-container"
  (swipeleft)="nextSlide()"
  (swiperight)="prevSlide()"
>
  <div
    class="carousel-slide"
    *ngFor="let data of reviewData; let i = index"
    [ngClass]="{
      active: i === activeIndex,
      prev: i === prevIndex,
      next: i === nextIndex,
      pre2: i === pre2Index,
      next2: i === next2Index,
      pre3: i === pre3Index,
      next3: i === next3Index,
      next4: i === next4Index
    }"
  >
    <a [href]="data.href" target="_blank">
      <button>
        <img alt="img" [src]="data.src" />
      </button>
    </a>
  </div>
</div>
<span (click)="nextSlide()" class="youjiantou"
  ><mat-icon>chevron_right</mat-icon></span
>
