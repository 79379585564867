import { Component, ElementRef, ViewChild, HostListener } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractNgModelComponent } from '../../base/abstract-ng-model/abstract-ng-model.component';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-promotional-language-select',
  templateUrl: './promotional-language-select.component.html',
  styleUrls: ['./promotional-language-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PromotionalLanguageSelectComponent,
    },
  ],
})
export class PromotionalLanguageSelectComponent extends AbstractNgModelComponent {
  languages = [
    { name: 'English', value: 'en' },
    { name: 'Español', value: 'es' },
    { name: 'Italiano', value: 'it' },
    { name: 'Français', value: 'fr' },
    { name: '한국어', value: 'ko' },
    { name: 'Polski', value: 'pl' },
    { name: 'Deutsch', value: 'de' },
    { name: 'Português', value: 'pt-PT' },
    { name: 'Nederlands', value: 'nl' },
    { name: 'Slovenčina', value: 'sk' },
    { name: 'Română', value: 'ro' },
    { name: 'اللغة العربية', value: 'ar' },
    { name: 'Magyar', value: 'hu' },
    { name: 'Ελληνικά', value: 'el' },
    { name: 'čeština', value: 'cs' },
    { name: 'Русский Язык', value: 'ru' },
    { name: 'עברית', value: 'iw' },
    { name: '简体中文', value: 'zh-CN' },
  ];

  language = '';

  override writeValue(v: any): void {
    super.writeValue(v);
    this.language = this.languages.find((lang) => lang.value === v)?.name || '';
  }

  @ViewChild(MatMenuTrigger, { static: true, read: ElementRef })
  userMenu!: ElementRef<HTMLElement>;

  get userMenuData() {
    return {
      menuWidth: this.userMenu.nativeElement.clientWidth,
    };
  }

  value: any = '';

  isDropdownOpen = false;
  src = '../../../../assets/images/withdraw/Icon-down-arrow-min.svg';

  constructor(private elementRef: ElementRef) {
    super();
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
    if (this.isDropdownOpen) {
      this.src = '../../../../assets/images/withdraw/Icon-up-arrow-min.svg';
    } else {
      this.src = '../../../../assets/images/withdraw/Icon-down-arrow-min.svg';
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
      this.src = '../../../../assets/images/withdraw/Icon-down-arrow-min.svg';
    }
  }

  override onChange(): void {
    super.onChange();
  }

  selectOption(option: any) {
    this.isDropdownOpen = false;
    this.src = '../../../../assets/images/withdraw/Icon-down-arrow-min.svg';
    this.value = option.value;
    this.language = option.name;
    this.onChange();
  }
}
