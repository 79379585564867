<mat-card>
  <mat-card-header>
    <span>
      {{ "pageAffiliate.Union" | translate }}
      <!-- <img
        alt="union"
        width="28"
        height="28"
        src="../../../../assets/images/affiliate/Icon-union.svg"
      /> -->
    </span>

    <button
      [matTooltip]="'pageAffiliate.Question' | translate"
      #tooltip="matTooltip"
      matTooltipClass="tooltip-question"
      (click)="tooltip.toggle()"
    >
      <img
        alt="question"
        width="28"
        height="28"
        src="../../../../assets/images/affiliate/Icon-question.svg"
        style="cursor: pointer"
      />
    </button>
  </mat-card-header>
  <mat-card-content class="mat-card-content"
    >{{ "pageAffiliate.Tip1" | translate }}
    <!-- <span style="color: #d6392e">{{ data?.first_ratio * 100 }}% </span>
    {{ "pageAffiliate.Tip2" | translate }}
    <span style="color: #2eb3d6">{{ data?.ratio * 100 }}% </span>
    {{ "pageAffiliate.Tip3" | translate }} -->
    <div></div>
    {{ "pageAffiliate.Tip4" | translate }}
  </mat-card-content>
</mat-card>
